import { useCallback, useState } from "react"
import PecaInput from "../../models/pecas/PecaInput"
import http from "../../../../http"

export const useCadastraPeca = () => {
    const [resposta, setResposta] = useState({})

    const cadastrarPeca = useCallback((peca) => {
        const cadastroLaudo = PecaInput.create(peca) 
        http.post('/items', cadastroLaudo)
            .then(res => {
                setResposta({ data: res.data, error: null })
            })
            .catch(error => {
                const { response } = error
                setResposta({data: null, error: response})
            })
    }, [])
    return [resposta, cadastrarPeca]
}