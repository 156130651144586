import { useDispatch, useSelector } from 'react-redux'
import EditItem from '../../../../shared/components/IconButtons/EditItem'
import CampoInputTexto from '../../../../shared/components/CampoInputComButton/CampoInputTexto/CampoInputTexto'
import { handleOcorrenciaChange } from '../../../../../store/reducers/ocorrenciaSlice'

const CampoInputDataOcorrencia = ({ onClick, editButton, disabled }) => {

    const dispatch = useDispatch()
    const { ocurrence_date } = useSelector(state => state.ocorrenciaState)
    
    const handleChange = (name, value) => {
        dispatch(handleOcorrenciaChange({[name]: value}))
    }
    const handleClick = (e) => {
        e.preventDefault()
        onClick("ocurrence_date")
    }

    return (
        <CampoInputTexto
            id="inputDataOcorrencia"
            titulo="Data ocorrência*"
            onChange={handleChange}
            name="ocurrence_date"
            value={ocurrence_date}
            type="date"
            required={true}
            disabled={disabled}
        >
            {
                editButton ? <EditItem onClick={handleClick} /> : <></>
            }
        </CampoInputTexto>
    )
}

export default CampoInputDataOcorrencia
