import React, { useEffect } from 'react'
import CampoInputConfirmaSenha from '../Inputs/CampoInputConfirmaSenha/CampoInputConfirmaSenha'
import CampoInputEmail from '../Inputs/CampoInputEmail/CampoInputEmail'
import CampoInputSenha from '../Inputs/CampoInputSenha/CampoInputSenha'
import { resetUsuario } from '../../../../store/reducers/usuarioSlice'
import { useDispatch, useSelector } from 'react-redux'
import ContainerTitleInputField from '../../../../components/Containers/ContainerTitleInputField'
import Botao from '../../../../components/Botao'

const FormCadastroAdministrador = ({ aoSubmeterForm, editButton }) => {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(resetUsuario())
    }, [dispatch])

    const dadosUsuario = useSelector(state => state.usuarioState)

    const handleSubmit = (e) => {
        e.preventDefault()
        try{
            aoSubmeterForm(dadosUsuario)
        } catch(error) {
            alert(error.message)
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <CampoInputEmail
                onClick={() => {}}
                editButton={editButton}
            />
            <CampoInputSenha
                onClick={() => {}}
                editButton={editButton}
            />
            <CampoInputConfirmaSenha
                onClick={() => {}}
                editButton={editButton}
            />
            <ContainerTitleInputField titulo="" botao>
                <Botao titulo="Cadastrar administrador" type="submit" />
            </ContainerTitleInputField>
        </form>
    )
}

export default FormCadastroAdministrador