import { useEffect, useState } from "react";
import http from "../../../../http";

export const useBuscaNumeroOcorrencias = () => {
    const [totalOcorrencias, setTotalOcorrencias] = useState('')
    useEffect(() => {
        http.get('/nr-ocorrencias-totais', )
        .then(res => {
            const nrTotalOcorrencias = res.data
            setTotalOcorrencias(nrTotalOcorrencias)
        })
    }, [])

    return {
        totalOcorrencias
    }
}