import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import GerenciarAssociados from '../TabelaGerenciarAssociados/GerenciarAssociados';

import MatchFilter from '../../../shared/components/CampoMultiplosInputs/MatchFilter/MatchFilter'; 
import { retornaListaMatchPorListaCampo } from '../../../../Utilitarios';
import { useSelector } from 'react-redux';

export default function FilterAssociadoTable({ onClick, listaAssociados, tituloToolbar, children }) {

    const [filtrado, setFiltrado] = useState([])
    const { campo, valor } = useSelector(state => state.campoValorState)
    
    useEffect(() => {
        setFiltrado(listaAssociados)
    }, [listaAssociados])

    useEffect(() => {
        if(campo === "E-mail" && valor !== "") {
            const listaFiltrada = retornaListaMatchPorListaCampo(listaAssociados, 'usuario.email', valor)
            setFiltrado(listaFiltrada)
        }

        if(campo === "Razão Social" && valor !== "") {
            const listaFiltrada = retornaListaMatchPorListaCampo(listaAssociados, 'cadastro.social_name', valor)
            setFiltrado(listaFiltrada)
        }

        if(campo === "Proprietário" && valor !== "") {
            const listaFiltrada = retornaListaMatchPorListaCampo(listaAssociados, 'cadastro.owner', valor)
            setFiltrado(listaFiltrada)
        }
        
        if(campo === "CNPJ" && valor !== "") {
            const listaFiltrada = retornaListaMatchPorListaCampo(listaAssociados, 'cadastro.cnpj', valor)
            setFiltrado(listaFiltrada)
        }
        
        if(valor === "") {
            setFiltrado(listaAssociados)
        }
    }, [campo, valor, listaAssociados])

    return (
        <>
            <MatchFilter 
                itensDoSelect={['E-mail', 'Razão Social', 'Proprietário', 'CNPJ']} />
            <GerenciarAssociados
                cadastros={filtrado}
                onClick={onClick}
                tituloToolbar={tituloToolbar}>
                {children}
            </GerenciarAssociados>
        </>
    )
}