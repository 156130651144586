import { useEffect, useState } from "react";
import http from "../../../../http";

export const useBuscaListaDeIdsDeOcorrenciasSemLaudo = () => {
    const [listaIdsDeOcorrenciaSemLaudo, setListaIdsDeOcorrenciasSemLaudo] = useState([])
    
    useEffect(() => {
        http.get('/ocorrencias-sem-laudo')
        .then(res => {
            const listaOcorrencias = res.data
            setListaIdsDeOcorrenciasSemLaudo(listaOcorrencias)
        })
    }, [])

    return {
        listaIdsDeOcorrenciaSemLaudo
    }
}