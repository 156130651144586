import { Paper } from "@mui/material"
import MainTitle from "../../modules/shared/components/MainTitle"
import { useState } from "react"
import ContainerTitleInputField from "../../components/Containers/ContainerTitleInputField"
import FormInputTexto from "../../components/FormInputTexto"
import Botao from "../../components/Botao"
import NavBar from "../../modules/usuario/components/NavBar/NavBar"
import SideDrawer from "../../modules/usuario/components/SideDrawer/SideDrawer"
import { useRecuperaSenha } from "../../modules/usuario/hooks/useRecuperaSenha/useRecuperaSenha"
import { useParams } from "react-router-dom"
import { useDisplayAlertAfterServerResponseRedirectLogin } from "../../modules/shared/hooks/useDisplayAlertAfterServerResponseRedirectLogin/useDisplayAlertAfterServerResponseRedirectLogin"


export default function RecuperarSenha() {

    const [novaSenha, setNovaSenha] = useState("")
    const [confirmaNovaSenha, setConfirmaNovaSenha] = useState("")
    const [resposta, recuperaSenha] = useRecuperaSenha()
    useDisplayAlertAfterServerResponseRedirectLogin(resposta)
    const { token } = useParams()
    
    const aoSubmeterFormulario = (e) => {
        e.preventDefault()
        const alterarSenha = {
            novaSenha,
            confirmaNovaSenha
        }
        recuperaSenha(token, alterarSenha)
    }

    const handleChange = (name) => (e) => {
        name(e.target.value)
    }

    return (
        <Paper>
            <NavBar />
            <SideDrawer permissao={1} >
                <MainTitle titulo="Alterar senha" tituloPrincipal />
                <form onSubmit={aoSubmeterFormulario}>
                    <ContainerTitleInputField titulo="Nova senha" >
                        <FormInputTexto
                            value={novaSenha}
                            onChange={handleChange(setNovaSenha)}
                            required={true}
                            label={"Nova senha"} 
                            type="password" />
                    </ContainerTitleInputField>
                    <ContainerTitleInputField titulo="Confirma nova senha" >
                        <FormInputTexto
                            value={confirmaNovaSenha}
                            onChange={handleChange(setConfirmaNovaSenha)}
                            required={true}
                            label={"Confirma nova senha"}
                            type="password" />
                    </ContainerTitleInputField>
                    <ContainerTitleInputField botao>
                        <Botao titulo="Alterar senha" type="submit" />
                    </ContainerTitleInputField>
            </form>
            </SideDrawer>
        </Paper>
    )
}