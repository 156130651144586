import { useDispatch, useSelector } from 'react-redux';
import EditItem from '../../../../shared/components/IconButtons/EditItem';
import { handleAssociadoChange } from '../../../../../store/reducers/associadoSlice';
import { telefoneMask } from '../../../../../Utilitarios';
import CampoInputTexto from '../../../../shared/components/CampoInputComButton/CampoInputTexto/CampoInputTexto';

const CampoInputTelefone = ({ onClick, editButton, disabled }) => {

    const dispatch = useDispatch()
    const { phone1 } = useSelector(state => state.associadoState)
    
    const handleChange = (name, value) => {
        dispatch(handleAssociadoChange({[name]: telefoneMask(value)}))
    }
    const handleClick = (e) => {
        e.preventDefault()
        onClick("phone1")
    }

    return (
        <CampoInputTexto
            id="inputTelefone"
            titulo="Telefone*"
            onChange={handleChange}
            label={"obrigatório"}
            name="phone1"
            value={phone1}
            required={true}
            disabled={disabled}
        >
            {
                editButton ? <EditItem onClick={handleClick} /> : <></>
            }
        </CampoInputTexto>
    )
}

export default CampoInputTelefone
