import React, { useEffect } from 'react'
import Paper from '@mui/material/Paper'
import MainTitle from "../../modules/shared/components/MainTitle"
import { useBuscaListaCadastros } from '../../modules/associado/hooks/useBuscaListaCadastros'
import { useCadastraOcorrencia } from '../../modules/ocorrencia/hooks/useCadastraOcorrencia'
import { useDispatch } from 'react-redux'
import { resetLocalizacao } from '../../store/reducers/localizacaoSlice'
import { resetOcorrencia } from '../../store/reducers/ocorrenciaSlice'
import FormCadastraOcorrencia from '../../modules/ocorrencia/components/FormCadastraOcorrencia/FormCadastraOcorrencia'
import { useDisplayAlertAfterServerResponse } from '../../modules/shared/hooks/useDisplayAlertAfterServerResponse/useDisplayAlertAfterServerResponse'
import { resetAssociadoSelecionado } from '../../store/reducers/associadoSelecionadoSlice'

export default function CadastrarOcorrencia() {
    
    const { listaAssociados } = useBuscaListaCadastros()
    const [resposta, cadastrarOcorrencia] = useCadastraOcorrencia()
    useDisplayAlertAfterServerResponse(resposta)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(resetAssociadoSelecionado())
        dispatch(resetOcorrencia())
        dispatch(resetLocalizacao())
    }, [dispatch])

    return (
        <Paper>
            <MainTitle titulo={"Cadastrar uma nova ocorrência"} tituloPrincipal={true} />
            <FormCadastraOcorrencia 
                disabled={false}
                editButton={false}
                aoSubmeterForm={cadastrarOcorrencia}
                listaAssociados={listaAssociados} />
        </Paper>
    )
}