import React, { useState } from 'react'
import WatchItem from '../../modules/shared/components/IconButtons/WatchItem';
import MainTitle from "../../modules/shared/components/MainTitle"
import { useEffect } from 'react';
import { useBuscaMinhasOcorrencias } from '../../modules/ocorrencia/hooks/useBuscaMinhasOcorrencias';
import TabelaMeusLaudos from '../../modules/ocorrencia/components/TabelaMeusLaudos/TabelaMeusLaudos';
import DisplayPDFOnScreen from '../../modules/ocorrencia/components/DisplayPDFOnScreen/DisplayPDFOnScreen';
import ModalGeneric from '../../components/ModalGeneric';
import { useDispatch } from 'react-redux';
import { handleOcorrenciaSelecionadaChange, resetOcorrenciaSelecionada } from '../../store/reducers/ocorrenciaSelecionadaSlice';

export default function VisualizaLaudos() {
    const [listaOcorrencias, setListaOcorrencias] = useState([])

    const [open, setOpen] = useState(false)

    const [resposta, buscaMinhasOcorrencias] = useBuscaMinhasOcorrencias()

    const dispatch = useDispatch()

    useEffect(() => {
        buscaMinhasOcorrencias()
        dispatch(resetOcorrenciaSelecionada())
    }, [dispatch, buscaMinhasOcorrencias])

    useEffect(() => {
        if(!resposta.error && Object.entries(resposta).length > 0) {
            const listaTabela = resposta.data.map(value => {
                const { cadastro, listaPecas, listaMarcas, ocorrencia } = value
                return {
                    id: ocorrencia.getId(),
                    social_name: cadastro.getRazaoSocial(),
                    cnpj: cadastro.getCnpj(),
                    client: ocorrencia.getCliente(),
                    damage: ocorrencia.getDanoReclamado(),
                    items: listaPecas.listaNomePecas,
                    brands: listaMarcas.listaNomeMarcas,
                    laudoCadastrado: ocorrencia.getLaudoCadastrado()
                }
            })
            setListaOcorrencias(listaTabela)
        } else if (resposta.error) {
            const { data } = resposta.error
            alert(data)
            setListaOcorrencias([])
        }
    }, [resposta])

    const handleClick = (rowId, index) => (e) => {
        dispatch(handleOcorrenciaSelecionadaChange({codigoAT: rowId}))
        setOpen(true)
    }

    const handleClose = () => {
        dispatch(resetOcorrenciaSelecionada())
        setOpen(false)
    }

    return (
        <>
            <MainTitle titulo={"Visualizar laudos"} tituloPrincipal />
            <TabelaMeusLaudos ocorrencias={listaOcorrencias} onClick={handleClick}>
                <WatchItem />
            </TabelaMeusLaudos>  
            <ModalGeneric open={open} onClose={handleClose}>
                <DisplayPDFOnScreen />
            </ModalGeneric>
        </>
    )
}