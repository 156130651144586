import { useEffect, useState } from "react";
import http from "../../../../http";

export const useBuscaLaudoPdf = (id) => {
    const [href, setHref] = useState()

    useEffect(() => {
        setHref()
        if(id) {
            http.get(`/laudos/${id}`, { responseType: 'blob' })
                .then(res => {
                    const href = res.data
                    setHref(href)
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }, [id])

    return {
        href
    }
}