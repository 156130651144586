import { useCallback, useState } from "react"
import http from "../../../../http"

export const useRecuperaSenha = () => {
    const [resposta, setResposta] = useState({})

    const recuperaSenha = useCallback((token, camposSenha) => {
        http.post(`/usuarios/recupera-senha/${token}`, camposSenha)
            .then(res => {
                setResposta({ data: res.data, error: null })
            })
            .catch(error => {
                const { response } = error
                setResposta({data: null, error: response})
            })
    }, [])
    return [resposta, recuperaSenha]
}