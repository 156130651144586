import React from 'react'
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch } from 'react-redux';
import { toggleSideBar } from '../../../../store/reducers/estadoMobileSlice';
import { useMediaQuery } from '@mui/material';

const drawerWidth = 240;

export default function NavBar({children}) {

    const dispatch = useDispatch()
    
    const resolveSideBar = () => {
        dispatch(toggleSideBar())
    }   

    const theme = createTheme({
        palette: {
            primary: {
                main: "#fff"
            }
        }
    });

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    
    return (
        <ThemeProvider theme={theme}>
            <AppBar
                position="relative"
                // sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
                sx={{
                    width: { md: `calc(100% - ${drawerWidth}px)` },
                    ml: { md: `${drawerWidth}px` },
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        aria-controls={`visible-${isSmallScreen}`}
                        edge="start"
                        onClick={resolveSideBar}
                        sx={{ mr: 2, display: { md: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Área do associado
                    </Typography>
                    {children}
                </Toolbar>
            </AppBar>
        </ThemeProvider>
    )
}