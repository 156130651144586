import axios from 'axios';

const http = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        Accept: 'application/json',
        Content: 'application.json'
    }
})

http.interceptors.request.use(function (config) {
    // Faz alguma coisa antes da requisição ser enviada
    const token = localStorage.getItem('Authorization')
    if(token && config.headers) {
        config.headers.Authorization = `Bearer ${token}`
    }
    return config;
}, function (error) {
    // Faz alguma coisa com o erro da requisição
    return Promise.reject(error);
});

http.interceptors.response.use(function (response) {
    return response
}, async function (error) {
    const originalConfig = error.config;
    if (originalConfig.url !== "/usuarios/login" && error.response) {
        // Access Token was expired
        if (error.response.status === 401 && !originalConfig._retry) {
            originalConfig._retry = true;
            try {
                const refresh = localStorage.getItem('RefreshToken')
                const rs = await axios.post(`${process.env.REACT_APP_BASE_URL}/usuarios/atualiza-token`, { refreshToken: refresh });              
                const accessToken = rs.headers.authorization
                const { refreshToken } = rs.data
                localStorage.setItem('Authorization', accessToken)
                localStorage.setItem('RefreshToken', refreshToken)
                return http(originalConfig.url);
            } catch (_error) {
                return Promise.reject(_error);
            }
        }
    }
    return Promise.reject(error);
})

export default http