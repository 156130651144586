import { useCallback, useState } from "react"
import UsuarioInput from "../../models/UsuarioInput"
import http from "../../../../http"

export const useCadastraAdministrador = () => {
    const [resposta, setResposta] = useState({})

    const cadastraAdministrador = useCallback((usuario) => {
        const cadastroAdministrador = UsuarioInput.create(usuario) 
        http.post('/administrador', cadastroAdministrador)
            .then(res => {
                setResposta({ data: res.data, error: null })
            })
            .catch(error => {
                const { response } = error
                setResposta({data: null, error: response})
            })
    }, [])
    return [resposta, cadastraAdministrador]
}