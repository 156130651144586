import { useDispatch, useSelector } from 'react-redux';

import EditItem from '../../../../shared/components/IconButtons/EditItem';
import { handleAssociadoChange } from '../../../../../store/reducers/associadoSlice';
import CampoInputTexto from '../../../../shared/components/CampoInputComButton/CampoInputTexto/CampoInputTexto';

const CampoInputNomeFantasia = ({ onClick, editButton, disabled }) => {

    const dispatch = useDispatch()
    const { fantasy_name } = useSelector(state => state.associadoState)
    
    const handleChange = (name, value) => {
        dispatch(handleAssociadoChange({[name]: value}))
    }
    const handleClick = (e) => {
        e.preventDefault()
        onClick("fantasy_name")
    }

    return (
        <CampoInputTexto
            id="inputNomeFantasia"
            titulo="Nome fantasia*"
            onChange={handleChange}
            name="fantasy_name"
            value={fantasy_name}
            label="obrigatório"
            required={true}
            disabled={disabled}
        >
            {
                editButton ? <EditItem onClick={handleClick} /> : <></>
            }
        </CampoInputTexto>
    )
}

export default CampoInputNomeFantasia
