import { useDispatch, useSelector } from 'react-redux';
import { handleAssociadoChange } from '../../../../../store/reducers/associadoSlice';
import CampoInputTexto from '../../../../shared/components/CampoInputComButton/CampoInputTexto/CampoInputTexto';
import VoidIcon from '../../../../shared/components/IconButtons/VoidIcon/VoidIcon';

const CampoInputEndereco = ({ editButton }) => {

    const dispatch = useDispatch()
    const { logradouro } = useSelector(state => state.localizacaoState)
    
    const handleChange = (name, value) => {
        dispatch(handleAssociadoChange({[name]: value}))
    }

    return (
        <CampoInputTexto
            id="inputEndereço"
            titulo="Endereço*"
            onChange={handleChange}
            name="logradouro"
            value={logradouro}
            required={true}
            disabled={true}
        >
            {
                editButton ? <VoidIcon /> : <></>
            }
        </CampoInputTexto>
    )
}

export default CampoInputEndereco
