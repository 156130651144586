import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Botao from '../../../../components/Botao'
import ContainerTitleInputField from '../../../../components/Containers/ContainerTitleInputField'
import { handleAssociadoSelecionadoChange } from '../../../../store/reducers/associadoSelecionadoSlice'
import { handleLocalizacaoChange, resetLocalizacao } from '../../../../store/reducers/localizacaoSlice'
import { handleOcorrenciaChange } from '../../../../store/reducers/ocorrenciaSlice'
import CampoInputAssociado from '../../../associado/components/Inputs/CampoInputAssociado/CampoInputAssociado'
import { useBuscaCadastroPeloId } from '../../../associado/hooks/useBuscaCadastroPeloId'
import { useBuscaCep } from '../../../associado/hooks/useBuscaCep'
import ShowOrHide from '../../../shared/components/ShowOrHide/ShowOrHide'
import CamposCadastraOcorrencia from '../CamposCadastraOcorrencia/CamposCadastraOcorrencia'
import CampoInputCodigoAT from '../Inputs/CampoInputCodigoAT/CampoInputCodigoAT'
import CampoInputDataEmissaoLaudo from '../Inputs/CampoInputDataEmissaoLaudo/CampoInputDataEmissaoLaudo'

const FormEditaOcorrencia = ({ disabled, editButton, onSubmitForm, onClick, useBuscaOcorrenciaPeloId, listaIdsDeOcorrencia, listaAssociados }) => {

    const dispatch = useDispatch()
    const { codigoAT } = useSelector(state => state.ocorrenciaSelecionadaState)
    const associadoSelecionado = useSelector(state => state.associadoSelecionadoState)
    const [resposta, buscarCep] = useBuscaCep()

    const { cadastro } = useBuscaCadastroPeloId(associadoSelecionado.id)
    
    const {
        dadosOcorrencia: dadosOcorrenciaInicial,
        dadosCadastro: dadosCadastroInicial,
        dadosUsuario: dadosUsuarioInicial,
        dadosCep: dadosCepInicial,
        pecas: pecasInicial,
        marcas: marcasInicial
    } = useBuscaOcorrenciaPeloId(codigoAT)

    useEffect(() => {
        if(Object.entries(cadastro).length > 0) {
            dispatch(handleLocalizacaoChange({cep: cadastro.cep}))
            buscarCep(cadastro.cep)
        } else {
            dispatch(resetLocalizacao())
        }
    }, [cadastro, dispatch, buscarCep])

    useEffect(() => {
        if(resposta.data) {
            const { cep, ...dadosLocalizacao } = resposta.data 
            dispatch(handleLocalizacaoChange(dadosLocalizacao))
        }
    }, [resposta, dispatch])

    useEffect(() => {
        if(dadosOcorrenciaInicial) {
            const alteraOcorrencia = {
                usuario_id: dadosOcorrenciaInicial.usuario_id,
                items: dadosOcorrenciaInicial.pecas,
                brands: dadosOcorrenciaInicial.marcas,
                wash_instruct: dadosOcorrenciaInicial.instrucaoLimpeza,
                wash_used: dadosOcorrenciaInicial.limpezaUtilizada,
                damage: dadosOcorrenciaInicial.danoReclamado,
                ocurrence_date: dadosOcorrenciaInicial.dataOcorrencia,
                fiber_type: dadosOcorrenciaInicial.tipoFibra,
                iron_clothes: dadosOcorrenciaInicial.foiPassado,
                dry_dryer: dadosOcorrenciaInicial.foiSecado, 
                insurance: dadosOcorrenciaInicial.encaminhaSeguro,
                tests: dadosOcorrenciaInicial.autorizaTestes, 
                responsible: dadosOcorrenciaInicial.responsavel,
                technician: dadosOcorrenciaInicial.nomeTecnico,
                client: dadosOcorrenciaInicial.cliente,
                ticket: dadosOcorrenciaInicial.ticket,
                observation: dadosOcorrenciaInicial.observacao, 
                routing: dadosOcorrenciaInicial.encaminha,
                complemento: dadosOcorrenciaInicial.complemento,
                solution: dadosOcorrenciaInicial.solucao,
                out_date: dadosOcorrenciaInicial.dataSaida
            }
            dispatch(handleOcorrenciaChange(alteraOcorrencia))
        }
    }, [dadosOcorrenciaInicial, dispatch])

    useEffect(() => {
        if(dadosUsuarioInicial) {
            dispatch(handleAssociadoSelecionadoChange({usuario_id: dadosUsuarioInicial.id}))
        }
    }, [dadosUsuarioInicial, dispatch])

    useEffect(() => {
        if(dadosCepInicial) {
            dispatch(handleLocalizacaoChange({cep: dadosCepInicial.cep, cidade: dadosCepInicial.cidade, estado: dadosCepInicial.estado}))
        }
    }, [dadosCepInicial, dispatch])

    useEffect(() => {
        if(dadosCadastroInicial) {
            dispatch(handleAssociadoSelecionadoChange({associado: dadosCadastroInicial.associado, id: dadosCadastroInicial.id}))
        }
    }, [dadosCadastroInicial, dispatch])
    
    useEffect(() => {
        if(marcasInicial.listaMarcas) {
            dispatch(handleOcorrenciaChange({
                brands: marcasInicial.listaMarcas
            }))
        }
    }, [marcasInicial, dispatch])

    useEffect(() => {
        if(pecasInicial.listaPecas) {
            dispatch(handleOcorrenciaChange({
                items: pecasInicial.listaPecas
            }))
        }
    }, [pecasInicial, dispatch])
    

    const handleSubmit = (e) => {
        e.preventDefault()
        onSubmitForm()
    }

    const handleClick = (name) => {
        onClick(name)
    }
    

    return (
        <form method="post" onSubmit={handleSubmit}>
            <CampoInputCodigoAT listaParaAutocomplete={listaIdsDeOcorrencia} editButton={true} />
            <ShowOrHide campo={codigoAT} >
                <CampoInputAssociado editButton={'edit'} disabled listaAssociados={listaAssociados} onClick={handleClick} />
                <CamposCadastraOcorrencia
                    disabled={true}
                    editButton={'edit'}
                    listaAssociados={listaAssociados} 
                    onClick={handleClick} />
                <CampoInputDataEmissaoLaudo editButton disabled onClick={handleClick} />
                <ContainerTitleInputField titulo="" botao>
                    <Botao titulo="Atualizar ocorrência" type="submit" />
                </ContainerTitleInputField> 
            </ShowOrHide>
        </form>
    )
}

export default FormEditaOcorrencia