export default class OcorrenciaInput {
    constructor(dadosOcorrencia) {
        // this.cadastro_id = dadosOcorrencia.cadastro_id
        // this.usuario_id = dadosOcorrencia.usuario_id
        this.brands = dadosOcorrencia.brands
        this.items = dadosOcorrencia.items
        this.client = dadosOcorrencia.client
        this.complemento = dadosOcorrencia.complemento
        this.damage = dadosOcorrencia.damage
        this.date = dadosOcorrencia.date
        this.dry_dryer = dadosOcorrencia.dry_dryer
        this.fiber_type = dadosOcorrencia.fiber_type
        this.insurance = dadosOcorrencia.insurance
        this.iron_clothes = dadosOcorrencia.iron_clothes
        this.observation = dadosOcorrencia.observation
        this.ocurrence_date = dadosOcorrencia.ocurrence_date
        this.responsible = dadosOcorrencia.responsible
        this.routing = dadosOcorrencia.routing
        this.solution = dadosOcorrencia.solution
        this.technician = dadosOcorrencia.technician
        this.tests = dadosOcorrencia.tests
        this.ticket = dadosOcorrencia.ticket
        this.wash_instruct = dadosOcorrencia.wash_instruct
        this.wash_used = dadosOcorrencia.wash_used

    }

    static create (ocorrencia) {
        return new OcorrenciaInput(ocorrencia)
    }

}

