
import React from "react"
import ModalEditItem from "../../../../components/ModalEditItem"
import CampoInputAssociado from "../../../associado/components/Inputs/CampoInputAssociado/CampoInputAssociado"
import CampoInputAutorizaTestes from "../Inputs/CampoInputAutorizaTestes/CampoInputAutorizaTestes"
import CampoInputDanoReclamado from "../Inputs/CampoInputDanoReclamado/CampoInputDanoReclamado"
import CampoInputDataEmissaoLaudo from "../Inputs/CampoInputDataEmissaoLaudo/CampoInputDataEmissaoLaudo"
import CampoInputDataOcorrencia from "../Inputs/CampoInputDataOcorrencia/CampoInputDataOcorrencia"

import CampoInputEncaminhaSeguro from "../Inputs/CampoInputEncaminhaSeguro/CampoInputEncaminhaSeguro"
import CampoInputFoiPassado from "../Inputs/CampoInputFoiPassado/CampoInputFoiPassado"
import CampoInputFoiSeco from "../Inputs/CampoInputFoiSeco/CampoInputFoiSeco"
import CampoInputInstrucaoLimpeza from "../Inputs/CampoInputInstrucaoLimpeza/CampoInputInstrucaoLimpeza"
import CampoInputLimpezaUtilizada from "../Inputs/CampoInputLimpezaUtilizada/CampoInputLimpezaUtilizada"
import CampoInputMarcas from "../Inputs/CampoInputMarcas/CampoInputMarcas"
import CampoInputNomeCliente from "../Inputs/CampoInputNomeCliente/CampoInputNomeCliente"
import CampoInputObservacao from "../Inputs/CampoInputObservacao/CampoInputObservacao"
import CampoInputPecas from "../Inputs/CampoInputPecas/CampoInputPecas"
import CampoInputResponsabilidade from "../Inputs/CampoInputResponsabilidade/CampoInputResponsabilidade"
import CampoInputSolucao from "../Inputs/CampoInputSolucao/CampoInputSolucao"
import CampoInputTecnico from "../Inputs/CampoInputTecnico/CampoInputTecnico"
import CampoInputTicket from "../Inputs/CampoInputTicket/CampoInputTicket"
import CampoInputTipoFibra from "../Inputs/CampoInputTipoFibra/CampoInputTipoFibra"

const ModalOcorrencia = ({ open, handleClose, handleConfirma, selected, listaAssociados }) => {

    return (
        <ModalEditItem open={open} onClose={handleClose} confirma={handleConfirma} titulo="Editar ocorrência" >
            {selected === 'associado' && <CampoInputAssociado listaAssociados={listaAssociados}/>}
            {selected === 'items' && <CampoInputPecas />}
            {selected === 'brands' && <CampoInputMarcas />}
            {selected === 'wash_instruct' && <CampoInputInstrucaoLimpeza />}
            {selected === 'wash_used' && <CampoInputLimpezaUtilizada />}
            {selected === 'damage' && <CampoInputDanoReclamado />}
            {selected === 'ocurrence_date' && <CampoInputDataOcorrencia />}
            {selected === 'fiber_type' && <CampoInputTipoFibra />}
            {selected === 'iron_clothes' && <CampoInputFoiPassado />}
            {selected === 'dry_dryer' && <CampoInputFoiSeco />}
            {selected === 'insurance' && <CampoInputEncaminhaSeguro />}
            {selected === 'tests' && <CampoInputAutorizaTestes />}
            {selected === 'responsible' && <CampoInputResponsabilidade />}
            {selected === 'technician' && <CampoInputTecnico />}
            {selected === 'client' && <CampoInputNomeCliente />}
            {selected === 'ticket' && <CampoInputTicket />}
            {/* {selected === 'routing' && <CampoInputEncaminha />} */}
            {selected === 'solution' && <CampoInputSolucao />}
            {selected === 'observation' && <CampoInputObservacao />}
            {selected === 'out_date' && <CampoInputDataEmissaoLaudo />}

        </ModalEditItem>
    )
}

export default ModalOcorrencia