import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';

import React, { useState } from 'react';

import * as S from './style'
import { retornaPrimeiraLetraEmMaiuscula } from '../../../../Utilitarios';
import { useLogout } from '../../hooks/useLogout/useLogout';
import { useDisplayAlertAfterServerResponse } from '../../../shared/hooks/useDisplayAlertAfterServerResponse/useDisplayAlertAfterServerResponse';
import { useNavigate } from 'react-router-dom';

export default function AccountMenu({email}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [resposta, logout] = useLogout()
  useDisplayAlertAfterServerResponse(resposta)
  const navigate = useNavigate()
  const open = Boolean(anchorEl);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = (e) => {
    e.preventDefault()
    logout()
    navigate('/login')
  }

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Configurações de conta">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 32, height: 32, backgroundColor: '#206BAD' }}>{retornaPrimeiraLetraEmMaiuscula(email)}</Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        disableScrollLock={true}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem>
          <S.ContainerMenuItem to="/meus-dados" aria-label='MeusDados'>
            Meus dados
          </S.ContainerMenuItem>
        </MenuItem>
        <MenuItem>
        <S.ContainerMenuItem to="/alterar-senha" aria-label='AlterarSenha' >
          Alterar senha
        </S.ContainerMenuItem>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" aria-label='Logout' />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}