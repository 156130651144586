import * as React from 'react';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import IconButton from '@mui/material/IconButton';
import { Tooltip } from '@mui/material'; 

export default function WatchItem(props) {

    return (
        <Tooltip title="Visualizar">
            <IconButton onClick={props.onClick}>
                <RemoveRedEyeIcon />
            </IconButton>
        </Tooltip>
    )
}