import { useCallback, useState } from "react"
import ListaActivateIds from "../../../shared/models/ListaActivateIds/ListaActivateIds"
import http from "../../../../http"

export const useAtivaCadastros = () => {
    const [resposta, setResposta] = useState({})

    const ativaCadastros = useCallback((listaIdsCadastro) => {
        const listaIdsUsuariosASeremAtivados = ListaActivateIds.create(listaIdsCadastro)
        setResposta(estadoAnterior => ({ ...estadoAnterior }))
        http.put(`/ativa-multiplos-cadastros`, listaIdsUsuariosASeremAtivados)
            .then(res => {
                setResposta({ data: res.data, error: null })
            })
            .catch(error => {
                setResposta({ data: null, error })
            })
    }, [])
    return [resposta, ativaCadastros]
}