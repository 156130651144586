import React, { useEffect, useState } from 'react'

import MainTitle from "../../modules/shared/components/MainTitle"
import FormEditaAssociado from '../../modules/associado/components/FormEditaAssociado/FormEditaAssociado'
import ModalAssociados from '../../modules/associado/components/ModalAssociados/ModalAssociados'
import { useDispatch, useSelector } from 'react-redux'
import { handleAssociadoChange, resetAssociado } from '../../store/reducers/associadoSlice'
import { useDisplayAlertAfterServerResponse } from '../../modules/shared/hooks/useDisplayAlertAfterServerResponse/useDisplayAlertAfterServerResponse'
import { resetAssociadoSelecionado } from '../../store/reducers/associadoSelecionadoSlice'
import { resetLocalizacao } from '../../store/reducers/localizacaoSlice'
import { resetOcorrencia } from '../../store/reducers/ocorrenciaSlice'
import { resetOcorrenciaSelecionada } from '../../store/reducers/ocorrenciaSelecionadaSlice'
import { resetUsuario } from '../../store/reducers/usuarioSlice'
import { useBuscaListaCadastros } from '../../modules/associado/hooks/useBuscaListaCadastros'
import { useBuscaListaEmails } from '../../modules/associado/hooks/useBuscaListaEmails'
import { handleEmailAssociadoSelecionadoChange } from '../../store/reducers/emailAssociadoSelecionadoSlice'
import { useAtualizaCadastroPorADM } from '../../modules/associado/hooks/useAtualizaCadastroPorADM'

export default function EditarAssociado() {

    const [open, setOpen] = useState(false)
    const [tempValues, setTempValues] = useState()
    const [selected, setSelected] = useState()

    const dadosAssociado = useSelector(state => state.associadoState)
    const emailAssociadoSelecionado = useSelector(state => state.emailAssociadoSelecionadoState)
    const dispatch = useDispatch()
    const { listaAssociados } = useBuscaListaCadastros()
    const { listaUsuarios } = useBuscaListaEmails()
    const [resposta, atualizarCadastroPorADM] = useAtualizaCadastroPorADM()

    useDisplayAlertAfterServerResponse(resposta)

    useEffect(() => {
        dispatch(resetAssociado())
        dispatch(resetAssociadoSelecionado())
        dispatch(resetLocalizacao())
        dispatch(resetOcorrencia())
        dispatch(resetOcorrenciaSelecionada())
        dispatch(resetUsuario())
    }, [dispatch])

    

    const handleClickEditaAssociado = (name) => {
        setSelected(name)
        if(name === 'email') {
            setTempValues({
                [name]: emailAssociadoSelecionado[name], 
                id: emailAssociadoSelecionado['id']
            })
        } else {
            setTempValues({[name]: dadosAssociado[name]})
        }
        setOpen(true)
    }

    const handleSubmitForm = (cadastroId, inputForms) => {
        atualizarCadastroPorADM(cadastroId, inputForms)
    }

    const handleCloseModal = () => {
        if(selected === 'email') {
            dispatch(handleEmailAssociadoSelecionadoChange(tempValues))
        } else {
            dispatch(handleAssociadoChange(tempValues))
        }
        setOpen(false)
        setTempValues(false)
    }

    const handleConfirma = () => {
        setOpen(false)
    }

    return (
        <>
            <MainTitle titulo={"Editar associado"} tituloPrincipal={true}/>
            <FormEditaAssociado listaAssociados={listaAssociados} listaUsuarios={listaUsuarios} onClick={handleClickEditaAssociado} aoSubmeterForm={handleSubmitForm} />
            <ModalAssociados open={open} handleClose={handleCloseModal} handleConfirma={handleConfirma} selected={selected} listaUsuarios={listaUsuarios} />
        </>
    )
}