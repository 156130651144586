import React, { useState, useEffect } from 'react'

import TabelaMeusEstabelecimentos from '../../modules/associado/components/TabelaMeusEstabelecimentos/TabelaMeusEstabelecimentos'
import { useBuscaMeusCadastros } from '../../modules/associado/hooks/useBuscaMeusCadastros'
import MainTitle from '../../modules/shared/components/MainTitle'

export default function MeusEstabelecimentos() {

  const [listaCadastrosCompleto, setListaCadastrosCompleto] = useState([])
  const [resposta, buscaMeusCadastros] = useBuscaMeusCadastros()

  useEffect(() => {
    buscaMeusCadastros()
  }, [buscaMeusCadastros])

  useEffect(() => {
    if(Object.entries(resposta).length > 0) {
        const listaTabela = resposta.data.map(value => {
            const { cadastro } = value
            return {
              social_name: cadastro.getRazaoSocial(),
              cnpj: cadastro.getCnpj(),
              owner: cadastro.getProprietario(),
              ativo: cadastro.getAtivo() ? "Sim" : "Não",
              id: cadastro.getCadastroId()
            }
        })
        setListaCadastrosCompleto(listaTabela)
    }
}, [resposta])


  return (
    <>
      <MainTitle tituloPrincipal={true} titulo="Lista de estabelecimentos cadastrados" />
      <TabelaMeusEstabelecimentos
        tituloToolbar="Meus Estabelecimentos"
        cadastros={listaCadastrosCompleto}
        onClick={() => {}} />
    </>
  )
}