import React, { useEffect, useState, } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import http from '../../http'
import * as S from './style'
import Logo from '../../components/Logo'
import ModalRecuperaSenha from '../../modules/usuario/ModalRecuperaSenha/ModalRecuperaSenha';
import { Alert, Fade, Snackbar } from '@mui/material';
import { useSolicitaRecuperacaoSenha } from '../../modules/usuario/hooks/useSolicitaRecuperacaoSenha/useSolicitaRecuperacaoSenha';
import { useDispatch, useSelector } from 'react-redux';
import { resetUsuario } from '../../store/reducers/usuarioSlice'
// import * as S from './style'

export default function Login() {
    const [email, setEmail] = useState("");
    const [senha, setSenha] = useState("");
    const [open, setOpen] = useState(false)
    const [severity, setSeverity] = useState()
    const [mensagemAlert, setMensagemAlert] = useState(false)
    const [openSnackBar, setOpenSnackBar] = useState(false)

    const { email: emailRecuperacao } = useSelector(state => state.usuarioState)
    const [resposta, solicitaRecuperacaoSenha] = useSolicitaRecuperacaoSenha()

    const navigate  = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        if(resposta.data) {
            dispatch(resetUsuario())
            setSeverity("success")
            setMensagemAlert("O link para recuperação de senha foi enviado para o seu e-mail.")
            setOpen(false)
            setOpenSnackBar(true)
        }
        if(resposta.error) {
            const { data } = resposta.error
            setSeverity("warning")
            setMensagemAlert(data)
            setOpenSnackBar(true)
        }
        
    }, [resposta, dispatch])

    const aoSubmeterForm = event => {
        event.preventDefault()
        const user = {
            email,
            senha
        }
        http.post('/usuarios/login', user)
            .then(res => {
                if (res.status === 200) {
                    localStorage.setItem('Authorization', res.headers.authorization)
                    localStorage.setItem('RefreshToken', res.data.refreshToken)
                    navigate('/meus-dados')
                }
            })
            .catch(err => {
                const { response } = err
                alert(response.data)
            })
        setEmail("");
        setSenha("");
    }

    const handleChangeLogin = (event) => {
        setEmail(event.target.value)
    }
    const handleChangeSenha = (event) => {
        setSenha(event.target.value)
    }

    const handleRecuperaSenha = (e) => {
        e.preventDefault()
        setOpen(true)
    }

    const handleClose = (e) => {
        e.preventDefault()
        setOpen(false)
        dispatch(resetUsuario())
    }

    const handleCloseAlert = (e) => {
        e.preventDefault()
        setOpenSnackBar(false)
    }

    const handleConfirma = (e) => {
        e.preventDefault()
        solicitaRecuperacaoSenha(emailRecuperacao)
        setOpenSnackBar(false)
    }

    const handleCloseSnackBar = () => {
        setOpenSnackBar(false)
    }


    return (
        <>
            <S.ContainerPage>
                <S.NavBarLogin sx={{backgroundColor: '#FFF'}}>
                    <S.ContainerLogoTitle>
                        <Logo />
                        <S.NavBarTitle>Área do associado - Login</S.NavBarTitle>
                    </S.ContainerLogoTitle>
                </S.NavBarLogin>
                
                <S.ContainerLogin>
                    <S.Formulario method="post" onSubmit={aoSubmeterForm}>
                        <S.ContainerTextInputs>
                            <S.FormTitle>Usuário*</S.FormTitle>
                            <S.formInput type="text" value={email} onChange={handleChangeLogin} placeholder="E-mail*"/>
                            <S.FormTitle>Senha*</S.FormTitle>
                            <S.formInput type="password" value={senha} onChange={handleChangeSenha} placeholder="Senha*" />
                        </S.ContainerTextInputs>
                        <S.ContainerNaoPossuiConta>Não possui conta? <Link to="/cadastrar-associados" >Cadastre-se aqui.</Link></S.ContainerNaoPossuiConta>
                        <S.ContainerButtons>
                            <S.LoginButton type="submit" variant="contained" sx={{ margin: '10px'}}>Fazer login</S.LoginButton>
                            <S.RecuperarSenhaButton onClick={handleRecuperaSenha} variant="outlined" sx={{ margin: '10px'}}>Recuperar senha</S.RecuperarSenhaButton>
                        </S.ContainerButtons>
                    </S.Formulario>
                </S.ContainerLogin>
            </S.ContainerPage>
            <ModalRecuperaSenha open={open} handleClose={handleClose} handleConfirma={handleConfirma} />
            <Snackbar
                open={openSnackBar}
                onClose={handleCloseSnackBar}
                TransitionComponent={Fade}
                autoHideDuration={6000} >
                <Alert onClose={handleCloseAlert} severity={severity} sx={{ width: '100%' }}>
                    {mensagemAlert}
                </Alert>
            </Snackbar>
        </>
    )
}

