import { useSelector } from 'react-redux';
import CampoInputTexto from '../../../../shared/components/CampoInputComButton/CampoInputTexto/CampoInputTexto';
import VoidIcon from '../../../../shared/components/IconButtons/VoidIcon/VoidIcon';

const CampoInputEstado = ({ editButton }) => {

    const { estado } = useSelector(state => state.localizacaoState)
    
    return (
        <CampoInputTexto
            id="inputEstado"
            titulo="Estado*"
            name="estado"
            value={estado}
            required={true}
            disabled={true}
        >
            {
                editButton ? <VoidIcon /> : <></>
            }
        </CampoInputTexto>
    )
}

export default CampoInputEstado
