import React from 'react'
import ContainerTitleInputField from "../../../../components/Containers/ContainerTitleInputField"
import MyDropZone from "../../../shared/components/MyDropZone"

const CampoUploadFile = ({ name, aoAceitarArquivoDropZone }) => {

    const aoDropar = ([e]) => {
        aoAceitarArquivoDropZone(name, e)
    }

    return (
        <ContainerTitleInputField titulo="Upload de laudo*">
            <MyDropZone
                id="uploadDeLaudo"
                onDropAccepted={aoDropar}
            />
        </ContainerTitleInputField>
    )
}

export default CampoUploadFile