import React, { useState, useEffect } from 'react'

import Botao from "../../../../components/Botao"
import ContainerTitleInputField from "../../../../components/Containers/ContainerTitleInputField"
import CamposInformacoesDaOcorrencia from "../CamposInformacoesDaOcorrencia/CamposInformacoesDaOcorrencia"
import CampoUploadFile from "../CampoUploadFile/CampoUploadFile"
import ShowOrHide from '../../../shared/components/ShowOrHide/ShowOrHide'
import CampoInputCodigoAT from '../Inputs/CampoInputCodigoAT/CampoInputCodigoAT'
import { useDispatch, useSelector } from 'react-redux'
import CampoInputSolucao from '../Inputs/CampoInputSolucao/CampoInputSolucao'
import { handleOcorrenciaChange, resetOcorrencia } from '../../../../store/reducers/ocorrenciaSlice'
import {useDisplayAlertAfterServerResponse} from '../../../shared/hooks/useDisplayAlertAfterServerResponse/useDisplayAlertAfterServerResponse'
import { handleAssociadoSelecionadoChange, resetAssociadoSelecionado } from '../../../../store/reducers/associadoSelecionadoSlice'
import { resetOcorrenciaSelecionada } from '../../../../store/reducers/ocorrenciaSelecionadaSlice'

const FormLaudos = ({
    useBuscaOcorrenciaParaCamposPreenchidos, 
    listaParaAutocomplete,
    useOnSubmitForm,
    children
}) => {

    const { codigoAT } = useSelector(state => state.ocorrenciaSelecionadaState)
    const { solution } = useSelector(state => state.ocorrenciaState)
    const [idOcorrencia, setIdOcorrencia] = useState("")
    const [file, setFile] = useState()
    const [resposta, executaOnSubmitForm] = useOnSubmitForm()
    useDisplayAlertAfterServerResponse(resposta)

    const dispatch = useDispatch()

    const handleChange = (name, value) => {
        setFile(value)
    }

    const { dadosCadastro, 
        dadosOcorrencia: dadosOcorrenciaInicial, 
        pecas: pecasInicial, 
        marcas: marcasInicial 
    } = useBuscaOcorrenciaParaCamposPreenchidos(idOcorrencia)

    useEffect(() => {
        dispatch(resetOcorrenciaSelecionada())
        setIdOcorrencia("")
    }, [dispatch])

    useEffect(() => {
        if (codigoAT) {
            setIdOcorrencia(codigoAT)
        } else {
            setIdOcorrencia(false)
            dispatch(resetOcorrencia())
            dispatch(resetOcorrenciaSelecionada())
            dispatch(resetAssociadoSelecionado())
        }
        
    }, [codigoAT, dispatch])


    useEffect(() => {
        if(dadosOcorrenciaInicial) {
            dispatch(handleOcorrenciaChange({solution: dadosOcorrenciaInicial.getSolucao()}))
        }
    }, [dadosOcorrenciaInicial, dispatch])

    useEffect(() => {
        if(pecasInicial) {
            dispatch(handleOcorrenciaChange({
                items: pecasInicial.listaPecas
            }))
        }
    }, [pecasInicial, dispatch])

    useEffect(() => {
        if(marcasInicial) {
            dispatch(handleOcorrenciaChange({
                brands: marcasInicial.listaMarcas
            }))
        }
    }, [marcasInicial, dispatch])

    useEffect(() => {
        if(dadosCadastro) {
            const { id, associado } = dadosCadastro
            dispatch(handleAssociadoSelecionadoChange({ id, associado }))
        }
    }, [dadosCadastro, dispatch])


    return (
        <form onSubmit={(e) => {
            e.preventDefault()
            try {
                executaOnSubmitForm({
                    codigoAT,
                    solucao: solution,
                    file
                }) 
            } catch(error) {
                alert(error)
            }
         }}>
            <CampoInputCodigoAT
                listaParaAutocomplete={listaParaAutocomplete} />
            <ShowOrHide campo={idOcorrencia} >
                <CamposInformacoesDaOcorrencia 
                    dadosCadastro={dadosCadastro} 
                    dadosOcorrencia={dadosOcorrenciaInicial} 
                    disabled={true} />
                <CampoInputSolucao
                    editButton={false}
                    disabled={false} />
                <>
                    {children}
                </>
            </ShowOrHide>
            <CampoUploadFile name="file" aoAceitarArquivoDropZone={handleChange}/>
            <ContainerTitleInputField titulo="" botao>
                <Botao titulo="Cadastrar laudo" type="submit" />
            </ContainerTitleInputField>
        </form>
    )
}

export default FormLaudos