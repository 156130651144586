import OcorrenciaInput from "./OcorrenciaInput"

export default class OcorrenciaUpdate extends OcorrenciaInput {
    constructor(dadosOcorrencia) {   
        super(dadosOcorrencia)   
        this.cadastro_id = dadosOcorrencia.cadastro_id
        this.out_date = dadosOcorrencia.out_date
    }

    static create (ocorrencia) {
        return new OcorrenciaUpdate(ocorrencia)
    }

}

