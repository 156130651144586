import styled from 'styled-components'

const Title = styled.h3`
    fontsize: 18px;
    padding: 20px;
    background-color: #ededed;
    border-radius: 5px;
    `
    
    const Subtitle = styled.h4`
    fontsize: 18px;
    padding: 20px;
    background-color: #fbfbfb;
    border-radius: 5px;
`

export default function MainTitle(props) {

    return (
        <>
            {props.tituloPrincipal && (
                <Title>
                    {props.titulo}
                </Title>
            )}
            {props.subtitulo && (
                <Subtitle>
                    {props.titulo}
                </Subtitle>
            )}
        </>
    )
}
