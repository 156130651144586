import styled from 'styled-components'
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import { Button } from '@mui/material';

export const ContainerMain = styled.div`

`

export const ContainerLaudos = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`
export const ContainerItemCadastro = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: minmax(56px, 1fr);
    align-items: center;
    background-color: #fbfbfb;
    padding: 20px;
    border: 1px solid #ededed;
    border-radius: 5px;
    gap: 25px;
    @media (max-width: 950px) {
        grid-template-columns: 1fr;
        gap: 0;
    }
`

export const ContainerEditItem = styled.div`
    display: grid;
    grid-template-columns: 1fr 50px;
    grid-template-rows: minmax(56px, 1fr);
`

export const ItemTitle = styled.h5`
    font-weight: normal;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%
    display: flex;
    align-items: center;
    justify-content: flex-end;
    justify-self: flex-end;
    text-align: right;
    @media (max-width: 950px) {
        justify-self: flex-start;
    }
`

export const VoidTitle = styled.h5`
    
    @media (max-width: 950px) {
        display: none;
    }
`

export const Formulario = styled.form`
    display: flex;
    flex-direction: column;
`
export const InputSelect = styled(Select)`
    height: 38px;
    padding: 0;
    max-width: 420px;
    width: 100%;
`

export const InputText = styled(TextField)`
    max-width: 420px;
    width: 100%;
`

export const CadastrarOcorrenciaButton = styled(Button)`
    width: 200px;
    
`

export const VoidIcon = styled.div`
    align-self: flex-end;
    margin-left: 20px;
    margin-right: 20px;
`


export const TitleLaudos = styled.h3`
    fontsize: 18px;
    padding: 20px;
    background-color: #ededed;
`

