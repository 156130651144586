import MarcaOutput from './MarcaOutput'
 
export default class MarcasOutput {
    constructor (marcas) {
        this.marcas = marcas.map(marca => MarcaOutput.create(marca)) 
    }

    static create(marcas) {
        return new MarcasOutput(marcas)
    }

    get listaMarcas() {
        return this.marcas.map(marca => {
            const { id, name } = marca
            return {
                id,
                name
            }
        })
    }
    
    get listaIdMarcas() {
        return this.marcas.map(marca => marca.id)
    }
    
    get listaNomeMarcas() {
        return this.marcas.map(marca => marca.name)
    }
}