import UsuarioAbstract from "./UsuarioAbstract"

export default class UsuarioOutput extends UsuarioAbstract {
    constructor(dadosUsuario) {
        super(dadosUsuario.email)
        this.id = dadosUsuario.id
        this.emailVerificado = dadosUsuario.emailVerificado
        this.permissao_id = dadosUsuario.permissao_id
        this.senhaHash = dadosUsuario.senhaHash
    }

    static create (usuario) {
        return new UsuarioOutput(usuario)
    }

    static validate (cadastro) {
        if(!cadastro.id) {
            return false
        }
        return true
    }

    getId() {
        return this.id
    }
    
    getPermissao() {
        return this.permissao_id
    }

    getEmail() {
        return this.email
    }
}

