import { useCallback, useState } from "react"
import http from "../../../../http"

export const useVerificaEmail = () => {
    const [resposta, setResposta] = useState({})

    const verificaEmail = useCallback((token) => {
        http.get(`/usuarios/verifica-email/${token}`)
            .then(res => {
                setResposta({ data: res, error: null })
            })
            .catch(error => {
                const { response } = error
                setResposta({data: null, error: response})
            })
    }, [])
    return [resposta, verificaEmail]
}