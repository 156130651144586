import React from 'react'

const ShowOrHide = ({ campo, children }) => {
    return (
        campo && (
            <>
                {children}
            </>
        )
    )
}

export default ShowOrHide