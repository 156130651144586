import * as React from 'react';
import * as S from './style'

import Paper from '@mui/material/Paper';
import ArticleIcon from '@mui/icons-material/Article';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import GroupsIcon from '@mui/icons-material/Groups';


export default function DataCard(props) {
  return (
      <Paper elevation={1} >
          <S.ContainerCard>
              {
                props.tipo === 1 && (
                    <ArticleIcon sx={{ fontSize: 100, fill: props.cor }} />
                )
              }
              {
                props.tipo === 2 && (
                    <FolderCopyIcon sx={{ fontSize: 100, fill: props.cor }} />
                )
              }
              {
                props.tipo === 3 && (
                    <PersonAddAlt1Icon sx={{ fontSize: 100, fill: props.cor }} />
                )
              }
              {
                props.tipo === 4 && (
                    <GroupsIcon sx={{ fontSize: 100, fill: props.cor }} />
                )
              }
              <S.ContainerDadosCard>
                  <S.TitleCard>{props.titulo}</S.TitleCard>
                  <S.CardData>{props.dado}</S.CardData>
              </S.ContainerDadosCard>
          </S.ContainerCard>
      </Paper>
  );
}