import React, { useState, useEffect } from 'react'
import { Paper } from '@mui/material'
import MainTitle from "../../modules/shared/components/MainTitle"
import http from '../../http'
import FormInputTexto from '../../components/FormInputTexto'
import ContainerTitleInputField from '../../components/Containers/ContainerTitleInputField'

export default function MeusDados() {

    const [email, setEmail] = useState("")
    const [tipoConta, setTipoConta] = useState("")
    const [dataCriacao, setDataCriacao] = useState("")

    useEffect(() => {
        http.get('/meus-dados')
        .then(res => {
            const user = res.data
            setEmail(user.email)
            
            const dataCriacao = new Date(user.createdAt)
            const anoAgora = dataCriacao.getFullYear()
            const mesAgora = ("0" + (dataCriacao.getMonth() + 1)).slice(-2)
            const diaAgora = ("0" + dataCriacao.getDate()).slice(-2)
            setDataCriacao(`${anoAgora}-${mesAgora}-${diaAgora}`)
            
            const permissoes = {
                1: 'Inativo',
                2: 'Associado',
                3: 'Administrador'
            }
            setTipoConta(permissoes[user.permissao_id])
        })
    }, [])

    return (
        <Paper>
            <MainTitle titulo="Meus dados" tituloPrincipal />
            <ContainerTitleInputField titulo="E-mail">
                <FormInputTexto
                disabled={true}
                value={email} />
            </ContainerTitleInputField>
            <ContainerTitleInputField titulo="Tipo de conta" >
                <FormInputTexto
                disabled={true}
                value={tipoConta} />
            </ContainerTitleInputField>
            <ContainerTitleInputField titulo="Data de criação" >
                <FormInputTexto
                type="date"
                disabled={true}
                value={dataCriacao} />
            </ContainerTitleInputField>
        </Paper>
    )
}