import { matchSorter } from "match-sorter"

export const transformaListaObjetoEmListaDeCampoUnico = (listaOpcoes, campo) => {
    const listaItens = listaOpcoes.map(value => `${value[campo]}`)
    return listaItens
}

export const removerDuplicadosPor = (keyFun, array) => {
    let mySet = new Set()
    return array.filter((x) => {
        let key = keyFun(x)
        let isNew = !mySet.has(key)
        if (isNew) mySet.add(key)
        return isNew
    })
}

export const retornaListaDeMarcasOuPecas = (listaNomeItem, listaItemIdNome) => {
    if (!listaNomeItem || !listaItemIdNome) {
        return []
    }
    const retornaListaItems = listaNomeItem.map(value => {
        const [objItem] = listaItemIdNome.filter(peca => peca.name === value)
        return objItem.id
    })
    return retornaListaItems
}

export const retornaListaMatchPorListaCampo = (listaCompleta, campo, valor) => {    
    const novaListaFiltrada = matchSorter(listaCompleta, valor, {
        keys: [campo],
        threshold: matchSorter.rankings.CONTAINS
    })
    return novaListaFiltrada
}

export const criaObjetoURL = (dado) => {
    return URL.createObjectURL(dado)
}

export const retornaIndexDaLista = (lista, valor) => {
    return lista.indexOf(valor)
}

export const retornaListaIndexDaLista = (listaCompleta, listaValores) => {
    if (!listaCompleta || !listaValores) {
        return []
    }
    const retornaListaItems = listaValores.map(value => {
        const [objItem] = listaCompleta.filter(peca => peca.name === value)
        return objItem.id
    })
    return retornaListaItems
}

export const retornaSomenteNumerosDeString = (string) => {
    const numbers = string.replace(/\D/g, "")
    return numbers
}

export const cnpjMask = (value) => {
    return value
        .replace(/\D+/g, '') // não deixa ser digitado nenhuma letra
        .replace(/(\d{2})(\d)/, '$1.$2') // captura 2 grupos de número o primeiro com 2 digitos e o segundo de com 3 digitos, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de número
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1/$2') // captura 2 grupos de número o primeiro e o segundo com 3 digitos, separados por /
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1') // captura os dois últimos 2 números, com um - antes dos dois números
}

export const cepMask = (value) => {
    return value
        .replace(/\D+/g, '')
        .replace(/(\d{5})(\d)/, '$1-$2')
        .replace(/(-\d{3})\d+?$/, '$1')
}

export const telefoneMask = (value) => {
    if (value.length <= 14) {
        return value
            .replace(/\D+/g, "")
            .replace(/(\d{2})(\d)/, '($1) $2')
            .replace(/(\d{4})(\d)/, '$1-$2')
            .replace(/(-\d{4})\d+?$/, "$1")
    } else {
        return value
            .replace(/\D+/g, "")
            .replace(/(\d{2})(\d)/, '($1) $2')
            .replace(/(\d{5})(\d)/, '$1-$2')
            .replace(/(-\d{4})\d+?$/, "$1")
    }
}

export const retornaPrimeiraLetraEmMaiuscula = (string) => {
    return typeof string === 'string' ? string[0].toUpperCase() : `${string}`[0].toUpperCase()
}
