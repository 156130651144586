import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'


export default function Loading() {
    return (
        <>
            <CircularProgress />
        </>
    )
}