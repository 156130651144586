import React from 'react';

import Rotas from './usuarioRoute';

const TodasAsRotas = () => {
    return (
        <Rotas />
    )
};

export default TodasAsRotas;