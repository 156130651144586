import { useEffect } from "react"

import ContainerItemGenerico from "../../../../../components/Containers/ContainerItemGenerico"
import Campo from "../Campo/Campo"
import Valor from "../Valor/Valor"
import ValorMaiusculo from "../ValorMaiusculo/ValorMaiusculo"
import * as S from './style'
import { useDispatch } from "react-redux"
import { resetCampoValor } from "../../../../../store/reducers/campoValorSlice"

export default function MatchFilter({maiusculo, itensDoSelect, children}) {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(resetCampoValor())
    }, [dispatch])

    return (
        <>
            <ContainerItemGenerico>
                <S.ContainerItemCadastro>
                    <S.ContainerCampo>
                        <Campo itensDoSelect={itensDoSelect} />
                    </S.ContainerCampo>
                    <S.ContainerCampo>
                        {
                            maiusculo && (<ValorMaiusculo />)
                        }
                        {
                            !maiusculo && (<Valor />)
                        }

                    </S.ContainerCampo>
                    {
                        children && 
                        (<S.ContainerBotaoOpcional>
                        {   children}
                        </S.ContainerBotaoOpcional>)
                    }
                    
                </S.ContainerItemCadastro>
            </ContainerItemGenerico>
        </>
    )
}