import React from 'react'
import { useDispatch } from 'react-redux'
import { VoidIcon } from '../../../../../paginas/EditarOcorrencia/style'
import { handleOcorrenciaSelecionadaChange, resetOcorrenciaSelecionada } from '../../../../../store/reducers/ocorrenciaSelecionadaSlice'
import CampoInputAutocomplete from '../../../../shared/components/CampoInputComButton/CampoInputAutocomplete/CampoInputAutocomplete'

const CampoInputCodigoAT = ({ listaParaAutocomplete, editButton }) => {

    const dispatch = useDispatch()
    
    const handleChange = (name, value) => {
        if(value) {
            dispatch(handleOcorrenciaSelecionadaChange({[name]: value.id}))
        } else {
            dispatch(resetOcorrenciaSelecionada())
        }
    }

    return (
        <CampoInputAutocomplete 
            titulo="Código AT*" 
            id="formAutocompleteCodigoAT" 
            name="codigoAT" 
            onChange={handleChange}
            getOptionLabel={option => option.id.toString()}
            renderOption={(props, option, state) => [props, option.id, state.id]}
            listaAutocomplete={listaParaAutocomplete} >
                {
                    editButton ? <VoidIcon /> : <></>
                }
            </CampoInputAutocomplete>
    )
}

export default CampoInputCodigoAT