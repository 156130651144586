import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import * as S from './style'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch } from 'react-redux';
import { fecharSideBar } from '../../../../store/reducers/estadoMobileSlice';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    // border: `1px solid ${theme.palette.divider}`,
    // '&:not(:last-child)': {
    //     borderBottom: 0,
    // },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ fontSize: '1.5rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(255, 255, 255, .03)',
    flexDirection: 'row',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(180deg)',
    },
    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, .03)'
    }
    // '& .MuiAccordionSummary-content': {
    //     marginLeft: theme.spacing(1),
    // },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: 0,
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    // backgroundColor: 'rgba(0, 0, 0, .03)'
}));

export default function AccordionCustomizado(props) {
    const [expanded, setExpanded] = React.useState('');
    const dispatch = useDispatch()

    const resolveSideBar = () => {
        dispatch(fecharSideBar())
        setExpanded(false)
    }

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };    
    
    return (
        <div>
            {
                props.simpleItems && props.simpleItems.map((simpleItem, index) => {
                    return (
                        <Accordion key={index}>
                            <AccordionDetails sx={{ borderTop: '0px', borderBottom: '0px', }}>
                                <ListItem key={simpleItem} disablePadding >
                                    <S.ContainerMenuItem to={simpleItem.caminho} onClick={resolveSideBar} >
                                        <ListItemButton>
                                            <ListItemIcon sx={{justifyContent: 'center'}}>
                                                {simpleItem.icone}
                                            </ListItemIcon>
                                            <ListItemText primary={simpleItem.nome} />
                                        </ListItemButton>
                                    </S.ContainerMenuItem>
                                </ListItem>
                            </AccordionDetails>
                        </Accordion>
                    )
                })
            }
            {
                props.items && props.items.map((itemAccordion, index) => {
                    return (
                        <Accordion expanded={expanded === itemAccordion.painel} onChange={handleChange(itemAccordion.painel)} key={index}>
                            <AccordionSummary aria-controls={`${itemAccordion.painel}d-content`} id={`${itemAccordion.painel}d-header`} >
                                <ListItemIcon sx={{justifyContent: 'center'}}>
                                    {itemAccordion.icone}
                                </ListItemIcon>
                                <Typography>{itemAccordion.titulo}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {
                                    itemAccordion.item.map((itemDetalheAccordion) => {
                                        return (
                                            <ListItem key={itemDetalheAccordion.chave} disablePadding>
                                                <S.ContainerMenuItem to={itemDetalheAccordion.caminho} onClick={resolveSideBar}>
                                                    <ListItemButton >
                                                        <ListItemText primary={itemDetalheAccordion.nomeItem} sx={{ marginLeft: '56px' }} />
                                                    </ListItemButton>
                                                </S.ContainerMenuItem>
                                            </ListItem>
                                        )
                                    })
                                }
                            </AccordionDetails>
                        </Accordion>
                    )
                })
            }
        </div>
    );
}