import { useEffect, useState } from "react";
import http from "../../../../http";

export const useBuscaListaDeIdsDeOcorrencias = () => {
    const [listaIdsDeOcorrencia, setListaIdsDeOcorrencias] = useState([])
    
    useEffect(() => {
        http.get('/ocorrencias-id')
        .then(res => {
            const listaOcorrencias = res.data
            setListaIdsDeOcorrencias(listaOcorrencias)
        })
    }, [])

    return {
        listaIdsDeOcorrencia
    }
}