import { Paper } from '@mui/material'
import React, { useEffect, useState } from 'react'
import MainTitle from "../../modules/shared/components/MainTitle"
import { useDisplayAlertAfterServerResponse } from '../../modules/shared/hooks/useDisplayAlertAfterServerResponse/useDisplayAlertAfterServerResponse'
import { handleAssociadoChange, resetAssociado } from '../../store/reducers/associadoSlice'
import { resetLocalizacao } from '../../store/reducers/localizacaoSlice'
import { resetOcorrencia } from '../../store/reducers/ocorrenciaSlice'
import { resetAssociadoSelecionado } from '../../store/reducers/associadoSelecionadoSlice'
import { resetOcorrenciaSelecionada } from '../../store/reducers/ocorrenciaSelecionadaSlice'
import { resetUsuario } from '../../store/reducers/usuarioSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useAtualizaCadastro } from '../../modules/associado/hooks/useAtualizaCadastro'
import ModalAssociados from '../../modules/associado/components/ModalAssociados/ModalAssociados'
import { useBuscaMeusCadastros } from '../../modules/associado/hooks/useBuscaMeusCadastros'
import Associado from '../../modules/associado/models/Associado'
import FormEditaEstabelecimento from '../../modules/associado/components/FormEditaEstabelecimento/FormEditaEstabelecimento'

export default function EditarEstabelecimento() {
    const [open, setOpen] = useState(false)
    const [tempValues, setTempValues] = useState()
    const [selected, setSelected] = useState()
    const [listaMeusCadastros, setListaMeusCadastros] = useState([])
    const dadosAssociado = useSelector(state => state.associadoState)

    const [respostaCadastros, buscaMeusCadastros] = useBuscaMeusCadastros()
    const [resposta, atualizarCadastro] = useAtualizaCadastro()
    useDisplayAlertAfterServerResponse(resposta)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(resetAssociado())
        dispatch(resetAssociadoSelecionado())
        dispatch(resetLocalizacao())
        dispatch(resetOcorrencia())
        dispatch(resetOcorrenciaSelecionada())
        dispatch(resetUsuario())
        buscaMeusCadastros()
    }, [dispatch, buscaMeusCadastros])

    useEffect(() => {
        if(Object.entries(respostaCadastros).length > 0) {
            const listaTabela = respostaCadastros.data.map(value => {
                const { cadastro, usuario } = value
                return Associado.create({
                    id: cadastro.getCadastroId(),
                    associado: cadastro.getAssociado(),
                    'Usuario.id': usuario.getId()
                })
            })
            
            setListaMeusCadastros(listaTabela)
        }
    }, [respostaCadastros])

    const handleClickEditaAssociado = (name) => {
        setSelected(name)
        setTempValues({[name]: dadosAssociado[name]})
        setOpen(true)
    }

    const handleSubmitForm = (cadastroId, inputForms) => {
        atualizarCadastro(cadastroId, inputForms)
    }

    const handleCloseModal = () => {
        dispatch(handleAssociadoChange(tempValues))
        setOpen(false)
    }

    const handleConfirma = () => {
        setOpen(false)
    }

    return (
        <Paper>
            <MainTitle titulo={"Editar estabelecimento"} tituloPrincipal={true} />
            <FormEditaEstabelecimento listaAssociados={listaMeusCadastros} onClick={handleClickEditaAssociado} aoSubmeterForm={handleSubmitForm} />
            <ModalAssociados open={open} handleClose={handleCloseModal} handleConfirma={handleConfirma} selected={selected} />
        </Paper>
    )
}