export default class Laudo {
    constructor(dadosCadastroLaudo) {
        this.codigoAT = dadosCadastroLaudo.codigoAT
        this.file = dadosCadastroLaudo.file
        this.solucao = dadosCadastroLaudo.solucao
    }

    static create(dadosCadastroLaudo) {
        Laudo.validate(dadosCadastroLaudo)
        return new Laudo(dadosCadastroLaudo)
    }

    static validate (dadosCadastroLaudo) {
        if(!dadosCadastroLaudo.codigoAT) throw new Error('O campo CódigoAT é obrigatório')
        if(!dadosCadastroLaudo.file) throw new Error('O arquivo de laudo é obrigatório')
    }
}
