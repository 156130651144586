import { useCallback, useState } from "react"
import ListaDeactivateIds from "../../../shared/models/ListaDeactivateIds/ListaDeactivateIds"
import http from "../../../../http"

export const useDesativaCadastros = () => {
    const [resposta, setResposta] = useState({})

    const desativaCadastros = useCallback((listaIdsCadastro) => {
        const listaIdsUsuariosASeremDesativados = ListaDeactivateIds.create(listaIdsCadastro)
        setResposta(estadoAnterior => ({ ...estadoAnterior }))
        http.put(`/desativa-multiplos-cadastros`, listaIdsUsuariosASeremDesativados)
            .then(res => {
                setResposta({ data: res.data, error: null })
            })
            .catch(error => {
                setResposta({ data: null, error })
            })
    }, [])
    return [resposta, desativaCadastros]
}