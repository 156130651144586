import styled from 'styled-components'
import Select from '@mui/material/Select';

export const InputSelect = styled(Select)`
    height: 38px;
    padding: 0;
    max-width: 420px;
    width: 100%;
    background-color: ${props => props.disabled ? "rgba(100, 100, 100, 0.1)" : "rgb(255, 255, 255, 0)"};
`

export const ContainerComButton = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`

export const ContainerIcon = styled.div`
    align-self: flex-end;
    margin-left: 20px;
    margin-right: 20px;

`