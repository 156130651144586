import { useEffect, useState } from "react";
import http from "../../../../http";

export const useBuscaNumeroCadastros = () => {
    const [totalUsuarios, setTotalUsuarios] = useState('')
    useEffect(() => {
        http.get('/nr-cadastros-totais', )
        .then(res => {
            const nrTotalCadastros = res.data
            setTotalUsuarios(nrTotalCadastros)
        })
    }, [])

    return {
        totalUsuarios
    }
}