import { Paper } from '@mui/material'
import React, { useEffect } from 'react'

import MainTitle from "../../modules/shared/components/MainTitle"
import { useDisplayAlertAfterServerResponse } from '../../modules/shared/hooks/useDisplayAlertAfterServerResponse/useDisplayAlertAfterServerResponse'
import { resetAssociado } from '../../store/reducers/associadoSlice'
import { resetAssociadoSelecionado } from '../../store/reducers/associadoSelecionadoSlice'
import { resetLocalizacao } from '../../store/reducers/localizacaoSlice'
import { resetOcorrencia } from '../../store/reducers/ocorrenciaSlice'
import { resetOcorrenciaSelecionada } from '../../store/reducers/ocorrenciaSelecionadaSlice'
import { resetUsuario } from '../../store/reducers/usuarioSlice'
import { useDispatch } from 'react-redux'
import FormCadastraEstabelecimento from '../../modules/associado/components/FormCadastraEstabelecimento/FormCadastraEstabelecimento'
import { useCadastraEstabelecimento } from '../../modules/associado/hooks/useCadastraEstabelecimento'

export default function CadastrarEstabelecimento() {
    
    const [resposta, cadastrarEstabelecimento] = useCadastraEstabelecimento()
    useDisplayAlertAfterServerResponse(resposta)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(resetAssociado())
        dispatch(resetAssociadoSelecionado())
        dispatch(resetLocalizacao())
        dispatch(resetOcorrencia())
        dispatch(resetOcorrenciaSelecionada())
        dispatch(resetUsuario())
    }, [dispatch])

    return (
        <Paper>
            <MainTitle titulo={"Cadastrar estabelecimento"} tituloPrincipal={true} />
            <FormCadastraEstabelecimento aoSubmeterForm={cadastrarEstabelecimento} editButton={true} />
        </Paper>
    )
}