import React, { useState, useEffect } from 'react'

import DadosAssociado from '../../components/TabelaSimples/DadosAssociado'
import { useBuscaCadastros } from '../../modules/associado/hooks/useBuscaCadastros'
import MatchFilter from '../../modules/shared/components/CampoMultiplosInputs/MatchFilter/MatchFilter'
import MainTitle from '../../modules/shared/components/MainTitle'
import { retornaListaMatchPorListaCampo } from '../../Utilitarios'
import { useSelector } from 'react-redux'

export default function DadosDeAssociados() {

  const [filtrado, setFiltrado] = useState([])
  const [listaCadastrosCompleto, setListaCadastrosCompleto] = useState()

  const [resposta, buscaCadastros] = useBuscaCadastros()

  const { campo, valor } = useSelector(state => state.campoValorState)

  useEffect(() => {
    buscaCadastros()
  }, [buscaCadastros])

  useEffect(() => {
    if(Object.entries(resposta).length > 0) {
        const listaTabela = resposta.data.map(value => {
            const { cadastro, usuario } = value

            return {
              social_name: cadastro.getRazaoSocial(),
              cnpj: cadastro.getCnpj(),
              phone: cadastro.getTelefone(),
              phone2: cadastro.getTelefone2(),
              email: usuario.getEmail(),
              id: cadastro.getCadastroId()
            }
        })
        setFiltrado(listaTabela)
        setListaCadastrosCompleto(listaTabela)
    }
}, [resposta])

  useEffect(() => {

    if (campo === "E-mail" && valor !== "") {
      const novaLista = retornaListaMatchPorListaCampo(listaCadastrosCompleto, 'email', valor)
      setFiltrado(novaLista)
    }
    if (campo === "CNPJ" && valor !== "") {
      const novaLista = retornaListaMatchPorListaCampo(listaCadastrosCompleto, 'cnpj', valor)
      setFiltrado(novaLista)
    }
    if (campo === "Razão Social" && valor !== "") {
      const novaLista = retornaListaMatchPorListaCampo(listaCadastrosCompleto, 'social_name', valor)
      setFiltrado(novaLista)
    }
    if (campo === "Telefone" && valor !== "") {
      const novaLista = retornaListaMatchPorListaCampo(listaCadastrosCompleto, 'phone', valor)
      setFiltrado(novaLista)
    }
    if (campo === "Celular" && valor !== "") {
      const novaLista = retornaListaMatchPorListaCampo(listaCadastrosCompleto, 'phone2', valor)
      setFiltrado(novaLista)
    }
    
    if (campo === "" || valor === "") {
      setFiltrado(listaCadastrosCompleto)
    }
  }, [campo, valor, listaCadastrosCompleto]) 

  return (
    <>
      <MainTitle tituloPrincipal={true} titulo="Buscar cadastro por campo" />
      <MatchFilter
        itensDoSelect={['E-mail', 'CNPJ', 'Razão Social', 'Telefone', 'Celular', ]}>
      </MatchFilter>
      <DadosAssociado
        cadastros={filtrado}
        onClick={() => {}} />
    </>
  )
}