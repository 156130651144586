import React from 'react'
import { useBuscaAdministradoresDesativados } from '../../modules/usuario/hooks/useBuscaAdministradoresDesativados'
import { useAtivaAdministradores } from '../../modules/usuario/hooks/useAtivaAdministradores'
import AllowItem from '../../modules/shared/components/IconButtons/AllowItem'
import TabelaGerenciarAdministradores from '../../modules/usuario/components/TabelaGerenciarAdministradores/TabelaGerenciarAdministradores'
import MainTitle from '../../modules/shared/components/MainTitle'
import { useDisplayAlertAfterServerResponse } from '../../modules/shared/hooks/useDisplayAlertAfterServerResponse/useDisplayAlertAfterServerResponse'
import { Paper } from '@mui/material'

export default function AtivarAdministradores() {

    const { listaAdministradoresDesativados } = useBuscaAdministradoresDesativados()
    
    const [resposta, ativaUsuarios] = useAtivaAdministradores()
    useDisplayAlertAfterServerResponse(resposta)
    
    const handleClick = (selecionados) => (e) => {
        e.preventDefault()
        ativaUsuarios(selecionados)
    }

    return (
        <Paper>
            <MainTitle titulo="Ativar administradores" tituloPrincipal={true} />
            <TabelaGerenciarAdministradores
                usuarios={listaAdministradoresDesativados}
                onClick={handleClick}
                tituloToolbar={"Administradores desativados"}>
                <AllowItem />
            </TabelaGerenciarAdministradores>
        </Paper>
    )
}