import styled from 'styled-components'

export const ContainerActions = styled.div`
    display: flex;
    flex-direction: row;
    
`  

export const Formulario = styled.form`
    display: flex;
    flex-direction: column;
`

