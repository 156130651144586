import { useEffect, useState } from "react";
import UsuarioOutput from "../../models/UsuarioOutput";
import http from "../../../../http";

export const useBuscaMeuUsuario = () => {
    const [dadosMeuUsuario, setDadosMeuUsuario] = useState()

    useEffect(() => {
        http.get(`/meus-dados`)
        .then(res => {
            const meusDados = res.data
            const dadosMeuUsuario = UsuarioOutput.create(meusDados)
            setDadosMeuUsuario(dadosMeuUsuario)
        })
    }, [])

    return {
        dadosMeuUsuario
    }
}