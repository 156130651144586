
import React from "react"
import ModalEditItem from "../../../components/ModalEditItem"
import CampoInputEmail from "../components/Inputs/CampoInputEmail/CampoInputEmail"


const ModalRecuperaSenha = ({ open, handleClose, handleConfirma, onKeyPress }) => {
        
    return (
        <ModalEditItem open={open} onClose={handleClose} confirma={handleConfirma} titulo="Inserir e-mail cadastrado" onKeyPress={onKeyPress} >
            <CampoInputEmail />
        </ModalEditItem>
    )
}

export default ModalRecuperaSenha