import React, { useEffect, useState } from 'react'
import { Outlet } from "react-router-dom";

import SideDrawer from "../../../modules/usuario/components/SideDrawer/SideDrawer";
import NavBar from "../../../modules/usuario/components/NavBar/NavBar";
import AccountMenu from "../../../modules/usuario/components/AccountMenu/AccountMenu";
import { useBuscaMeuUsuario } from '../../../modules/usuario/hooks/useBuscaMeuUsuario';

export default function ContainerPaginaComSideBar() {

    const [displayComponents, setDisplayComponents] = useState(false)
    const { dadosMeuUsuario } = useBuscaMeuUsuario()
    useEffect(() => {
        if(!dadosMeuUsuario) setDisplayComponents(false)
        else setDisplayComponents(true)
    }, [dadosMeuUsuario])

    return (
        <>
            <NavBar>
                {
                    displayComponents && (<AccountMenu email={dadosMeuUsuario ? dadosMeuUsuario.getEmail() : " "} />)
                }
            </NavBar>
            <SideDrawer permissao={dadosMeuUsuario ? dadosMeuUsuario.getPermissao() : 1} >
                <Outlet />
            </SideDrawer>
        </>
    )
}
