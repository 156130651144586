import React from 'react'
import { ThemeProvider } from 'styled-components'

import Paper from '@mui/material/Paper';

import DataCard from '../../modules/shared/components/DataCard'
import MainTitle from "../../modules/shared/components/MainTitle"

import * as S from './style'
import { useBuscaNumeroCadastros } from './apiHooks/useBuscaNumeroCadastros';
import { useBuscaNumeroCadastrosDoDia } from './apiHooks/useBuscaNumeroCadastrosDoDia';
import { useBuscaNumeroOcorrencias } from './apiHooks/useBuscaNumeroOcorrencias';
import { useBuscaNumeroOcorrenciasDoDia } from './apiHooks/useBuscaNumeroOcorrenciasDoDia';
import { useBuscaMeuCadastro } from '../../modules/associado/hooks/useBuscaMeuCadastro';

export default function Inicial() {

    const { totalUsuarios } = useBuscaNumeroCadastros()
    const { totalUsuariosDoDia } = useBuscaNumeroCadastrosDoDia()
    const { totalOcorrencias } = useBuscaNumeroOcorrencias()
    const { totalOcorrenciasDoDia } = useBuscaNumeroOcorrenciasDoDia()
    const { nomeProprietario } = useBuscaMeuCadastro()

    const descricaoCard = (totalOcorrenciasDoDia, campo) => {
        if(!totalOcorrenciasDoDia) {
            return `Nenhuma ${campo}`
        }
        if(totalOcorrenciasDoDia === 1) {
            return `${totalOcorrenciasDoDia} ${campo}`
        }
        if(totalOcorrenciasDoDia > 1) {
            return `${totalOcorrenciasDoDia} ${campo}s`
        }        
    }

    return (
        <Paper elevation={1} sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            <MainTitle titulo={nomeProprietario ? `Bem-vindo, ${nomeProprietario}` : "Bem-vindo"} tituloPrincipal={true} />
            <S.ContainerCards>
                <ThemeProvider theme={{ main: "#0088CC" }}>
                    <DataCard titulo={"Ocorrências hoje"} dado={descricaoCard(totalOcorrenciasDoDia, 'ocorrência')} cor={"#0088CC"} tipo={1} />
                </ThemeProvider>
                <ThemeProvider theme={{ main: "#E36159" }}>
                    <DataCard titulo={"Total de ocorrências"} dado={descricaoCard(totalOcorrencias, 'ocorrência')} cor={"#E36159"} tipo={2} />
                </ThemeProvider>
                <ThemeProvider theme={{ main: "#2baab1" }}>
                    <DataCard titulo={"Novos associados hoje"} dado={descricaoCard(totalUsuariosDoDia, 'associado')} cor={"#2baab1"} tipo={3} />
                </ThemeProvider>
                <ThemeProvider theme={{ main: "#734ba9" }}>
                    <DataCard titulo={"Total de associados"} dado={descricaoCard(totalUsuarios, 'associado')} cor={"#734ba9"} tipo={4} />
                </ThemeProvider>
            </S.ContainerCards>
        </Paper>
    )
}