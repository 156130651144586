import React, { useEffect, useState } from 'react'
import MainTitle from "../../modules/shared/components/MainTitle"
import { useBuscaListaDeIdsDeOcorrencias } from '../../modules/ocorrencia/hooks/useBuscaListaDeIdsDeOcorrencias';
import { useBuscaListaCadastros } from '../../modules/associado/hooks/useBuscaListaCadastros';
import { useBuscaOcorrenciaPeloId } from '../../modules/ocorrencia/hooks/useBuscaOcorrenciaPeloId';
import { useAtualizaOcorrencia } from '../../modules/ocorrencia/hooks/useAtualizaOcorrencia';
import { useDispatch, useSelector } from 'react-redux';
import { handleAssociadoSelecionadoChange, resetAssociadoSelecionado } from '../../store/reducers/associadoSelecionadoSlice';
import { resetOcorrenciaSelecionada } from '../../store/reducers/ocorrenciaSelecionadaSlice';
import { handleOcorrenciaChange, resetOcorrencia } from '../../store/reducers/ocorrenciaSlice';
import { resetLocalizacao } from '../../store/reducers/localizacaoSlice';
import FormEditaOcorrencia from '../../modules/ocorrencia/components/FormEditaOcorrencia/FormEditaOcorrencia';
import ModalOcorrencia from '../../modules/ocorrencia/components/ModalOcorrencia/ModalOcorrencia';
import { useDisplayAlertAfterServerResponse } from '../../modules/shared/hooks/useDisplayAlertAfterServerResponse/useDisplayAlertAfterServerResponse';
export default function EditarOcorrencia() {
    
    const [open, setOpen] = useState(false)
    const [tempValues, setTempValues] = useState()
    const [selected, setSelected] = useState()

    const associadoSelecionado = useSelector(state => state.associadoSelecionadoState)
    const dadosOcorrencia = useSelector(state => state.ocorrenciaState)
    const dadosLocalizacao = useSelector(state => state.localizacaoState)
    const { codigoAT } = useSelector(state => state.ocorrenciaSelecionadaState)

    const { listaIdsDeOcorrencia } = useBuscaListaDeIdsDeOcorrencias()
    const { listaAssociados } = useBuscaListaCadastros()
    const [resposta, atualizarOcorrencia] = useAtualizaOcorrencia()

    useDisplayAlertAfterServerResponse(resposta)
    
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(resetAssociadoSelecionado())
        dispatch(resetOcorrenciaSelecionada())
        dispatch(resetOcorrencia())
        dispatch(resetLocalizacao())
    }, [dispatch])


    const handleClickEditaOcorrencia = (name) => {
        setSelected(name)
        selectedTempValue(name)
        setOpen(true) 
    }

    const selectedTempValue = (name) => {
        if(name === 'associado') {
            setTempValues(associadoSelecionado)
        } else {
            setTempValues({[name]: dadosOcorrencia[name]})
        }
    }

    const handleSubmitForm = (cadastroId, inputForms) => {
        const { id, usuario_id } = associadoSelecionado
        const { out_date, ...dadosOcorrenciaSemDataSaida} = dadosOcorrencia
        const dadosAtualizarOcorrencia = {
            ...dadosOcorrenciaSemDataSaida,
            cep: dadosLocalizacao.cep,
            usuario_id,
            cadastro_id: id,
            codigoAT,
            brands: dadosOcorrencia.brands.map(brand => brand.id),
            items: dadosOcorrencia.items.map(item => item.id)
        }
        atualizarOcorrencia(dadosAtualizarOcorrencia)
    }

    const handleCloseModal = () => {
        if(selected === 'associado') {
            dispatch(handleAssociadoSelecionadoChange(tempValues))
        } else if(selected) {
            dispatch(handleOcorrenciaChange(tempValues))
        }
        setOpen(false)
        setSelected(false)
    }

    const handleConfirma = () => {
        setOpen(false)
        setSelected(false)
    }
    
    return (
        <>
            <MainTitle titulo="Editar ocorrência" tituloPrincipal={true} />
            <FormEditaOcorrencia
                disabled
                editButton
                onSubmitForm={handleSubmitForm}
                useBuscaOcorrenciaPeloId={useBuscaOcorrenciaPeloId}
                listaIdsDeOcorrencia={listaIdsDeOcorrencia} 
                listaAssociados={listaAssociados}
                onClick={handleClickEditaOcorrencia}
                />
            <ModalOcorrencia open={open} listaAssociados={listaAssociados} handleClose={handleCloseModal} handleConfirma={handleConfirma} selected={selected} />
        </>
    )
}