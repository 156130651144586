import { createSlice } from "@reduxjs/toolkit"

const estadoInicial = {
    estado: false
}

const estadoMobileSlice = createSlice({
    name: 'dadosUsuario',
    initialState: estadoInicial,
    reducers: {
        toggleSideBar: (state, params) => {
            state.estado = !state.estado
        },
        fecharSideBar: (state, params) => {
            if(state.estado === true) state.estado = false
        },

    }
})

export const { toggleSideBar, fecharSideBar } = estadoMobileSlice.actions

export default estadoMobileSlice.reducer