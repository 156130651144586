import React, { useEffect, useState } from 'react'
import FormSelectInput from '../../../../../components/FormSelectInput'
import { useDispatch } from 'react-redux'
import { handleCampoValorChange, resetCampoValor } from '../../../../../store/reducers/campoValorSlice'

const Campo = ({itensDoSelect}) => {

    const [campo, setCampo] = useState("")
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(resetCampoValor())
    }, [dispatch])

    const handleChange = (e) => {
        e.preventDefault()
        const input = e.target.value
        setCampo(input)
        dispatch(handleCampoValorChange({ campo: input, valor: "" }))
    }

    return (
        <>
            <h5>Campo</h5>
            <FormSelectInput
                value={campo}
                onChange={handleChange}
                itensDoSelect={itensDoSelect} />
        </>       
    )
}

export default Campo