import { configureStore } from '@reduxjs/toolkit'
import associadoSlice from './reducers/associadoSlice'
import usuarioSlice from './reducers/usuarioSlice'
import estadoMobileSlice from './reducers/estadoMobileSlice'
import associadoSelecionadoSlice from './reducers/associadoSelecionadoSlice'
import localizacaoSlice from './reducers/localizacaoSlice'
import ocorrenciaSlice from './reducers/ocorrenciaSlice'
import ocorrenciaSelecionadaSlice from './reducers/ocorrenciaSelecionadaSlice'
import campoValorSlice from './reducers/campoValorSlice'
import emailAssociadoSelecionadoSlice from './reducers/emailAssociadoSelecionadoSlice'

const store = configureStore({
    reducer: {
        mobileState: estadoMobileSlice,
        associadoState: associadoSlice,
        usuarioState: usuarioSlice,
        associadoSelecionadoState: associadoSelecionadoSlice,
        localizacaoState: localizacaoSlice,
        ocorrenciaSelecionadaState: ocorrenciaSelecionadaSlice,
        ocorrenciaState: ocorrenciaSlice,
        campoValorState: campoValorSlice,
        emailAssociadoSelecionadoState: emailAssociadoSelecionadoSlice,
    }
})

export default store