import styled from 'styled-components'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { AppBar } from '@mui/material';

export const ContainerPage = styled.div`
    display: flex;
    flex-direction: column;
`

export const ContainerLogin = styled.div`
    width: 100%;
    background-color: #fbfbfb;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 164px;
`

export const NavBarLogin = styled(AppBar)`
    height: 64px;
    justify-content: center;
    padding: 20px;
`

export const ContainerLogoTitle = styled.div`
    display: flex;
    align-items: center;
    @media (max-width: 580px) {
        justify-content: center;
    }
`

export const NavBarTitle = styled.h2`
    margin-left: 20px;
    @media (max-width: 580px) {
        display: none;
    }
`

export const ContainerButtons = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
`

export const ContainerTextInputs = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70%;
`

export const FormTitle = styled.h3`
    align-self: start;
    margin-top: 20px;
`

export const formInput = styled(TextField)`
    align-self: start;
    width: 100%;

`

export const LoginButton = styled(Button)`
    width: 300px;
    justify-self: center;
    
`

export const RecuperarSenhaButton = styled(Button)`
    width: 300px;
    justify-self: center;
`

export const Formulario = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;

`

export const ContainerNaoPossuiConta = styled.div`
    width: 70%;
    padding: 10px;
`
