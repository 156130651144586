import { useEffect, useState } from "react";
import http from "../../../../http";

export const useBuscaNumeroCadastrosDoDia = () => {

    const [totalUsuariosDoDia, setTotalUsuariosDoDia] = useState('')

    useEffect(() => {
        http.get('/cadastros-hoje', )
        .then(res => {
            const nrCadastrosHoje = res.data
            setTotalUsuariosDoDia(nrCadastrosHoje)
        })
    }, [])
  
    return {
        totalUsuariosDoDia
    };
  };