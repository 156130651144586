import { createSlice } from "@reduxjs/toolkit"

const estadoInicial = {
    email: "",
    senha: "",
    confirmaSenha: "",
}

const usuarioSlice = createSlice({
    name: 'dadosUsuario',
    initialState: estadoInicial,
    reducers: {
        handleUsuarioChange: (state, action) => {
            return {...state, ...action.payload }
        },
        resetUsuario: (state) => {
            return estadoInicial;
        }
        
    }
})

export const { handleUsuarioChange, resetUsuario } = usuarioSlice.actions

export default usuarioSlice.reducer