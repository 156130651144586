import { useEffect, useState } from "react";
import http from "../../../../http";

import OcorrenciaOutput from "../../models/ocorrencia/OcorrenciaOutput";
import UsuarioOutput from "../../../usuario/models/UsuarioOutput";
import CadastroOutput from "../../../associado/models/CadastroOutput";
import MarcasOutput from "../../models/marcas/MarcasOutput";
import PecasOutput from "../../models/pecas/PecasOutput";
import Cep from "../../../associado/models/cep/cep";

export const useBuscaOcorrenciaPeloId = (id) => {
    const [dadosOcorrencia, setDadosOcorrencia] = useState()
    const [dadosCadastro, setDadosCadastro] = useState()
    const [dadosCep, setDadosCep] = useState()
    const [dadosUsuario, setDadosUsuario] = useState()
    const [pecas, setPecas] = useState([])
    const [marcas, setMarcas] = useState([])

    useEffect(() => {
        if(id) {
            http.get(`/ocorrencias/${id}`)
                .then(res => {
                    const ocorrenciaRaw = res.data
                    
                    const ocorrencia = OcorrenciaOutput.create(ocorrenciaRaw.ocorrencia)
                    setDadosOcorrencia(ocorrencia)

                    const cadastro = CadastroOutput.create(ocorrenciaRaw.ocorrencia.Cadastro)
                    setDadosCadastro(cadastro)
                    
                    const usuario = UsuarioOutput.create(ocorrenciaRaw.ocorrencia.Cadastro.Usuario)
                    setDadosUsuario(usuario)

                    const cep = Cep.create(ocorrenciaRaw.ocorrencia.Cadastro.Cep)
                    setDadosCep(cep)

                    const adaptaListaPecas = ocorrenciaRaw.Items.map(peca => {
                        return {
                            id: peca.Item.id,
                            name: peca.Item.name
                        }
                    })
                    const listaPecas = PecasOutput.create(adaptaListaPecas)
                    setPecas(listaPecas)

                    const adaptaListaMarcas = ocorrenciaRaw.Brands.map(marca => {
                        return {
                            id: marca.Brand.id,
                            name: marca.Brand.name
                        }
                    })
                    const listaMarcas = MarcasOutput.create(adaptaListaMarcas)
                    setMarcas(listaMarcas)

                })
        }
    }, [id])
    
    return {
        dadosOcorrencia,
        dadosCadastro,
        dadosUsuario,
        dadosCep,
        pecas,
        marcas
    }
}