import React from 'react';
import { useSelector } from 'react-redux';
import CampoInputTexto from '../../../../shared/components/CampoInputComButton/CampoInputTexto/CampoInputTexto';
import VoidIcon from '../../../../shared/components/IconButtons/VoidIcon/VoidIcon';

const CampoInputCidade = ({ editButton }) => {

    const { cidade } = useSelector(state => state.localizacaoState)
      
    return (
        <CampoInputTexto
            id="inputCidade"
            titulo="Cidade*"
            name="cidade"
            value={cidade}
            required={true}
            disabled={true}
        >
            {
                editButton ? <VoidIcon /> : <></>
            }
        </CampoInputTexto>
    )
}

export default CampoInputCidade
