import { useCallback, useState } from "react"
import AssociadoInput from "../../models/AssociadoInput" 
import http from "../../../../http"

export const useCadastraAssociadoADM = () => {
    const [resposta, setResposta] = useState({})

    const cadastrarAssociadoADM = useCallback((inputsAssociado) => {
        const dadosUsusarioECadastro = AssociadoInput.create(inputsAssociado)
        http.post(`/cadastros-adm`, dadosUsusarioECadastro)
            .then(res => {
                setResposta({ data: res.data, error: null })
            })
            .catch(error => {
                const { response } = error
                setResposta({ data: null, error: response })
            })
    }, [])
    return [resposta, cadastrarAssociadoADM]
}