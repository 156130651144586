import { useDispatch, useSelector } from 'react-redux'
import CampoInputTexto from '../../../../shared/components/CampoInputComButton/CampoInputTexto/CampoInputTexto'
import { handleOcorrenciaChange } from '../../../../../store/reducers/ocorrenciaSlice'
import { VoidIcon } from '../../../../../paginas/EditarOcorrencia/style'

const CampoInputDataEmissaoLaudo = ({ onClick, editButton, disabled }) => {

    const dispatch = useDispatch()
    const { out_date } = useSelector(state => state.ocorrenciaState)
    
    const handleChange = (name, value) => {
        dispatch(handleOcorrenciaChange({[name]: value}))
    }
    const handleClick = (e) => {
        e.preventDefault()
        onClick("out_date")
    }

    return (
        <CampoInputTexto
            id="inputDataEmissaoLaudo"
            titulo="Data emissão laudo"
            onChange={handleChange}
            name="out_date"
            value={out_date ? out_date : ""}
            type="date"
            disabled={disabled}
        >
            {
                editButton ? <VoidIcon onClick={handleClick} /> : <></>
            }
        </CampoInputTexto>
    )
}

export default CampoInputDataEmissaoLaudo
