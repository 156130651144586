import { useDispatch, useSelector } from 'react-redux';

import EditItem from '../../../../shared/components/IconButtons/EditItem';
import CampoInputTexto from '../../../../shared/components/CampoInputComButton/CampoInputTexto/CampoInputTexto';
import { handleOcorrenciaChange } from '../../../../../store/reducers/ocorrenciaSlice';

const CampoInputTecnico = ({ onClick, editButton, disabled }) => {

    const dispatch = useDispatch()
    const { technician } = useSelector(state => state.ocorrenciaState)
    
    const handleChange = (name, value) => {
        dispatch(handleOcorrenciaChange({[name]: value}))
    }
    const handleClick = (e) => {
        e.preventDefault()
        onClick("technician")
    }

    return (
        <CampoInputTexto
            id="inputTecnico"
            titulo="Técnico*"
            onChange={handleChange}
            name="technician"
            required
            value={technician}
            disabled={disabled}
        >
            {
                editButton ? <EditItem onClick={handleClick} /> : <></>
            }
        </CampoInputTexto>
    )
}

export default CampoInputTecnico
