import { useEffect, useState } from "react";

export const useStatusFetch = (estadoDados) => {
  const [needFetching, setNeedFetching] = useState(false)

  useEffect(() => {
    if(estadoDados.length || needFetching) return
    setNeedFetching(true)
  }, [estadoDados, needFetching])
    
    return {
        needFetching
    };
  };