import { useCallback, useState } from "react"
import http from "../../../../http"

export const useAlteraSenha = () => {
    const [resposta, setResposta] = useState({})

    const alteraSenha = useCallback((camposSenha) => {
        http.put('/usuarios/altera-senha', camposSenha)
            .then(res => {
                setResposta({ data: res.data, error: null })
            })
            .catch(error => {
                const { response } = error
                setResposta({data: null, error: response})
            })
    }, [])
    return [resposta, alteraSenha]
}