import React, { memo } from 'react'
import { TextField } from "@mui/material"
import ContainerTitleInputField from "../../../../../components/Containers/ContainerTitleInputField"
import FormAutocompletar from "../../../../../components/FormAutocompletar"
const CampoInputAutocompleteMultipleOptions = ({ titulo, id, name, onChange, listaAutocomplete, children, ...props }) => {
    
    const handleRender = (params) => {
        return <TextField { ...params } />
    }

    const handleOnChange = (e, newValues) => {
        onChange(name, newValues);
    }

    return (
        <ContainerTitleInputField titulo={titulo}>
            <FormAutocompletar
                id={id}
                required={true}
                onChange={handleOnChange}
                options={listaAutocomplete}
                renderInput={handleRender}
                isOptionEqualToValue={(option, value) => false}
                freeSolo={true}
                {...props}
            >
                {children ? children : <></>}
            </FormAutocompletar>
        </ContainerTitleInputField>
    )
}

export default memo(CampoInputAutocompleteMultipleOptions)