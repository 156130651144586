import { useCallback, useState } from "react"
import Laudo from "../../models/laudo/Laudo"
import http from "../../../../http"

export const useCadastraLaudo = () => {
    const [resposta, setResposta] = useState({})
    const cadastrarLaudo = useCallback((inputCadastro) => {
        const cadastroLaudo = Laudo.create(inputCadastro) 
        http.put('/cadastrar-laudo', cadastroLaudo, {
                headers: {
                    "Content-Type": "multipart/form-data"
                },
            })
            .then(res => {
                if (res.status === 200) {
                    setResposta({ data: res.data, error: null })
                }
            })
            .catch(error => {
                const { response } = error
                setResposta({data: null, error: response})
            })
    }, [])
    return [resposta, cadastrarLaudo]
}