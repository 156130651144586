import React, { useEffect, useState } from 'react'
import Botao from '../../../../components/Botao'
import MatchFilter from '../../../shared/components/CampoMultiplosInputs/MatchFilter/MatchFilter'
import * as S from './style'
import { useCadastraPeca } from '../../hooks/useCadastraPeca'
import { useCadastraMarca } from '../../hooks/useCadastraMarca'
import { useDisplayAlertAfterServerResponse } from '../../../shared/hooks/useDisplayAlertAfterServerResponse/useDisplayAlertAfterServerResponse'
import { useSelector } from 'react-redux'

const FormCadastraCampo = () => {

    const [resposta, setResposta] = useState({})
    const { campo, valor } = useSelector(state => state.campoValorState)

    const [respostaPeca, cadastrarPeca] = useCadastraPeca()
    const [respostaMarca, cadastrarMarca] = useCadastraMarca()
    useDisplayAlertAfterServerResponse(resposta)

    useEffect(() => {
        if(Object.entries(respostaPeca).length > 0) setResposta(respostaPeca)
        if(Object.entries(respostaMarca).length > 0) setResposta(respostaMarca)
    }, [respostaPeca, respostaMarca])


    const handleSubmit = (e) => {
        e.preventDefault()
        if(campo === "Peça") {
            cadastrarPeca(valor)
        }
        if(campo === "Marca") {
            cadastrarMarca(valor)
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <MatchFilter
                maiusculo
                itensDoSelect={['Peça', 'Marca']}>
                <S.ContainerBotao>
                    <Botao
                        titulo={`cadastrar ${campo}`} type="submit" />
                </S.ContainerBotao>
            </MatchFilter>
        </form>
    )
}

export default FormCadastraCampo