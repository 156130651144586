import { useCallback, useState } from "react"
import MarcaInput from "../../models/marcas/MarcaInput"
import http from "../../../../http"

export const useCadastraMarca = () => {
    const [resposta, setResposta] = useState({})

    const cadastrarMarca = useCallback((marca) => {
        const cadastroMarca = MarcaInput.create(marca) 
        http.post('/brands', cadastroMarca)
            .then(res => {
                setResposta({ data: res.data, error: null })
            })
            .catch(error => {
                const { response } = error
                setResposta({ data: null, error: response })
            })
    }, [])
    return [resposta, cadastrarMarca]
}