import { useEffect, useState } from "react";
import http from "../../../../http";
import CadastroOutput from "../../models/CadastroOutput";
import UsuarioOutput from "../../../usuario/models/UsuarioOutput";

export const useBuscaCadastroPeloId = (id) => {
    const [cadastro, setCadastro] = useState({})
    const [usuario, setUsuario] = useState({})
    useEffect(() => {
        if(id) {
            http.get(`/cadastros/${id}`)
            .then(res => {
                const { Usuario, Cep, ...cadastro } = res.data
                const cadastroRetorno = CadastroOutput.create(cadastro)
                setCadastro(cadastroRetorno)
                const usuarioRetorno = UsuarioOutput.create(Usuario)
                setUsuario(usuarioRetorno)
            })
            .catch(error => {
                setCadastro({})
            })
        } else {
            setCadastro({})
        }
    }, [id])
 
    return { cadastro, usuario }
}