import { createSlice } from "@reduxjs/toolkit"

const estadoInicial = {
    associado: "",
    id: "",
    usuario_id: ""
}

const associadoSelecionadoSlice = createSlice({
    name: 'dadosAssociadoSelecionado',
    initialState: estadoInicial,
    reducers: {
        handleAssociadoSelecionadoChange: (state, action) => {
            return {...state, ...action.payload }
        },
        resetAssociadoSelecionado: (state) => {
            return estadoInicial;
        }
    }
})

export const { handleAssociadoSelecionadoChange, resetAssociadoSelecionado } = associadoSelecionadoSlice.actions

export default associadoSelecionadoSlice.reducer