import { useCallback, useState } from "react"
import ListaDeactivateIds from "../../../shared/models/ListaDeactivateIds/ListaDeactivateIds"
import http from "../../../../http"

export const useDesativaAdministradores = () => {
    const [resposta, setResposta] = useState({})

    const desativaUsuarios = useCallback((listaIdsAdms) => {
        const listaIdsUsuariosASeremDesativados = ListaDeactivateIds.create(listaIdsAdms)
        http.put(`/desativa-multiplos-administradores`, listaIdsUsuariosASeremDesativados)
            .then(res => {
                setResposta({ data: res.data, error: null })
            })
            .catch(error => {
                const { response } = error
                setResposta({ data: null, error: response })
            })
    }, [])
    return [resposta, desativaUsuarios]
}