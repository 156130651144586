import React from 'react'
import * as S from './style'

import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function FormRadioInput({disabled, value, onChange, radioValores, children}) {
    return (
        <S.ContainerComButton>
            <S.ContainerRadio>
                <FormControl disabled={disabled}>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={`${value}`}
                        onChange={onChange}
                        row
                    >
                        {
                            radioValores.map((radioValor, index) => {
                                return (<FormControlLabel key={index} value={radioValor} control={<Radio />} label={radioValor} />)
                            })
                        }
                    </RadioGroup>     
                </FormControl>
            </S.ContainerRadio>
            <S.ContainerIcon>
                {children && (<>{children}</>)}
            </S.ContainerIcon>
        </S.ContainerComButton>
    )
}