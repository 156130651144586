import React, { useEffect, useState } from 'react'
import MainTitle from "../../modules/shared/components/MainTitle"
import { useBuscaOcorrenciasParaAssociados } from '../../modules/ocorrencia/hooks/useBuscaOcorrenciasParaAssociados'
import MatchFilter from '../../modules/shared/components/CampoMultiplosInputs/MatchFilter/MatchFilter'
import TabelaOcorrenciasAnonimas from '../../modules/ocorrencia/components/TabelaOcorrenciasAnonimas/TabelaOcorrenciasAnonimas'
import { retornaListaMatchPorListaCampo } from '../../Utilitarios'
import { useSelector } from 'react-redux'

export default function Relatorios() {
    
    const {campo, valor} = useSelector(state => state.campoValorState)

    const [filtrado, setFiltrado] = useState([])
    const [listaOcorrenciasCompleta, setListaOcorrenciasCompleta] = useState([])

    const [resposta, buscaOcorrencias] = useBuscaOcorrenciasParaAssociados()

    useEffect(() => {
        buscaOcorrencias()
    }, [buscaOcorrencias])

    useEffect(() => {
        if(Object.entries(resposta).length > 0) {
            const listaTabela = resposta.data.map(value => {
                const { listaPecas, listaMarcas, ocorrencia } = value
                return {
                    id: ocorrencia.getId(),
                    damage: ocorrencia.getDanoReclamado(),
                    ocurrence_date: ocorrencia.getDataOcorrencia(),
                    wash_used: ocorrencia.getInstrucaoLimpeza(),
                    wash_instruct: ocorrencia.getLimpezaUtilizada(),
                    // dry_dryer: ocorrencia.getFoiSecado() === "0" ? "Não" : "Sim",
                    // iron_clothes: ocorrencia.getFoiPassado() === "0" ? "Não" : "Sim",
                    responsible: ocorrencia.getResponsavel(),
                    items: listaPecas.listaNomePecas,
                    brands: listaMarcas.listaNomeMarcas
                }
            })
            console.log('resposta', resposta)
            setFiltrado(listaTabela)
            setListaOcorrenciasCompleta(listaTabela)
        }
    }, [resposta])

    useEffect(() => {
        if(campo === "Dano Reclamado" && valor !== "") {
            const novaLista = retornaListaMatchPorListaCampo(listaOcorrenciasCompleta, 'damage', valor)
            setFiltrado(novaLista)
        }
        if(campo === "Peças" && valor !== "") {
            const novaLista = retornaListaMatchPorListaCampo(listaOcorrenciasCompleta, 'items', valor)
            setFiltrado(novaLista)
        }
        if(campo === "Marcas" && valor !== "") {
            const novaLista = retornaListaMatchPorListaCampo(listaOcorrenciasCompleta, 'brands', valor)
            setFiltrado(novaLista)
        }
        if(campo === "Data Ocorrência" && valor !== "") {
            const novaLista = retornaListaMatchPorListaCampo(listaOcorrenciasCompleta, 'ocurrence_date', valor)
            setFiltrado(novaLista)
        }
        if(campo === "Instrução Limpeza" && valor !== "") {
            const novaLista = retornaListaMatchPorListaCampo(listaOcorrenciasCompleta, 'wash_instruct', valor)
            setFiltrado(novaLista)
        }
        if(campo === "Limpeza Utilizada" && valor !== "") {
            const novaLista = retornaListaMatchPorListaCampo(listaOcorrenciasCompleta, 'wash_used', valor)
            setFiltrado(novaLista)
        }
        if(campo === "Responsável" && valor !== "") {
            const novaLista = retornaListaMatchPorListaCampo(listaOcorrenciasCompleta, 'responsible', valor)
            setFiltrado(novaLista)
        }

        // if(campo === "Foi Passado" && valor !== "") {
        //     const novaLista = retornaListaMatchPorListaCampo(listaOcorrenciasCompleta, 'iron_clothes', valor)
        //     setFiltrado(novaLista)
        // }
        // if(campo === "Foi Secado" && valor !== "") {
        //     const novaLista = retornaListaMatchPorListaCampo(listaOcorrenciasCompleta, 'dry_dryer', valor)
        //     setFiltrado(novaLista)
        // }
        
        if(campo === "" || valor === "") {
            setFiltrado(listaOcorrenciasCompleta)
        }
           
    }, [campo, valor, listaOcorrenciasCompleta]) 

    return (
        <>
            <MainTitle titulo="Relatórios de ocorrência" tituloPrincipal />
            <MatchFilter
                itensDoSelect={['Dano Reclamado', 'Peças', 'Marcas', 'Data Ocorrência', 'Responsável']}>
            </MatchFilter>
            <TabelaOcorrenciasAnonimas
                ocorrencias={filtrado}
                onClick={() => {}} />
        </>
    )
}