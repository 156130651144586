import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Botao from '../../../../components/Botao'
import ContainerTitleInputField from '../../../../components/Containers/ContainerTitleInputField'
import { resetAssociadoSelecionado } from '../../../../store/reducers/associadoSelecionadoSlice'
import { handleLocalizacaoChange, resetLocalizacao } from '../../../../store/reducers/localizacaoSlice'
import { handleOcorrenciaChange, resetOcorrencia } from '../../../../store/reducers/ocorrenciaSlice'
import CampoInputAssociado from '../../../associado/components/Inputs/CampoInputAssociado/CampoInputAssociado'
import { useBuscaCadastroPeloId } from '../../../associado/hooks/useBuscaCadastroPeloId'
import { useBuscaCep } from '../../../associado/hooks/useBuscaCep'
import CamposCadastraOcorrencia from '../CamposCadastraOcorrencia/CamposCadastraOcorrencia'

const FormCadastraOcorrencia = ({ disabled, editButton, listaAssociados, aoSubmeterForm }) => {
    
    const [idCadastro, setIdCadastro] = useState()
    const { usuario_id, id } = useSelector(state => state.associadoSelecionadoState)
    const dadosOcorrencia = useSelector(state => state.ocorrenciaState)
    const { cadastro } = useBuscaCadastroPeloId(idCadastro)
    const [resposta, buscarCep] = useBuscaCep()
    const dispatch = useDispatch()

    useEffect(() => {
        setIdCadastro('')
        dispatch(resetAssociadoSelecionado())
        dispatch(resetOcorrencia())
    }, [dispatch])

    useEffect(() => {
        if(id !== "") {
            setIdCadastro(id)
        }
        if(id === "" || !id) {
            setIdCadastro(false)
        }
    }, [id, dispatch])

    useEffect(() => {
        if(Object.entries(cadastro).length > 0) {
            const { cep } = cadastro
            if(idCadastro) {
                dispatch(handleOcorrenciaChange({cep}))
                buscarCep(cep)
            }
        } else {
            dispatch(handleOcorrenciaChange({cep: ""}))
            dispatch(resetLocalizacao())
        }
    }, [idCadastro, cadastro, dispatch, buscarCep])

    useEffect(() => {
        if(resposta.data) {
            const { cep, ...dadosLocalizacao } = resposta.data 
            dispatch(handleLocalizacaoChange(dadosLocalizacao))
        }
    }, [resposta, dispatch])
    
    
    useEffect(() => {
        dispatch(handleOcorrenciaChange({ usuario_id }))
    }, [usuario_id, dispatch])

    const handleSubmit = (e) => {
        e.preventDefault()
        
        const { brands, items, ...resto } = dadosOcorrencia 
        const envioOcorrencia = {
            ...resto,
            brands: brands.map(brand => brand.id),
            items: items.map(item => item.id),
        }
        aoSubmeterForm(envioOcorrencia)
    }
    

    return (
        <form method="post" onSubmit={handleSubmit}>
            <CampoInputAssociado editButton={editButton} listaAssociados={listaAssociados} />
            <CamposCadastraOcorrencia 
                editButton={editButton}
                disabled={disabled} />
            <ContainerTitleInputField titulo="" botao>
                <Botao titulo="Cadastrar ocorrência" type="submit" />
            </ContainerTitleInputField> 
        </form>
    )
}

export default FormCadastraOcorrencia