import { useDispatch, useSelector } from 'react-redux'
import EditItem from '../../../../shared/components/IconButtons/EditItem'
import CampoInputRadio from '../../../../shared/components/CampoInputComButton/CampoInputRadio/CampoInputRadio'
import { useEffect, useState } from 'react'
import { handleOcorrenciaChange } from '../../../../../store/reducers/ocorrenciaSlice'

const CampoInputAutorizaTestes = ({ onClick, editButton, disabled }) => {
    const [value, setValue] = useState("")
    const dispatch = useDispatch()
    const { tests } = useSelector(state => state.ocorrenciaState)

    const handleChange = (name, value) => {
        if(value === "Sim") dispatch(handleOcorrenciaChange({[name]: "1"}))
        if(value === "Não") dispatch(handleOcorrenciaChange({[name]: "0"}))
        setValue(value)
    }
    
    const handleClick = (e) => {
        e.preventDefault()
        onClick("tests")
    }

    useEffect(() => {
        if(tests === "1") setValue("Sim") 
        if(tests === "0") setValue("Não") 
        if(tests === "") setValue() 
    }, [tests])

    return (
        <CampoInputRadio
            id="inputAutorizaTestes"
            titulo="Autoriza testes no artigo?*"
            onChange={handleChange}
            name="tests"
            value={value}
            radioValores={["Sim", "Não"]}
            required={true}
            disabled={disabled} >
            {
                editButton ? <EditItem onClick={handleClick} /> : <></>
            }
        </CampoInputRadio>
    )
}

export default CampoInputAutorizaTestes
