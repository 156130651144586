import React from 'react'

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: '5px',
    boxShadow: 24,
    p: 4,
};


export default function ModalGeneric({open, onClose, onKeyPress, children}) {
    return(
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            onKeyPress={onKeyPress}
        >
            <Box sx={style}>
                <>
                    {children}
                </>
            </Box>
        </Modal>
    )
}