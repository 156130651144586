import { useDispatch, useSelector } from 'react-redux';

import CampoInputTexto from '../../../../shared/components/CampoInputComButton/CampoInputTexto/CampoInputTexto';
import EditItem from '../../../../shared/components/IconButtons/EditItem';
import { cepMask, retornaSomenteNumerosDeString } from "../../../../../Utilitarios";
import { useBuscaCep } from '../../../hooks/useBuscaCep';
import { memo, useEffect } from 'react';
import { handleAssociadoChange } from '../../../../../store/reducers/associadoSlice';
import { handleLocalizacaoChange } from '../../../../../store/reducers/localizacaoSlice'

const CampoInputCep = ({ onClick, editButton, disabled }) => {

    const dispatch = useDispatch()
    const { cep } = useSelector(state => state.associadoState)
    const [resposta, buscaCep] = useBuscaCep()
    
    const handleChange = (name, value) => {
        dispatch(handleAssociadoChange({[name]: cepMask(value)}))
    }

    useEffect(() => {
        const cepNumber = retornaSomenteNumerosDeString(cep) 
        if (cepNumber.length === 8) buscaCep(cepNumber)
    }, [cep, dispatch, buscaCep])

    useEffect(() => {
        if(resposta.data) {
            const {cep: cepResposta, ...dadosLocalizacao} = resposta.data
            if(cep !== "") dispatch(handleLocalizacaoChange(dadosLocalizacao))
        }
    }, [resposta, dispatch, cep])
    
    const handleClick = (e) => {
        e.preventDefault()
        onClick("cep")
    }

    return (
        <CampoInputTexto
            id="inputCep"
            titulo="CEP*"
            onChange={handleChange}
            name="cep"
            value={cep}
            label="obrigatório"
            required={true}
            disabled={disabled}
        >
            {
                editButton ? <EditItem onClick={handleClick} /> : <></>
            }
        </CampoInputTexto>
    )
}

export default memo(CampoInputCep)
