import { useEffect, useState } from "react";
import http from "../../../../http";
import Associado from "../../models/Associado";

export const useBuscaListaCadastros = () => {

  const [listaAssociados, setListaAssociados] = useState([])

  useEffect(() => {
    http.get(`/lista-associados`)
        .then(res => {
          const resposta = res.data
          const listaAssociadosCompleta = resposta.map(associado => {
            return Associado.create(associado)
          })
          
          setListaAssociados(listaAssociadosCompleta)
        })
        .catch(err => console.log(err))
}, [])
  
    return {
      listaAssociados
    };
  };