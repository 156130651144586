import { useDispatch, useSelector } from 'react-redux';

import EditItem from '../../../../shared/components/IconButtons/EditItem';
import { handleAssociadoChange } from '../../../../../store/reducers/associadoSlice';
import CampoInputRadio from '../../../../shared/components/CampoInputComButton/CampoInputRadio/CampoInputRadio';

const CampoInputSegmentoLavanderia = ({ onClick, editButton, disabled }) => {

    const dispatch = useDispatch()
    const { washer_segment } = useSelector(state => state.associadoState)
    
    const handleChange = (name, value) => {
        dispatch(handleAssociadoChange({[name]: value}))
    }
    const handleClick = (e) => {
        e.preventDefault()
        onClick("washer_segment")
    }

    return (
        <CampoInputRadio
            id="inputSegmentoLavanderia"
            titulo="Segmento lavanderia*"
            onChange={handleChange}
            name="washer_segment"
            value={washer_segment}
            radioValores={["Independente", "Franquia"]}
            required={true}
            disabled={disabled}
        >
            {
                editButton ? <EditItem onClick={handleClick} /> : <></>
            }
        </CampoInputRadio>
    )
}

export default CampoInputSegmentoLavanderia
