export default class Cep {
    constructor(dadosCep) {
        this.cep = dadosCep.cep
        this.estado = dadosCep.estado
        this.cidade = dadosCep.cidade
        this.bairro = dadosCep.bairro
        this.logradouro = dadosCep.logradouro  
    }

    static create(dadosCep) {
        return new Cep(dadosCep)
    }
}