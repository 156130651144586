import { useEffect, useState } from "react";
import http from "../../../../http";

export const useBuscaNumeroOcorrenciasDoDia = () => {
    const [totalOcorrenciasDoDia, setTotalOcorrenciasDoDia] = useState('')
    useEffect(() => {
        http.get('/ocorrencias-hoje', )
        .then(res => {
            const nrOcorrenciasHoje = res.data
            setTotalOcorrenciasDoDia(nrOcorrenciasHoje)
        })
    }, [])

    return {
        totalOcorrenciasDoDia
    }
}