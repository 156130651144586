import styled from 'styled-components'
import logoAbralav from '../../assets/abralav-qdd.svg'

export const LogoAbralav = styled.img`
    background-image: url(${logoAbralav});
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    height: 64px;
    width: 200px;
`