import React, { useEffect, useState } from 'react'
import TabelaSimples from './index'

function createData(email, cnpj, social_name, phone1, phone2, id) {
    return {
      email,
      cnpj,
      social_name,
      phone1,
      phone2,
      id
    };
  }

const headCells = [
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'E-mail',
  },
  {
    id: 'cnpj',
    numeric: false,
    disablePadding: false,
    label: 'CNPJ',
  },
  {
    id: 'social_name',
    numeric: false,
    disablePadding: false,
    label: 'Razão Social',
  },
  {
    id: 'phone1',
    numeric: false,
    disablePadding: false,
    label: 'Telefone',
  },
  {
    id: 'phone2',
    numeric: false,
    disablePadding: false,
    label: 'Celular',
  }
      
]

const valueCells = [
    {
        value: 'email',
        alignment: 'left'
    },
    {
        value: 'cnpj',
        alignment: 'left'
    },
    {
        value: 'social_name',
        alignment: 'left'
    },
    {
        value: 'phone1',
        alignment: 'left'
    },
    {
        value: 'phone2',
        alignment: 'left'
    }
]

export default function DadosAssociado({cadastros, onClick, children}) {
  const [linhas, setLinhas] = useState([])
  useEffect(() => {
    if(cadastros) {
        const listaCadastros = cadastros.map(value => createData(value.email, value.cnpj, value.social_name, value.phone, value.phone2, value.id))
        setLinhas(listaCadastros)
    }
}, [cadastros])
    return (
        <TabelaSimples
            headCells={headCells}
            linhas={linhas}
            valueCells={valueCells}
            titulo="Cadastros"
            onClick={onClick}>
            {children}
        </TabelaSimples>
    )
}