import React from "react";
import {ReactComponent as LogoAbralav} from '../../../../assets/abralav-qdd.svg'
import * as S from './style'

export default function Logo() {
    return (
        <S.Logo>
            <LogoAbralav /> 
        </S.Logo> 
    )
}