import { useSelector } from 'react-redux';
import CampoInputTexto from '../../../../shared/components/CampoInputComButton/CampoInputTexto/CampoInputTexto';
import VoidIcon from '../../../../shared/components/IconButtons/VoidIcon/VoidIcon';

const CampoInputBairro = ({ editButton }) => {

    const { bairro } = useSelector(state => state.localizacaoState)
    
    return (
        <CampoInputTexto
            id="inputBairro"
            titulo="Bairro*"
            name="bairro"
            value={bairro}
            required={true}
            disabled={true}
        >
            {
                editButton ? <VoidIcon /> : <></>
            }
        </CampoInputTexto>
    )
}

export default CampoInputBairro
