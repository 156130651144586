import { useCallback, useState } from "react";
import http from "../../../../http";

import UsuarioOutput from "../../../usuario/models/UsuarioOutput";
import CadastroOutput from "../../models/CadastroOutput";

export const useBuscaCadastros = () => {
    const [resposta, setResposta] = useState({})

    const buscaCadastros = useCallback(() => {
        http.get('/cadastros')
        .then(res => {
            const respostaRaw = res.data
            const retorno = respostaRaw.map(cadastro => {
                return {
                    cadastro: CadastroOutput.create(cadastro),
                    usuario: UsuarioOutput.create(cadastro.Usuario),
                }
            })
            setResposta({ data: retorno, error: null })
        })
        .catch(error => {
            const { response } = error
            setResposta({data: null, error: response})
        })
    }, [])

    return [resposta, buscaCadastros]
    
}