import React from 'react'
import ContainerTitleInputField from '../../../../../components/Containers/ContainerTitleInputField'
import FormRadioInput from '../../../../../components/FormRadioInput'

const CampoInputRadio = ({ name, onChange, titulo, ...props }, ) => {

    const handleOnChange = (e) => {
        onChange(name, e.target.value)
    }

    return (
        <ContainerTitleInputField titulo={titulo}>
            <FormRadioInput
                {...props}
                onChange={handleOnChange}
                 />
        </ContainerTitleInputField>
    )
}

export default CampoInputRadio