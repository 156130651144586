import React from 'react'
import TabelaComSelection from '../../../../components/TabelaComSelection/index'

function createData(email, id) {
    return {
      email,
      id
    };
  }

  const headCells = [
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: 'Email administradores',
    }
  ];

const valueCells = [
    {
        value: 'email',
        aligment: 'left'
    }
]

export default function TabelaGerenciarAdministradores(props) {

    const linhas = props.usuarios.map(value => createData(value.email, value.id))
    return (
        <TabelaComSelection headCells={headCells} linhas={linhas} valueCells={valueCells} onClick={props.onClick} selecionados={props.selecionados} tituloToolbar={props.tituloToolbar}>
            {props.children}
        </TabelaComSelection>
    )
}