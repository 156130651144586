import { useEffect, useState } from "react"
import MarcasOutput from "../../models/marcas/MarcasOutput"
import http from "../../../../http"

export const useBuscaListaMarcas = () => {
    const [listaMarcas, setListaMarcas] = useState(MarcasOutput.create([]))
    useEffect(() => {
        http.get('/brands')
            .then(res => {
                const resposta = res.data
                const listaMarcas = MarcasOutput.create(resposta)
                setListaMarcas(listaMarcas)
            })
    }, [])

    return {
        listaMarcas,
    }
}