import UsuarioAbstract from "./UsuarioAbstract"

export default class UsuarioInput extends UsuarioAbstract {
    constructor(dadosUsuario) {
        super(dadosUsuario.email)
        this.senha = dadosUsuario.senha
        this.confirmaSenha = dadosUsuario.confirmaSenha
    }

    static create (usuario) {
        this.validate(usuario)
        return new UsuarioInput(usuario)
    }

    static validate(usuario) {
        if(usuario.senha !== usuario.confirmaSenha) {
            throw new Error('Campos senha e confirma senha devem ser iguais')
        }
    }
}

