import { useEffect, useState } from "react";
import UsuarioOutput from "../../../usuario/models/UsuarioOutput";
import { useStatusFetch } from "../../../shared/hooks/useStatusFetch";
import http from "../../../../http";

export const useBuscaListaEmails = () => {

  const [listaUsuarios, setListaUsuarios] = useState([])
  const { needFetching } = useStatusFetch(listaUsuarios)

  useEffect(() => {
    if (!needFetching) return
    http.get(`/usuarios`)
      .then(res => {
        const resposta = res.data
        const listaDeUsuarios = resposta.map(user => {
          const usuario = UsuarioOutput.create(user)
          return usuario
        })
        setListaUsuarios(listaDeUsuarios) 
      })
      .catch(err => console.log(err))
    
  }, [needFetching])

    return {
      listaUsuarios
    };
  };