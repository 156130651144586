import React, { memo } from 'react'
import ContainerTitleInputField from '../../../../../components/Containers/ContainerTitleInputField'
import FormInputTexto from '../../../../../components/FormInputTexto'

const CampoInputTexto = ({ name, onChange, titulo, ...props }, ) => {
    const handleOnChange = (e) => {
        
        onChange(name, e.target.value)
    }

    return (
        <ContainerTitleInputField titulo={titulo}>
            <FormInputTexto
                {...props}
                onChange={handleOnChange}
                 />
        </ContainerTitleInputField>
    )
}

export default memo(CampoInputTexto)