import { useDispatch, useSelector } from 'react-redux';

import EditItem from '../../../../shared/components/IconButtons/EditItem';
import { handleOcorrenciaChange } from '../../../../../store/reducers/ocorrenciaSlice';
import CampoInputSelect from '../../../../shared/components/CampoInputComButton/CampoInputSelect/CampoInputSelect';

const CampoInputLimpezaUtilizada = ({ onClick, editButton, disabled }) => {

    const dispatch = useDispatch()
    const { wash_used } = useSelector(state => state.ocorrenciaState)
    
    const handleChange = (name, value) => {
        dispatch(handleOcorrenciaChange({[name]: value}))
    }
    const handleClick = (e) => {
        e.preventDefault()
        onClick("wash_used")
    }

    return (
        <CampoInputSelect
            id="inputLimpezaUtilizada"
            titulo="Limpeza utilizada*"
            required
            onChange={handleChange}
            name="wash_used"
            value={wash_used}
            disabled={disabled}
            itensDoSelect={['ÁGUA', 'SECO', 'MANUAL', 'WET CLEANING', 'NÃO CONSTA']}
        >
            {
                editButton ? <EditItem onClick={handleClick} /> : <></>
            }
        </CampoInputSelect>
    )
}

export default CampoInputLimpezaUtilizada
