import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleOcorrenciaChange } from '../../../../../store/reducers/ocorrenciaSlice';
import { removerDuplicadosPor } from '../../../../../Utilitarios';
import CampoInputAutocompleteMultipleOptions from '../../../../shared/components/CampoInputComButton/CampoInputAutocompleteMultipleOptions/CampoInputAutocompleteMultipleOptions';
import EditItem from '../../../../shared/components/IconButtons/EditItem';
import VoidIcon from '../../../../shared/components/IconButtons/VoidIcon/VoidIcon';
import { useBuscaListaPecas } from '../../../hooks/useBuscaListaPecas';

const CampoInputPecas = ({ editButton, disabled, onClick }) => {

    const [listaSemDuplicados, setListaSemDuplicados] = useState([])
    const { listaPecas } = useBuscaListaPecas()
    const { items } = useSelector(state => state.ocorrenciaState)
    const dispatch = useDispatch()
    
    const handleChange = (name, values) => {
        dispatch(handleOcorrenciaChange({[name]: values}))
    }

    const handleClick = (e) => {
        e.preventDefault()
        onClick("items")
    }

    useEffect(() => {
        if(listaPecas.listaPecas) {
            const novaLista = removerDuplicadosPor(pecas => pecas.name, listaPecas.listaPecas)
            setListaSemDuplicados(novaLista)
        }
    }, [listaPecas])

    return (
        <CampoInputAutocompleteMultipleOptions
            titulo="Peça*" 
            id="formAutocompletePeca" 
            name="items" 
            disabled={disabled}
            getOptionLabel={option => option.name}
            renderOption={(props, option, state) => [props, option.name, state.id]}
            value={items}
            onChange={handleChange}
            multiple={true}
            listaAutocomplete={listaSemDuplicados} >
            {
                editButton === 'void' ? <VoidIcon /> : <></>
            }
            {
                editButton === 'edit' ? <EditItem onClick={handleClick} /> : <></>
            }
        </CampoInputAutocompleteMultipleOptions>
    )
}

export default memo(CampoInputPecas)
