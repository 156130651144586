import { Paper } from "@mui/material"
import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import MainTitle from "../../modules/shared/components/MainTitle"
import NavBar from "../../modules/usuario/components/NavBar/NavBar"
import SideDrawer from "../../modules/usuario/components/SideDrawer/SideDrawer"
import * as S from './style'
import { useVerificaEmail } from "../../modules/usuario/hooks/useVerificaEmail/useVerificaEmail"
import Loading from "../../components/Loading"


export default function EmailValidado() {
    const [resposta, verificaEmail] = useVerificaEmail()
    const navigate = useNavigate()

    const { token } = useParams()

    useEffect(() => {
        verificaEmail(token)
    }, [token, verificaEmail])

    useEffect(() => {
        setTimeout(() => {
            navigate('/login')
        }, 8000)
    }, [navigate])

    return (
        <Paper>
            <NavBar />
            <SideDrawer permissao={1} >
                <Paper>
                    {
                        Object.entries(resposta).length === 0 && <Loading />
                    }
                    {
                        resposta.error && (
                            <>
                                <MainTitle titulo="Erro na validação de e-mail" tituloPrincipal />
                                <S.CampoMensagem>{resposta.error.data}</S.CampoMensagem>
                            </>
                        )
                    }
                    {
                        resposta.data && resposta.data.status === 204 && (
                            <>
                                <MainTitle titulo="Email validado com sucesso" tituloPrincipal />
                                <S.CampoMensagem>Seu cadastro foi validado com sucesso. Uma solicitação de liberação foi enviada ao administrador. Aguarde esta liberação para poder acessar a área do associado.</S.CampoMensagem>
                            </>
                        )
                    }
                </Paper>
            </SideDrawer>
            
        </Paper>
    )
}