import { useEffect, useState } from "react";
import http from "../../../../http";

export const useBuscaAdministradoresAtivos = () => {
    const [listaAdministradoresAtivos, setListaAdministradoresAtivos] = useState([])
    
    useEffect(() => {
        http.get(`/administradores-ativos`)
            .then(res => {
                const resposta = res.data
                setListaAdministradoresAtivos(resposta)
            })
    }, [])

    return {
        listaAdministradoresAtivos,
    }
}