import React, { memo } from 'react'
import ContainerTitleInputField from '../../../../../components/Containers/ContainerTitleInputField'
import FormSelectInput from '../../../../../components/FormSelectInput'

const CampoInputSelect = ({ name, onChange, titulo, itensDoSelect, ...props }, ) => {

    const handleChange = (e) => {
        onChange(name, e.target.value)
    }

    return (
        <ContainerTitleInputField titulo={titulo}>
            <FormSelectInput
                onChange={handleChange} 
                itensDoSelect={itensDoSelect}
                {...props}/>
            </ContainerTitleInputField>
    )
}

export default memo(CampoInputSelect)