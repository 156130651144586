import AccordionCustomizado from "../AccordionCustomizado"
import InfoIcon from '@mui/icons-material/Info';
import HomeIcon from '@mui/icons-material/Home';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import InsightsIcon from '@mui/icons-material/Insights';
import PersonIcon from '@mui/icons-material/Person';
import GroupsIcon from '@mui/icons-material/Groups';

function AdminAccordion() {

    const simpleItems = [
        {
            nome: "Inicial",
            caminho: "/inicial",
            chave: "Inicial",
            icone: (<HomeIcon />)
        }
    ]

    const displayAccordionData = [
        {
            painel: "panel1",
            icone: (<InfoIcon />),
            titulo: "Laudos",
            item: [{
                chave: "CadastrarLaudos",
                caminho: "/cadastrar-laudo",
                nomeItem: "Cadastrar laudo"
            },
            {
                chave: "EditarLaudo",
                caminho: "/editar-laudo",
                nomeItem: "Editar laudo"
            },

            ]
        },
        {
            painel: "panel2",
            icone: (<WarningAmberIcon />),
            titulo: "Ocorrências",
            item: [{
                chave: "CadastrarCampo",
                caminho: "/cadastrar-campo",
                nomeItem: "Cadastrar campo"
            },
            {
                chave: "CadastrarOcorrencia",
                caminho: "/cadastrar-ocorrencia",
                nomeItem: "Cadastrar ocorrência"
            },
            {
                chave: "EditarOcorrencia",
                caminho: "/editar-ocorrencia",
                nomeItem: "Editar ocorrência"
            },

            ]
        },
        {
            painel: "panel3",
            icone: (<InsightsIcon />),
            titulo: "Relatórios",
            item: [{
                chave: "RelatorioOcorrencia",
                caminho: "/ocorrencias-campo",
                nomeItem: "Dados de ocorrências"
            },
            {
                chave: "RelatorioAssociados",
                caminho: "/dados-associados",
                nomeItem: "Dados de associados"
            }]
        },
        {
            painel: "panel4",
            icone: (<PersonIcon />),
            titulo: "Permissões",
            item: [{
                chave: "CadastrarAdministrador",
                caminho: "/cadastrar-administradores",
                nomeItem: "Cadastrar administrador"
            },
            {
                chave: "DesativarAdministrador",
                caminho: "/desativar-administradores",
                nomeItem: "Desativar administrador"
            },
            {
                chave: "AtivarAdministrador",
                caminho: "/ativar-administradores",
                nomeItem: "Ativar administrador"
            }]
        },
        {
            painel: "panel5",
            icone: (<GroupsIcon />),
            titulo: "Associados",
            item: [{
                chave: "CadastrarAssociados",
                caminho: "/admin/cadastrar-associados",
                nomeItem: "Cadastrar associados"
            },
            {
                chave: "CadastrarEstabelecimento",
                caminho: "/adiciona-estabelecimento",
                nomeItem: "Cadastrar Estabelecimento"
            },
            {
                chave: "EditarAssociados",
                caminho: "/editar-associados",
                nomeItem: "Editar associados"
            },
            {
                chave: "BloquearAcessos",
                caminho: "/bloquear-acessos",
                nomeItem: "Bloquear acessos"
            },
            {
                chave: "LiberarAcessos",
                caminho: "/liberar-acessos",
                nomeItem: "Liberar acessos"
            }]
        },
    ]

    return <AccordionCustomizado simpleItems={simpleItems} items={displayAccordionData} />
}

export default AdminAccordion