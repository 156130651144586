import { useEffect, useState } from "react";
import PecasOutput from "../../models/pecas/PecasOutput";
import http from "../../../../http";

export const useBuscaListaPecas = () => {
    const [listaPecas, setListaPecas] = useState(PecasOutput.create([]))
    useEffect(() => {
        http.get('/items')
            .then(res => {
                const resposta = res.data
                const listaPecas = PecasOutput.create(resposta)
                setListaPecas(listaPecas)
            })
    }, [])

    return {
        listaPecas,
    }
}