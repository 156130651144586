import styled from 'styled-components'

export const ContainerCampo = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
`

export const ContainerBotaoOpcional = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
`

export const ContainerItemCadastro = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 25px;
    @media (max-width: 950px) {
        flex-direction: column;
        gap: 20px;
    }
`