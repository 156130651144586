import React, { useEffect, useState } from 'react'
import { useBuscaLaudoPdf } from '../../hooks/useBuscaLaudoPdf'
import { criaObjetoURL } from '../../../../Utilitarios'
import MainTitle from '../../../shared/components/MainTitle'
import { useSelector } from 'react-redux'
import Botao from '../../../../components/Botao'
import * as S from './style'
import { Paper } from '@mui/material'
const DisplayPDFOnScreen = () => {
    const { codigoAT } = useSelector(state => state.ocorrenciaSelecionadaState)
    const { href } = useBuscaLaudoPdf(codigoAT)
    const [pdf, setPdf] = useState("")

    useEffect(() => {
        if(codigoAT && href) {
            const objHref = criaObjetoURL(href) 
            setPdf(objHref)
        } else {
            setPdf()
        }
    }, [codigoAT, href])

    const handleDownload = (e) => {
        e.preventDefault()
        const link = document.createElement('a');
        link.href = URL.createObjectURL(href);
        link.setAttribute('download', 'laudo.pdf');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <Paper>
            <MainTitle titulo={"Visualização de laudo cadastrado"} subtitulo={true} />
                <S.ContainerObject>
                    <object data={pdf} type='application/pdf' width="100%" height="600px">
                        <p>Seu navegador não possui um plugin de visualização. Clique no botão abaixo para baixar o laudo.</p>
                        <Botao onClick={handleDownload} titulo="baixar laudo"></Botao>  
                    </object>
                </S.ContainerObject>
        </Paper>
    )
}

export default DisplayPDFOnScreen