import { useDispatch, useSelector } from 'react-redux';
import EditItem from '../../../../shared/components/IconButtons/EditItem';
import { handleEmailAssociadoSelecionadoChange, resetEmailAssociadoSelecionado } from '../../../../../store/reducers/emailAssociadoSelecionadoSlice';
import CampoInputAutocomplete from '../../../../shared/components/CampoInputComButton/CampoInputAutocomplete/CampoInputAutocomplete';
import { VoidIcon } from '../../../../shared/components/IconButtons/VoidIcon/style';

const CampoInputSelecionaEmail = ({ onClick, editButton, disabled, listaAssociados }) => {

    const dispatch = useDispatch()
    const emailAssociadoSelecionado = useSelector(state => state.emailAssociadoSelecionadoState)
    
    const handleChange = (name, value) => {
        if(value) {
            const { id, email } = value
            dispatch(handleEmailAssociadoSelecionadoChange({ id, email }))
        } else {
            dispatch(resetEmailAssociadoSelecionado())
        }
    }

    const handleClick = (e) => {
        e.preventDefault()
        onClick("email")
    }

    return (
        <CampoInputAutocomplete
            id="inputSelecionaEmail"
            titulo="Email vinculado*"
            onChange={handleChange}
            name="email"
            value={emailAssociadoSelecionado.id === "" ? null : emailAssociadoSelecionado}
            label="obrigatório"
            required={true}
            disabled={disabled}
            getOptionLabel={option => option.email}
            renderOption={(props, option, state) => {
                return [props, option.email, state.id]
            }}
            isOptionEqualToValue={(option, value) => option.email === value.email}
            listaAutocomplete={listaAssociados}
        >
            {
                editButton === "void" ? <VoidIcon /> : <></>
            }
            {
                editButton === "edit" ? <EditItem onClick={handleClick} /> : <></>
            }
        </CampoInputAutocomplete>
    )
}

export default CampoInputSelecionaEmail
