import styled from 'styled-components'

export const ContainerCard = styled.div`
    height: 150px;
    border-radius: 5px;
    border-left: solid 3px ${props => props.theme.main};
    display: grid;
    grid-template-columns: 130px 1fr;
    grid-template-rows: 1fr;
    align-items: center;
    padding-left: 15px;
`
export const ContainerDadosCard = styled.div`
    display: flex;
    flex-direction: column;
`

export const TitleCard = styled.h1`
    font-size: 18px;
`

export const CardData = styled.p`
    
`



