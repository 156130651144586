import { createSlice } from "@reduxjs/toolkit"

const estadoInicial = {
    campo: "",
    valor: ""
}

const campoValorSlice = createSlice({
    name: 'campoValorSelecionado',
    initialState: estadoInicial,
    reducers: {
        handleCampoValorChange: (state, action) => {
            return {...state, ...action.payload }
        },
        resetCampoValor: (state) => {
            return estadoInicial;
        }
    }
})

export const { handleCampoValorChange, resetCampoValor } = campoValorSlice.actions

export default campoValorSlice.reducer