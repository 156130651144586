import React from 'react'
import ContainerTitleInputField from "../../../../../components/Containers/ContainerTitleInputField"
import FormAutocompletar from "../../../../../components/FormAutocompletar"

const CampoInputAutocomplete = ({ titulo, id, name, onChange, listaAutocomplete, children, ...props }) => {

    const handleOnChange = (e, newValue) => {
        onChange(name, newValue)
    }

    return (
        <ContainerTitleInputField titulo={titulo}>
            <FormAutocompletar
                id={id}
                required={true}
                onChange={handleOnChange}
                options={listaAutocomplete}
                {...props}
            >
                {children ? children : <></>}
            </FormAutocompletar>
        </ContainerTitleInputField>
    )
}

export default CampoInputAutocomplete