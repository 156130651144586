import styled from 'styled-components'
import Autocomplete from '@mui/material/Autocomplete';

export const ContainerItemGenerico = styled.div`
    background-color: #fbfbfb;
    border: 1px solid #ededed;
    border-radius: 5px;
    padding: 20px;
    @media (max-width: 950px) {
        grid-template-columns: 1fr;
        gap: 0;
    }
`

export const ContainerItemCadastro = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: minmax(56px, 1fr);
    align-items: center;
    gap: 25px;
    @media (max-width: 950px) {
        grid-template-columns: 1fr;
        gap: 0;
    }
`

export const ItemTitle = styled.h5`
    font-weight: normal;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    justify-self: flex-end;
    text-align: right;
    @media (max-width: 950px) {
        justify-self: flex-start;
        display: ${(props) => props.botao ? "none" : "flex"};
    }
`

export const ContainerComButton = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`

export const ContainerVoidIcon = styled.div`
    align-self: flex-end;
    margin-left: 20px;
    margin-right: 20px;
`

export const InputAutocomplete = styled(Autocomplete)`
    min-height: 38px;
    padding: 0;
    max-width: 420px;
    width: 100%;
`




