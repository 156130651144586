import { useCallback, useState } from "react"
import http from "../../../../http"
import CadastroOutput from "../../models/CadastroOutput"

export const useAtualizaCadastroPorADM = () => {
    const [resposta, setResposta] = useState({})

    const atualizarCadastroPorADM = useCallback((cadastro_id, inputsCadastro) => {
        const cadastro = CadastroOutput.create(inputsCadastro)
        http.put(`/admin/cadastros/${cadastro_id}`, cadastro)
            .then(res => {
                if(res.status === 204) setResposta({ data: 'Cadastro foi atualizado com sucesso', error: null })
            })
            .catch(error => {
                const { response } = error 
                setResposta({ data: null, error: response })
            })
    }, [])

    return [resposta, atualizarCadastroPorADM]
}