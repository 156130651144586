import React from 'react'
import ContainerTitleInputField from "../../../../components/Containers/ContainerTitleInputField";
import FormInputTexto from "../../../../components/FormInputTexto";
import { memo } from 'react';
import CampoInputAssociado from '../../../associado/components/Inputs/CampoInputAssociado/CampoInputAssociado';
import { useBuscaListaCadastros } from '../../../associado/hooks/useBuscaListaCadastros';
import CampoInputPecas from '../Inputs/CampoInputPecas/CampoInputPecas';
import CampoInputMarcas from '../Inputs/CampoInputMarcas/CampoInputMarcas';

const CamposInformacoesDaOcorrencia = ({ dadosOcorrencia, disabled }) => {

    const { listaAssociados } = useBuscaListaCadastros()

    return (
        <>
            <CampoInputAssociado
                editButton={false}
                disabled={true}
                listaAssociados={listaAssociados} />
            <CampoInputPecas
                editButton={false}
                disabled={true} />
            <CampoInputMarcas
                editButton={false}
                disabled={true} />
                
            <ContainerTitleInputField titulo="Cliente*">
                <FormInputTexto
                    id="formInputCliente"
                    disabled={disabled}
                    value={dadosOcorrencia ? dadosOcorrencia.getCliente() : ""} />
            </ContainerTitleInputField>
        </>
    )
}

export default memo(CamposInformacoesDaOcorrencia)