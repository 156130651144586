import { createSlice } from "@reduxjs/toolkit"

const estadoInicial = {
    codigoAT: ""
}

const ocorrenciaSelecionadaSlice = createSlice({
    name: 'dadosOcorrenciaSelecionada',
    initialState: estadoInicial,
    reducers: {
        handleOcorrenciaSelecionadaChange: (state, action) => {
            return {...state, ...action.payload }
        },
        resetOcorrenciaSelecionada: (state) => {
            return estadoInicial;
        }
    }
})

export const { handleOcorrenciaSelecionadaChange, resetOcorrenciaSelecionada } = ocorrenciaSelecionadaSlice.actions

export default ocorrenciaSelecionadaSlice.reducer