import { createSlice } from "@reduxjs/toolkit"

const estadoInicial = {
    email: "",
    id: ""
}

const emailAssociadoSelecionadoSlice = createSlice({
    name: 'dadosEmailAssociadoSelecionado',
    initialState: estadoInicial,
    reducers: {
        handleEmailAssociadoSelecionadoChange: (state, action) => {
            return {...state, ...action.payload }
        },
        resetEmailAssociadoSelecionado: (state) => {
            return estadoInicial;
        }
    }
})

export const { handleEmailAssociadoSelecionadoChange, resetEmailAssociadoSelecionado } = emailAssociadoSelecionadoSlice.actions

export default emailAssociadoSelecionadoSlice.reducer