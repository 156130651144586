import { useDispatch, useSelector } from 'react-redux';

import EditItem from '../../../../shared/components/IconButtons/EditItem';
import { handleOcorrenciaChange } from '../../../../../store/reducers/ocorrenciaSlice';
import CampoInputSelect from '../../../../shared/components/CampoInputComButton/CampoInputSelect/CampoInputSelect';

const CampoInputResponsabilidade = ({ onClick, editButton, disabled }) => {

    const dispatch = useDispatch()
    const { responsible } = useSelector(state => state.ocorrenciaState)
    
    const handleChange = (name, value) => {
        dispatch(handleOcorrenciaChange({[name]: value}))
    }
    const handleClick = (e) => {
        e.preventDefault()
        onClick("responsible")
    }

    return (
        <CampoInputSelect
            id="inputResponsabilidade"
            titulo="Responsabilidade*"
            required={true}
            onChange={handleChange}
            name="responsible"
            value={responsible}
            disabled={disabled}
            itensDoSelect={['CLIENTE', 'CONFECÇÃO', 'LAVANDERIA', 'INDEFINIDO', 'SEM DANO']}
        >
            {
                editButton ? <EditItem onClick={handleClick} /> : <></>
            }
        </CampoInputSelect>
    )
}

export default CampoInputResponsabilidade
