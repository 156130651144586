import React from 'react';
import { useDropzone } from 'react-dropzone';
import * as S from './style'

export default function MyDropZone(props) {
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps
  } = useDropzone({
    accept: {
      'application/pdf': ['.pdf']
    },
    multiple: false,
    onDropAccepted: (file) => {
      props.onDropAccepted(file)
      if(props.onChange) {
        props.onChange(file)
      }
    }
  });

  const acceptedFileItems = acceptedFiles.map(file => {
    return (
      <li key={file.path}>
        {file.path} - {file.size} bytes
      </li>
    )
  });

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
      <ul>
        {errors.map(e => {
          return (
            <li key={e.code}>{e.message}</li>
          )
        })}
      </ul>
    </li>
  ));

  return (
    <S.JanelaDragDrop data-testid={props.id} >
        <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <p>Arrastar arquivo aqui ou clique para selecionar arquivo</p>
            <em>(Apenas *.pdf serão aceitas)</em>
        </div>
        <aside>
            <h4>Arquivos aceitos</h4>
            <ul>{acceptedFileItems}</ul>
            <h4>Arquivos rejeitados</h4>
            <ul>{fileRejectionItems}</ul>
        </aside>
    </S.JanelaDragDrop>
  );
}