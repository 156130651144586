import React from 'react'
import * as S from './style'


export default function Botao(props) {
    return (
        <S.CadastrarOcorrenciaButton 
            data-testid="genericButton"
            variant="contained" 
            size="small" 
            onClick={props.onClick ? props.onClick : () => {}}
            type={props.type}>
            {props.titulo}
        </S.CadastrarOcorrenciaButton>
    )
}