import CampoInputTexto from '../../../../shared/components/CampoInputComButton/CampoInputTexto/CampoInputTexto';
import { useDispatch, useSelector } from 'react-redux';
import { handleAssociadoChange } from '../../../../../store/reducers/associadoSlice'
import EditItem from "../../../../shared/components/IconButtons/EditItem";
import { cnpjMask } from "../../../../../Utilitarios";
const CampoInputCnpj = ({ onClick, editButton, disabled }) => {

    const dispatch = useDispatch()
    const { cnpj } = useSelector(state => state.associadoState)
    
    const handleChange = (name, value) => {
        dispatch(handleAssociadoChange({[name]: cnpjMask(value)}))
    }
    
    const handleClick = (e) => {
        e.preventDefault()
        onClick("cnpj")
    }

    return (
        <CampoInputTexto
            id="inputCNPJ"
            titulo="CNPJ*"
            onChange={handleChange}
            name="cnpj"
            value={cnpj}
            label="obrigatório"
            required={true}
            disabled={disabled}
        >
            {
                editButton ? <EditItem onClick={handleClick} /> : <></>
            }
        </CampoInputTexto>
    )
}

export default CampoInputCnpj
