import React, { useState, useEffect } from "react"

import MainTitle from "../../modules/shared/components/MainTitle"
import { retornaListaMatchPorListaCampo } from '../../Utilitarios'

import { useBuscaListaPecas } from "../../modules/ocorrencia/hooks/useBuscaListaPecas"
import { useBuscaListaMarcas } from "../../modules/ocorrencia/hooks/useBuscaListaMarcas"

import TabelaListaValorPorCampoDeOcorrencia from "../../modules/ocorrencia/components/TabelaListaValorPorCampoDeOcorrencia/TabelaListaValorPorCampoDeOcorrencia"
import FormCadastraCampo from "../../modules/ocorrencia/components/FormCadastraCampo/FormCadastraCampo"
import { useSelector } from "react-redux"

export default function CadastrarCampo() {

    const { campo, valor } = useSelector(state => state.campoValorState)
    const [listaSelecionada, setListaSelecionada] = useState([])
    const [filtrado, setFiltrado] = useState([])
    
    const { listaPecas } = useBuscaListaPecas()
    const { listaMarcas } = useBuscaListaMarcas()

    useEffect(() => {
        if(campo === 'Peça') {
            setListaSelecionada(listaPecas.listaPecas)
            setFiltrado(listaPecas.listaPecas)
        }
        if(campo === 'Marca') {
            setListaSelecionada(listaMarcas.listaMarcas)
            setFiltrado(listaMarcas.listaMarcas)
        }
    }, [campo, listaMarcas, listaPecas])   

    useEffect(() => {
        if(valor !== "") {
            const novaLista = retornaListaMatchPorListaCampo(listaSelecionada, 'name', valor)
            setFiltrado(novaLista)
        }
    }, [valor, listaSelecionada])   

    return (
        <>
            <MainTitle titulo="Cadastrar item de campo" tituloPrincipal={true} />
            <FormCadastraCampo />
            {campo !== "" && (
                <TabelaListaValorPorCampoDeOcorrencia 
                    usuarios={filtrado}
                    campo={campo}
                    onClick={() => {}}/>
            )}
        </>
    )
}