import * as React from 'react';
import BlockIcon from '@mui/icons-material/Block';
import IconButton from '@mui/material/IconButton';
import { Tooltip } from '@mui/material';

export default function BlockItem(props) {

    return (
        <Tooltip title="Bloquear acesso">
            <IconButton onClick={props.onClick} linha={props.linha}>
                <BlockIcon />
            </IconButton>
        </Tooltip>
    )
}