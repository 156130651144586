import { useCallback, useState } from "react"
import OcorrenciaInput from "../../models/ocorrencia/OcorrenciaInput" 
import http from "../../../../http"

export const useCadastraOcorrencia = () => {
    const [resposta, setResposta] = useState({})

    const cadastrarOcorrencia = useCallback((inputsOcorrencia) => {
        const { usuario_id, ...demais} = inputsOcorrencia
        const ocorrencia = OcorrenciaInput.create(demais)
        
        http.post(`/usuarios/${usuario_id}/ocorrencias`, ocorrencia)
            .then(res => {
                setResposta({ data: res.data, error: null })
            })
            .catch(error => {
                const {response} = error
                setResposta({ data: null, error: response })
            })
    }, [])
    return [resposta, cadastrarOcorrencia]
}