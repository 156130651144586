import React, { useEffect } from 'react'

import Botao from '../../../../components/Botao'
import ContainerTitleInputField from '../../../../components/Containers/ContainerTitleInputField'
import { retornaSomenteNumerosDeString } from '../../../../Utilitarios'
import { useDispatch, useSelector } from 'react-redux'
import { resetAssociado } from '../../../../store/reducers/associadoSlice'
import { resetUsuario } from '../../../../store/reducers/usuarioSlice'
import CamposCadastraEstabelecimento from '../CamposCadastraEstabelecimento/CamposCadastraEstabelecimento'
import { resetAssociadoSelecionado } from '../../../../store/reducers/associadoSelecionadoSlice'
import { resetLocalizacao } from '../../../../store/reducers/localizacaoSlice'
import { resetOcorrencia } from '../../../../store/reducers/ocorrenciaSlice'
import { resetOcorrenciaSelecionada } from '../../../../store/reducers/ocorrenciaSelecionadaSlice'

const FormCadastraEstabelecimento = ({ aoSubmeterForm, onClick }) => {

    const dadosAssociado = useSelector(state => state.associadoState)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(resetAssociado())
        dispatch(resetAssociadoSelecionado())
        dispatch(resetLocalizacao())
        dispatch(resetOcorrencia())
        dispatch(resetOcorrenciaSelecionada())
        dispatch(resetUsuario())
    }, [dispatch])
    
    const handleClick = (name) => {
        onClick(name)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        const envioValores = {
            ...dadosAssociado,
            cep: retornaSomenteNumerosDeString(dadosAssociado.cep),
            phone1: retornaSomenteNumerosDeString(dadosAssociado.phone1)
        }
        aoSubmeterForm(envioValores)
    }

    return (
        <form method="post" onSubmit={onSubmit}>
            <CamposCadastraEstabelecimento editButton={false} onClick={handleClick} />
            <ContainerTitleInputField titulo="" botao>
                <Botao titulo="Cadastrar estabelecimento" type="submit" />
            </ContainerTitleInputField>
        </form>
    )
}

export default FormCadastraEstabelecimento