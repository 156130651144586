import AccordionCustomizado from "../AccordionCustomizado"
import HomeIcon from '@mui/icons-material/Home';


export default function LoginAccordion() {
    const simpleItems = [
        {
            nome: "Retornar página de login",
            caminho: "/login",
            chave: "PaginaLogin",
            icone: (<HomeIcon />)
        },
        
    ]
    const displayAccordionData = []

    return <AccordionCustomizado simpleItems={simpleItems} items={displayAccordionData} />
}