import * as React from 'react';
import DoneIcon from '@mui/icons-material/Done';
import IconButton from '@mui/material/IconButton';
import { Tooltip } from '@mui/material';

export default function AllowItem(props) {

    return (
        <Tooltip title="Liberar acesso">
            <IconButton onClick={props.onClick} linha={props.linha}>
                <DoneIcon />
            </IconButton>
        </Tooltip>
    )
}