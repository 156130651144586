import AccordionCustomizado from "../AccordionCustomizado"
import InfoIcon from '@mui/icons-material/Info';
import HomeIcon from '@mui/icons-material/Home';
import InsightsIcon from '@mui/icons-material/Insights';
import StoreIcon from '@mui/icons-material/Store';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import SaveAsIcon from '@mui/icons-material/SaveAs';

export default function UserAccordion() {
    const simpleItems = [
        {
            nome: "Inicial",
            caminho: "/meus-dados",
            chave: "Inicial",
            icone: (<HomeIcon />)
        },
        {
            nome: "Meus estabelecimentos",
            caminho: "/meus-estabelecimentos",
            chave: "MeusEstabelecimentos",
            icone: (<StoreIcon />)
        },
        {
            nome: "Cadastrar estabelecimento",
            caminho: "/cadastrar-estabelecimento",
            chave: "MeusEstabelecimentos",
            icone: (<AddBusinessIcon />)
        },
        {
            nome: "Editar estabelecimento",
            caminho: "/editar-estabelecimento",
            chave: "MeusEstabelecimentos",
            icone: (<SaveAsIcon />)
        },
        {
            nome: "Laudos",
            caminho: "/laudos",
            chave: "Laudos",
            icone: (<InfoIcon />)
        },
        {
            nome: "Relatórios",
            caminho: "/relatorios",
            chave: "Relatorios",
            icone: (<InsightsIcon />)
        },
    ]
    const displayAccordionData = []

    return <AccordionCustomizado simpleItems={simpleItems} items={displayAccordionData} />
}