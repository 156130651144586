import React from 'react'
import * as S from './style'


export default function ContainerItemGenerico(props) {
    return (
        <S.ContainerItemGenerico>
            {props.children}
        </S.ContainerItemGenerico>
    )
}