import { useCallback, useState } from "react";
import http from "../../../../http";
import UsuarioOutput from "../../../usuario/models/UsuarioOutput";

import CadastroOutput from "../../models/CadastroOutput";

export const useBuscaMeusCadastros = () => {
    const [resposta, setResposta] = useState({})

    const buscaMeusCadastros = useCallback(() => {
        http.get('/meus-cadastros')
        .then(res => {
            const respostaRaw = res.data
            const { Cadastros, ...usuarios } = respostaRaw 
            const retorno = Cadastros.map(cadastro => {
                return {
                    cadastro: CadastroOutput.create(cadastro),
                    usuario: UsuarioOutput.create(usuarios)
                }
            })
            setResposta({ data: retorno, error: null })
        })
        .catch(error => {
            const { response } = error
            setResposta({data: null, error: response})
        })
    }, [])

    return [resposta, buscaMeusCadastros]
    
}