import React from 'react'

import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: '5px',
    boxShadow: 24,
    p: 4,
};


export default function ModalEditItem({open, onClose, onKeyPress, titulo, confirma, children}) {
    return(
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            onKeyPress={onKeyPress}
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {titulo}
                </Typography>
                <>
                    {children}
                </>
                
                <Button onClick={confirma}>Confirma</Button>
                <Button onClick={onClose}>Cancela</Button>
            </Box>
        </Modal>
    )
}