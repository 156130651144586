import { useEffect, useState } from "react";
import http from "../../../../http";

export const useBuscaMeuCadastro = () => {
    const [ativo, setAtivo] = useState('')
    const [cadastroId, setCadastroId] = useState('')
    const [cargo, setCargo] = useState('')
    const [cep, setCep] = useState('')
    const [cnpj, setCnpj] = useState('')
    const [complemento, setComplemento] = useState('')
    const [contato, setContato] = useState('')
    const [numeroLojas, setNumeroLojas] = useState('')
    const [razaoSocial, setRazaoSocial] = useState('')
    const [dataFundacao, setDataFundacao] = useState('')
    const [nomeFantasia, setNomeFantasia] = useState('')
    const [numeroEndereco, setNumeroEndereco] = useState('')
    const [inscricaoEstadual, setInscricaoEstadual] = useState('')
    const [numeroFuncionarios, setNumeroFuncionarios] = useState('')
    const [telefone, setTelefone] = useState('')
    const [site, setSite] = useState('')
    const [nomeProprietario, setNomeProprietario] = useState('')
    const [segmentoLavanderia, setSegmentoLavanderia] = useState('')
    const [redeFranquia, setRedeFranquia] = useState('')
    const [usuarioId, setUsuarioId] = useState('')
    
    useEffect(() => {
        http.get(`/meu-cadastro`)
        .then(res => {
            const meuCadastro = res.data
            setAtivo(meuCadastro.ativo)
            setCadastroId(meuCadastro.id)
            setCargo(meuCadastro.cargo)
            setCep(meuCadastro.cep)
            setCnpj(meuCadastro.cnpj)
            setComplemento(meuCadastro.complemento)
            setContato(meuCadastro.contato)
            setDataFundacao(meuCadastro.foundation_date)
            setInscricaoEstadual(meuCadastro.state_insc)
            setNomeFantasia(meuCadastro.fantasy_name)
            setNomeProprietario(meuCadastro.owner)
            setNumeroEndereco(meuCadastro.numeroEndereco)
            setNumeroFuncionarios(meuCadastro.employees_number)
            setNumeroLojas(meuCadastro.store_number)
            setRedeFranquia(meuCadastro.franchise)
            setRazaoSocial(meuCadastro.social_name)
            setSegmentoLavanderia(meuCadastro.washer_segment)
            setSite(meuCadastro.site)
            setTelefone(meuCadastro.phone1)
            setUsuarioId(meuCadastro.usuario_id)
        })
    }, [])

    return {
        ativo,
        cadastroId,
        cargo,
        cep,
        cnpj,
        complemento,
        contato,
        dataFundacao,
        inscricaoEstadual,
        nomeFantasia,
        nomeProprietario,
        numeroEndereco,
        numeroFuncionarios,
        numeroLojas,
        redeFranquia,
        razaoSocial,
        segmentoLavanderia,
        site,
        telefone,
        usuarioId
    }
}