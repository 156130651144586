export default class UsuarioAbstract {
    constructor(email) {
        if(new.target === UsuarioAbstract) {
            throw new Error("Esta classe não pode ser instanciada")
        }
        this.email = email
    }

    getEmail() {
        return this.email
    }
}