import React, { useEffect, useState } from 'react'

import MainTitle from "../../modules/shared/components/MainTitle"
import MatchFilter from '../../modules/shared/components/CampoMultiplosInputs/MatchFilter/MatchFilter'
import { useBuscaOcorrencias } from '../../modules/ocorrencia/hooks/useBuscaOcorrencias'
import OcorrenciasUsuario from '../../components/TabelaSimples/OcorrenciasUsuario'
import { retornaListaMatchPorListaCampo } from '../../Utilitarios'
import { useSelector } from 'react-redux'

export default function OcorrenciasCampo() {

    const { campo, valor } = useSelector(state => state.campoValorState)

    const [filtrado, setFiltrado] = useState([])
    const [listaOcorrenciasCompleta, setListaOcorrenciasCompleta] = useState([])
    const [resposta, buscaOcorrencias] = useBuscaOcorrencias()

    useEffect(() => {
        buscaOcorrencias()
    }, [buscaOcorrencias])

    useEffect(() => {
        if(Object.entries(resposta).length > 0) {
            const listaTabela = resposta.data.map(value => {
                const { cadastro, listaPecas, listaMarcas, ocorrencia } = value
                return {
                    id: ocorrencia.getId(),
                    social_name: cadastro.getRazaoSocial(),
                    client: ocorrencia.getCliente(),
                    responsible: ocorrencia.getResponsavel(),
                    damage: ocorrencia.getDanoReclamado(),
                    items: listaPecas.listaNomePecas,
                    brands: listaMarcas.listaNomeMarcas
                }
            })

            setFiltrado(listaTabela)
            setListaOcorrenciasCompleta(listaTabela)
        } else {
            setFiltrado([])
            setListaOcorrenciasCompleta([])
        }
    }, [resposta])

    useEffect(() => {
        if(campo === "CódigoAT" && valor !== "") {
            const novaLista = retornaListaMatchPorListaCampo(listaOcorrenciasCompleta, 'id', valor)
            setFiltrado(novaLista)
        }
        if(campo === "Associado" && valor !== "") {
            const novaLista = retornaListaMatchPorListaCampo(listaOcorrenciasCompleta, 'social_name', valor)
            setFiltrado(novaLista)
        }
        if(campo === "Dano reclamado" && valor !== "") {
            const novaLista = retornaListaMatchPorListaCampo(listaOcorrenciasCompleta, 'damage', valor)
            setFiltrado(novaLista)
        }
        if(campo === "Cliente" && valor !== "") {
            const novaLista = retornaListaMatchPorListaCampo(listaOcorrenciasCompleta, 'client', valor)
            setFiltrado(novaLista)
        }
        if(campo === "Peças" && valor !== "") {
            const novaLista = retornaListaMatchPorListaCampo(listaOcorrenciasCompleta, 'items', valor)
            setFiltrado(novaLista)
        }
        if(campo === "Marcas" && valor !== "") {
            const novaLista = retornaListaMatchPorListaCampo(listaOcorrenciasCompleta, 'brands', valor)
            setFiltrado(novaLista)
        }
        if(campo === "" || valor === "") {
            setFiltrado(listaOcorrenciasCompleta)
        }
    }, [campo, valor, listaOcorrenciasCompleta]) 

    return (
        <>
            <MainTitle tituloPrincipal={true} titulo="Buscar ocorrência por campo" />
            <MatchFilter
                itensDoSelect={['CódigoAT', 'Associado', 'Dano reclamado', 'Cliente', 'Peças', 'Marcas']}>
            </MatchFilter>
            <OcorrenciasUsuario ocorrencias={filtrado} onClick={(e) => { return }}>
            </OcorrenciasUsuario>
        </>
        
    )
}