import { useCallback, useState } from "react";
import http from "../../../../http";

import OcorrenciaOutput from '../../models/ocorrencia/OcorrenciaOutput'
import UsuarioOutput from "../../../usuario/models/UsuarioOutput";
import CadastroOutput from "../../../associado/models/CadastroOutput";
import MarcasOutput from "../../models/marcas/MarcasOutput";
import PecasOutput from '../../models/pecas/PecasOutput'

export const useBuscaMinhasOcorrencias = () => {
    const [resposta, setResposta] = useState({})

    const buscaMinhasOcorrencias = useCallback(() => {
        http.get('/minhas-ocorrencias')
        .then(res => {
            const respostaRaw = res.data
            const retorno = respostaRaw.map(ocorrencia => {
                
                return {
                    ocorrencia: OcorrenciaOutput.create(ocorrencia),
                    cadastro: CadastroOutput.create(ocorrencia.Cadastro),
                    usuario: UsuarioOutput.create(ocorrencia.Cadastro.Usuario),
                    listaPecas: PecasOutput.create(ocorrencia.Items),
                    listaMarcas: MarcasOutput.create(ocorrencia.Brands),

                }
            })
            setResposta({ data: retorno, error: null })
        })
        .catch(error => {
            const { response } = error
            setResposta({data: null, error: response})
        })
    }, [])

    return [resposta, buscaMinhasOcorrencias]
    
}