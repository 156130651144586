import { useDispatch, useSelector } from 'react-redux';
import EditItem from '../../../../shared/components/IconButtons/EditItem';
import CampoInputTexto from '../../../../shared/components/CampoInputComButton/CampoInputTexto/CampoInputTexto';
import { handleOcorrenciaChange } from '../../../../../store/reducers/ocorrenciaSlice';
import { useEffect } from 'react';

const CampoInputObservacao = ({ onClick, editButton, disabled }) => {

    const dispatch = useDispatch()
    const { observation } = useSelector(state => state.ocorrenciaState)
    
    const handleChange = (name, value) => {
        dispatch(handleOcorrenciaChange({[name]: value}))
    }

    const handleClick = (e) => {
        e.preventDefault()
        onClick("observation")
    }

    useEffect(() => {
        if(!observation) dispatch(handleOcorrenciaChange({solution: ""}))
    }, [observation, dispatch])

    return (
        <CampoInputTexto
            id="inputObservacao"
            titulo="Observação"
            onChange={handleChange}
            name="observation"
            value={observation ? observation : ""}
            disabled={disabled}
            multiline
            maxRows={5}
        >
            {
                editButton ? <EditItem onClick={handleClick} /> : <></>
            }
        </CampoInputTexto>
    )
}

export default CampoInputObservacao
