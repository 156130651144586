import React from 'react'
import * as S from './style'

export default function FormInputTexto(props) {
    return (
        <>
            <S.ContainerComButton>
                <S.InputText 
                    data-testid={props.id}
                    variant="outlined" 
                    size="small" 
                    {...props}
                />
                <S.ContainerIcon>
                    { props.children && (<>{props.children}</>) }
                </S.ContainerIcon>
            </S.ContainerComButton>
        </>
    )
}