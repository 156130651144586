import { useCallback, useState } from "react"
import http from "../../../../http"

export const useLogout = () => {
    const [resposta, setResposta] = useState({})

    const logout = useCallback(() => {
        http.get('/logout')
            .then(res => {
                localStorage.removeItem("Authorization")
                localStorage.removeItem("RefreshToken")
                setResposta({})
            })
            .catch(error => {
                const { response } = error
                setResposta({data: null, error: response})
            })
    }, [])
    return [resposta, logout]
}