import PecaInput from "./PecaInput"

export default class PecaOutput extends PecaInput {
    constructor (peca) {
        super(peca.name)
        this.id = peca.id
    }
    
    static create(peca) {
        return new PecaOutput(peca)
    }
}