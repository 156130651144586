import { useCallback, useState } from "react";
import http from "../../../../http";

export const useBuscaCep = () => {
    const [resposta, setResposta] = useState({})

    const buscarCep = useCallback((cep) => {
        if(cep.length === 8) {
            http.get(`endereco/${cep}`)
                .then(res => {
                    const resposta = res.data
                    setResposta({ data: resposta, error: null })
                })
                .catch(error => {
                    setResposta({ data: null, error })
                })
        }
        else {
            setResposta({})
        }
    }, [])

    return [resposta, buscarCep]
}