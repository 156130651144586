import React from 'react'
import TabelaSimples from '../../../../components/TabelaSimples';

export default function TabelaMeusEstabelecimentos(props) {

    function createData(social_name, cnpj, owner, id, ativo) {
        return {
            social_name,
            cnpj,
            owner,
            ativo,
            id
        };
    }
    
    const headCells = [

        {
            id: 'social_name',
            numeric: false,
            disablePadding: false,
            label: 'Razão Social',
        },
        {
            id: 'cnpj',
            numeric: false,
            disablePadding: false,
            label: 'CNPJ',
        },
        {
            id: 'owner',
            numeric: false,
            disablePadding: false,
            label: 'Proprietário',
        },
        {
            id: 'ativo',
            numeric: false,
            disablePadding: false,
            label: 'Ativo',
        },
    ];
    
    const valueCells = [

        {
            value: 'social_name',
            alignment: 'left'
        },
        {
            value: 'cnpj',
            alignment: 'left'
        },
        {
            value: 'owner',
            alignment: 'left'
        },
        {
            value: 'ativo',
            alignment: 'left'
        },
    ]

    const linhas = props.cadastros.map(value => createData(value.social_name, value.cnpj, value.owner, value.id, value.ativo))

    return (
        <TabelaSimples 
            headCells={headCells} 
            linhas={linhas} 
            valueCells={valueCells} 
            onClick={props.onClick} 
            titulo={props.tituloToolbar}>
            {props.children}
        </TabelaSimples>
    )
}