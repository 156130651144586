export default class CadastroInput {
    constructor(dadosCadastro) {
        this.cnpj = dadosCadastro.cnpj 
        this.social_name = dadosCadastro.social_name 
        this.fantasy_name = dadosCadastro.fantasy_name 
        this.state_insc = dadosCadastro.state_insc 
        this.foundation_date = dadosCadastro.foundation_date 
        this.cep = dadosCadastro.cep 
        this.numeroEndereco = dadosCadastro.numeroEndereco 
        this.complemento = dadosCadastro.complemento 
        this.employees_number = dadosCadastro.employees_number
        this.phone1 = dadosCadastro.phone1
        this.phone2 = dadosCadastro.phone2
        this.site = dadosCadastro.site
        this.contato = dadosCadastro.contato
        this.cargo = dadosCadastro.cargo
        this.owner = dadosCadastro.owner 
        this.washer_segment = dadosCadastro.washer_segment
        this.franchise = dadosCadastro.franchise
        this.associado = `${dadosCadastro.cnpj} - ${dadosCadastro.social_name}`
    }


    static create (cadastro) {
        if(!CadastroInput.validate) {
            throw new Error('Dados de cadastro inconsistentes')
        }
        return new CadastroInput(cadastro)
    }

    static validate (cadastro) {
        if(!cadastro.id) {
            return false
        }
        return true
    }

    getAssociado() {
        return this.associado
    }

    getCnpj() {
        return this.cnpj
    }

    getRazaoSocial() {
        return this.social_name
    }

    getNomeFantasia() {
        return this.fantasy_name
    }

    getInscricaoEstadual() {
        return this.state_insc
    }

    getDataFundacao() {
        return this.foundation_date
    }

    getCep() {
        return this.cep
    }

    getNumeroEndereco() {
        return this.numeroEndereco
    }

    getComplemento() {
        return this.complemento
    }

    getNumeroFuncionarios() {
        return this.employees_number
    }

    getTelefone() {
        return this.phone1
    }

    getTelefone2() {
        return this.phone2
    }

    getSite() {
        return this.site
    }

    getContato() {
        return this.contato
    }

    getCargo() {
        return this.cargo
    }

    getNumeroEstabelecimento() {
        return this.store_number
    }

    getProprietario() {
        return this.owner
    }

    getSegmentoLavanderia() {
        return this.washer_segment
    }

    getRedeFranquia() {
        return this.franchise
    }

}

