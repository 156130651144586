import { Paper } from '@mui/material'

import React from 'react'
import { useBuscaListaCadastrosAtivos } from '../../modules/associado/hooks/useBuscaListaCadastrosAtivos';
import { useDesativaCadastros } from '../../modules/associado/hooks/useDesativaCadastros';
import MainTitle from '../../modules/shared/components/MainTitle';
import { useDisplayAlertAfterServerResponse } from '../../modules/shared/hooks/useDisplayAlertAfterServerResponse/useDisplayAlertAfterServerResponse';
import FilterAssociadoTable from '../../modules/associado/components/FilterAssociadoTable/FilterAssociadoTable';
import BlockItem from '../../modules/shared/components/IconButtons/BlockItem'

export default function BloquearAcessos() {
    
    const { listaCadastrosAtivos } = useBuscaListaCadastrosAtivos()
    const [resposta, desativaCadastros] = useDesativaCadastros()
    useDisplayAlertAfterServerResponse(resposta)

    const handleClick = (listaSelecionados) => (e) => {
        e.preventDefault()
        desativaCadastros(listaSelecionados) 
    }

    return (
        <Paper>
            <MainTitle titulo="Bloquear acessos" tituloPrincipal={true} />
            <FilterAssociadoTable onClick={handleClick} listaAssociados={listaCadastrosAtivos} tituloToolbar="Cadastros ativos">
                <BlockItem />
            </FilterAssociadoTable>
        </Paper>
    )
}