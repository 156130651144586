import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';

import Logo from '../Logo/Logo';
import AdminAccordion from '../AccordionCustomizado/AdminAccordion/AdminAccordion';
import LoginAccordion from '../AccordionCustomizado/LoginAccordion/LoginAccordion';
import { toggleSideBar } from '../../../../store/reducers/estadoMobileSlice';
import UserAccordion from '../AccordionCustomizado/UserAccordion/UserAccordion';

const drawerWidth = 240;

export default function SideDrawer({children, permissao, window}) {
  const [accordion, setAccordion] = useState(<></>)

  const { estado } = useSelector(state => state.mobileState)
  const dispatch = useDispatch()
  const resolverSideBar = () => {
    dispatch(toggleSideBar())
  }
  
  useEffect(() => {
    if(permissao === 2) {
      setAccordion(<UserAccordion />)
    } else if(permissao === 3) {
      setAccordion(<AdminAccordion />)
    } else {
      setAccordion(<LoginAccordion />)
    }
  }, [permissao])

  const drawer = (
    <>
      <Logo />
      <Divider />
      {accordion}
    </>
  )

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders"
      >
        {estado && (<Drawer
          variant="temporary"
          container={container}
          open={estado}
          onClose={resolverSideBar}
          anchor="left"
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>)}
        {!estado && (<Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', md: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>)}
      </Box>

      <Box
        component="main"
        // sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
        sx={{ flexGrow: 1, p: 3, display: 'flex', flexDirection: 'column', width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        {children}
      </Box>
    </Box>
  );
}