import styled from 'styled-components'

export const ContainerRadio = styled.div`
    max-width: 420px;
    width: 100%;
`
export const ContainerComButton = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`

export const ContainerIcon = styled.div`
    align-self: flex-end;
    margin-left: 20px;
    margin-right: 20px;

`

