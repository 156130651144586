import { Paper } from '@mui/material'
import React, { useEffect } from 'react'

import MainTitle from "../../modules/shared/components/MainTitle"
import { useDisplayAlertAfterServerResponse } from '../../modules/shared/hooks/useDisplayAlertAfterServerResponse/useDisplayAlertAfterServerResponse'
import { resetAssociado } from '../../store/reducers/associadoSlice'
import { resetAssociadoSelecionado } from '../../store/reducers/associadoSelecionadoSlice'
import { resetLocalizacao } from '../../store/reducers/localizacaoSlice'
import { resetOcorrencia } from '../../store/reducers/ocorrenciaSlice'
import { resetOcorrenciaSelecionada } from '../../store/reducers/ocorrenciaSelecionadaSlice'
import { resetUsuario } from '../../store/reducers/usuarioSlice'
import { useDispatch } from 'react-redux'
import FormCadastraEstabelecimentoADM from '../../modules/associado/components/FormCadastraEstabelecimentoADM/FormCadastraEstabelecimentoADM'
import { useCadastraEstabelecimentoADM } from '../../modules/associado/hooks/useCadastraEstabelecimentoADM'
import { useBuscaListaEmails } from '../../modules/associado/hooks/useBuscaListaEmails'
import { resetEmailAssociadoSelecionado } from '../../store/reducers/emailAssociadoSelecionadoSlice'

export default function CadastrarEstabelecimentoADM() {
    const { listaUsuarios } = useBuscaListaEmails()
    const [resposta, cadastrarEstabelecimentoADM] = useCadastraEstabelecimentoADM()
    useDisplayAlertAfterServerResponse(resposta)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(resetAssociado())
        dispatch(resetAssociadoSelecionado())
        dispatch(resetLocalizacao())
        dispatch(resetOcorrencia())
        dispatch(resetOcorrenciaSelecionada())
        dispatch(resetUsuario())
        dispatch(resetEmailAssociadoSelecionado())
    }, [dispatch])

    return (
        <Paper>
            <MainTitle titulo={"Cadastrar estabelecimento"} tituloPrincipal={true} />
            <FormCadastraEstabelecimentoADM listaAssociados={listaUsuarios} aoSubmeterForm={cadastrarEstabelecimentoADM} editButton={true} />
        </Paper>
    )
}