import styled from 'styled-components'

export const ContainerBotao = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    padding-top: 20px;
    @media (max-width: 950px) {
        grid-template-columns: 1fr;
        gap: 20px;
        padding-top: 0px;
    }
`
