import React from "react";
import { Routes, Route } from 'react-router-dom'

import Inicial from '../paginas/Inicial'
import VisualizaLaudos from '../paginas/VisualizaLaudos'
import OcorrenciasCampo from '../paginas/OcorrenciasCampo'
import Relatorios from '../paginas/Relatorios'
import DesativarAdministradores from '../paginas/DesativarAdministradores'
import DadosDeAssociados from '../paginas/DadosDeAssociados'
import Login from "../paginas/Login";
import CadastrarOcorrencia from "../paginas/CadastrarOcorrencia";
import EditarOcorrencia from "../paginas/EditarOcorrencia";
import CadastrarAssociado from "../paginas/CadastrarAssociado";
import EditarAssociado from "../paginas/EditarAssociado";
import BloquearAcessos from "../paginas/BloquearAcessos";
import LiberarAcessos from "../paginas/LiberarAcessos";
import CadastrarAdministrador from "../paginas/CadastrarAdministrador";
import AtivarAdministradores from "../paginas/AtivarAdministradores";
import CadastrarLaudo from "../paginas/CadastrarLaudo";
import EditarLaudo from "../paginas/EditarLaudo";
import MeusDados from "../paginas/MeusDados";
import AlterarSenha from "../paginas/AlterarSenha";
import CadastrarCampo from "../paginas/CadastrarCampo";
import ContainerPaginaComSideBar from "../paginas/shared/ContainerPaginaComSideBar/ContainerPaginaComSideBar";
import MeusEstabelecimentos from "../paginas/MeusEstabelecimentos";
import CadastrarEstabelecimento from "../paginas/CadastrarEstabelecimento";
import EditarEstabelecimento from "../paginas/EditarEstabelecimento";
import RecuperarSenha from "../paginas/RecuperarSenha";
import EmailValidado from "../paginas/EmailValidado";
import CadastrarEstabelecimentoADM from "../paginas/CadastrarEstabelecimentoADM";
import CadastrarAssociadoADM from "../paginas/CadastrarAssociadoADM";


export default function Rotas() {
    

    return (
        <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/sistema/Entrar" element={<Login />} />
            <Route exact path="/validate-email/:token" element={<EmailValidado />} />
            <Route exact path="/recuperar-senha/:token" element={<RecuperarSenha />} />
            
            <Route element={<ContainerPaginaComSideBar />}>
                <Route exact path="/meus-dados" element={<MeusDados />} />
                <Route exact path="/meus-estabelecimentos" element={<MeusEstabelecimentos />} />
                <Route exact path="/cadastrar-estabelecimento" element={<CadastrarEstabelecimento />} />
                <Route exact path="/editar-estabelecimento" element={<EditarEstabelecimento />} />
                <Route exact path="/alterar-senha" element={<AlterarSenha />} />
                <Route exact path="/inicial" element={<Inicial />} />
                <Route exact path="/laudos" element={<VisualizaLaudos />} />
                <Route exact path="/cadastrar-campo" element={<CadastrarCampo />} />
                <Route exact path="/cadastrar-laudo" element={<CadastrarLaudo />} />
                <Route exact path="/editar-laudo" element={<EditarLaudo />} />
                <Route exact path="/ocorrencias-campo" element={<OcorrenciasCampo />} />
                <Route exact path="/dados-associados" element={<DadosDeAssociados />} />
                <Route exact path="/cadastrar-ocorrencia" element={<CadastrarOcorrencia />} />
                <Route exact path="/editar-ocorrencia" element={<EditarOcorrencia />} />
                <Route exact path="/relatorios" element={<Relatorios />} />
                <Route exact path="/desativar-administradores" element={<DesativarAdministradores />} />
                <Route exact path="/ativar-administradores" element={<AtivarAdministradores />} />
                <Route exact path="/cadastrar-administradores" element={<CadastrarAdministrador />} />
                <Route exact path='/cadastrar-associados' element={<CadastrarAssociado />} />
                <Route exact path="/admin/cadastrar-associados" element={<CadastrarAssociadoADM />} />
                <Route exact path="/adiciona-estabelecimento" element={<CadastrarEstabelecimentoADM />} />
                <Route exact path="/editar-associados" element={<EditarAssociado />} />
                <Route exact path="/bloquear-acessos" element={<BloquearAcessos />} />
                <Route exact path="/liberar-acessos" element={<LiberarAcessos />} />
            </Route>

        </Routes>
    )
}