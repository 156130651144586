export default class Associado {
    constructor(dadosCadastro) {
        const idUser = dadosCadastro['Usuario.id']
        this.id = dadosCadastro.id 
        this.associado = dadosCadastro.associado 
        this.usuario_id = idUser
    }

    static create (cadastro) {
        if(!Associado.validate) {
            throw new Error('Dados de cadastro inconsistentes')
        }
        return new Associado(cadastro)
    }

    static validate (cadastro) {
        if(!cadastro.id) {
            return false
        }
        return true
    }

    getAssociado() {
        return this.associado
    }

    getCadastroId() {
        return this.id
    }
}

