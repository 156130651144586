
import { useDispatch, useSelector } from 'react-redux';

import EditItem from '../../../../shared/components/IconButtons/EditItem';
import CampoInputTexto from '../../../../shared/components/CampoInputComButton/CampoInputTexto/CampoInputTexto';
import { handleUsuarioChange } from '../../../../../store/reducers/usuarioSlice';


const CampoInputSenha = ({ onClick, editButton, disabled }) => {

    const dispatch = useDispatch()
    const { senha } = useSelector(state => state.usuarioState)
    
    const handleChange = (name, value) => {
        dispatch(handleUsuarioChange({[name]: value}))
    }
    const handleClick = (e) => {
        e.preventDefault()
        onClick("senha")
    }

    return (
        <CampoInputTexto
            id="inputSenha"
            titulo="Senha*"
            onChange={handleChange}
            name="senha"
            value={senha}
            label="obrigatório"
            type="password"
            required={true}
            disabled={disabled}
        >
            {
                editButton ? <EditItem onClick={handleClick} /> : <></>
            }
        </CampoInputTexto>
    )
}

export default CampoInputSenha
