import { Paper } from "@mui/material"
import MainTitle from "../../modules/shared/components/MainTitle"
import { useState } from "react"
import { useAlteraSenha } from "../../modules/usuario/hooks/useAlteraSenha/useAlteraSenha"
import { useDisplayAlertAfterServerResponse } from "../../modules/shared/hooks/useDisplayAlertAfterServerResponse/useDisplayAlertAfterServerResponse"
import ContainerTitleInputField from "../../components/Containers/ContainerTitleInputField"
import FormInputTexto from "../../components/FormInputTexto"
import Botao from "../../components/Botao"


export default function AlterarSenha() {

    const [senhaAntiga, setSenhaAntiga] = useState("")
    const [novaSenha, setNovaSenha] = useState("")
    const [confirmaNovaSenha, setConfirmaNovaSenha] = useState("")
    const [resposta, alteraSenha] = useAlteraSenha()
    useDisplayAlertAfterServerResponse(resposta)

    const aoSubmeterFormulario = (e) => {
        e.preventDefault()
        const alterarSenha = {
            senha: senhaAntiga,
            novaSenha,
            confirmaNovaSenha
        }
        alteraSenha(alterarSenha)
    }

    const handleChange = (name) => (e) => {
        name(e.target.value)
    }

    return (
        <Paper>
            <MainTitle titulo="Alterar senha" tituloPrincipal />
            <form onSubmit={aoSubmeterFormulario}>
                <ContainerTitleInputField titulo="Senha anterior" >
                    <FormInputTexto
                        value={senhaAntiga}
                        onChange={handleChange(setSenhaAntiga)}
                        required={true}
                        label={"Senha anterior"} 
                        type="password" />
                </ContainerTitleInputField>
                <ContainerTitleInputField titulo="Nova senha" >
                    <FormInputTexto
                        value={novaSenha}
                        onChange={handleChange(setNovaSenha)}
                        required={true}
                        label={"Nova senha"} 
                        type="password" />
                </ContainerTitleInputField>
                <ContainerTitleInputField titulo="Confirma nova senha" >
                    <FormInputTexto
                        value={confirmaNovaSenha}
                        onChange={handleChange(setConfirmaNovaSenha)}
                        required={true}
                        label={"Confirma nova senha"}
                        type="password" />
                </ContainerTitleInputField>
                <ContainerTitleInputField botao>
                    <Botao titulo="Alterar senha" type="submit" />
                </ContainerTitleInputField>
            </form>
        </Paper>
    )
}