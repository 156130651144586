import { useCallback, useState } from "react";
import http from "../../../../http";

import OcorrenciaOutput from '../../models/ocorrencia/OcorrenciaOutput'
import MarcasOutput from "../../models/marcas/MarcasOutput";
import PecasOutput from '../../models/pecas/PecasOutput'

export const useBuscaOcorrenciasParaAssociados = () => {
    const [resposta, setResposta] = useState({})

    const buscaOcorrencias = useCallback(() => {
        http.get('/ocorrencias-dados')
        .then(res => {
            const respostaRaw = res.data
            const retorno = respostaRaw.map(ocorrencia => {
                return {
                    ocorrencia: OcorrenciaOutput.create(ocorrencia),
                    listaPecas: PecasOutput.create(ocorrencia.Items),
                    listaMarcas: MarcasOutput.create(ocorrencia.Brands),
                }
            })
            setResposta({ data: retorno, error: null })
        })
        .catch(error => {
            const { response } = error
            setResposta({data: null, error: response})
        })
    }, [])

    return [resposta, buscaOcorrencias]
    
}