import React from 'react'
import * as S from './style'
import ContainerItemGenerico from './ContainerItemGenerico'

export default function ContainerTitleInputField(props) {
    return (
        <ContainerItemGenerico>
            <S.ContainerItemCadastro>
                <S.ItemTitle botao={props.botao}>{props.titulo}</S.ItemTitle>
                <S.ContainerComButton>
                {props.children}
                </S.ContainerComButton>
            </S.ContainerItemCadastro>
        </ContainerItemGenerico>
    )
}