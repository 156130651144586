import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleAssociadoSelecionadoChange, resetAssociadoSelecionado } from '../../../../../store/reducers/associadoSelecionadoSlice';
import CampoInputAutocomplete from '../../../../shared/components/CampoInputComButton/CampoInputAutocomplete/CampoInputAutocomplete';
import EditItem from '../../../../shared/components/IconButtons/EditItem';
import VoidIcon from '../../../../shared/components/IconButtons/VoidIcon/VoidIcon';
import { resetAssociado } from '../../../../../store/reducers/associadoSlice';
import { resetUsuario } from '../../../../../store/reducers/usuarioSlice';
import { resetLocalizacao } from '../../../../../store/reducers/localizacaoSlice';

const CampoInputAssociado = ({ editButton, disabled, listaAssociados, onClick }) => {

    const dispatch = useDispatch()

    const associadoSelecionado = useSelector(state => state.associadoSelecionadoState)

    const handleChange = (name, value) => {
        if(value) {
            const { id, associado, usuario_id } = value
            dispatch(handleAssociadoSelecionadoChange({id, associado, usuario_id}))
        } else {
            dispatch(resetAssociadoSelecionado())
            dispatch(resetAssociado())
            dispatch(resetUsuario())
            dispatch(resetLocalizacao())
        }
    }

    const handleClick = (e) => {
        e.preventDefault()
        onClick("associado")
    }

    return (
        <CampoInputAutocomplete 
            titulo="Associado*" 
            id="formAutocompleteAssociado" 
            name="associado" 
            disabled={disabled}
            getOptionLabel={option => option.associado}
            renderOption={(props, option, state) => {
                return [props, option.associado, state.id]
            }}
            onChange={handleChange}
            value={associadoSelecionado.id === "" ? null : associadoSelecionado}
            isOptionEqualToValue={(option, value) => option.associado === value.associado}
            listaAutocomplete={listaAssociados} >
            {
                editButton === "void" ? <VoidIcon /> : <></>
            }
            {
                editButton === "edit" ? <EditItem onClick={handleClick} /> : <></>
            }
        </CampoInputAutocomplete>
    )
}

export default CampoInputAssociado
