import { useCallback, useState } from "react"
import http from "../../../../http"
import CadastroInput from "../../models/CadastroInput"

export const useCadastraEstabelecimento = () => {
    const [resposta, setResposta] = useState({})

    const cadastrarEstabelecimento = useCallback((inputsAssociado) => {
        const dadosCadastro = CadastroInput.create(inputsAssociado)
        http.post(`/estabelecimento`, dadosCadastro)
            .then(res => {
                setResposta({ data: res.data, error: null })
            }) 
            .catch(error => {
                const { response } = error
                const { data } = response
                const { erro } = data
                setResposta({ data: null, error: { data: erro } })
            })
    }, [])
    return [resposta, cadastrarEstabelecimento]
}