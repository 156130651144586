import { createGlobalStyle } from 'styled-components'
import styled from 'styled-components'

export const GlobalStyle = createGlobalStyle`
    * {
        font-family: 'Poppins', sans-serif;
        color: #333;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
`
export const Container = styled.div`
    display: flex;
    flex-direction: column;
`

export const Content = styled.div`
    margin-top: 64px;
    display: flex;
    height: 600px;
    align-items: center;
    justify-content: center;
`
