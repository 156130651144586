import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import EditItem from '../../../../shared/components/IconButtons/EditItem';
import CampoInputTexto from '../../../../shared/components/CampoInputComButton/CampoInputTexto/CampoInputTexto';
import { handleUsuarioChange } from '../../../../../store/reducers/usuarioSlice';

const CampoInputEmail = ({ onClick, editButton, disabled }) => {

    const dispatch = useDispatch()
    const { email } = useSelector(state => state.usuarioState)

    const handleChange = (name, value) => {
        dispatch(handleUsuarioChange({[name]: value}))
    }
    const handleClick = (e) => {
        e.preventDefault()
        onClick("email")
    }

    return (
        <CampoInputTexto
            id="inputEmail"
            titulo="E-mail*"
            onChange={handleChange}
            name="email"
            value={email}
            label="obrigatório"
            required={true}
            disabled={disabled}
        >
            {
                editButton ? <EditItem onClick={handleClick} /> : <></>
            }
        </CampoInputTexto>
    )
}

export default memo(CampoInputEmail)
