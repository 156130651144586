import React, { useEffect } from 'react'

import Botao from '../../../../components/Botao'
import ContainerTitleInputField from '../../../../components/Containers/ContainerTitleInputField'
import { retornaSomenteNumerosDeString } from '../../../../Utilitarios'
import CamposCadastraAssociado from '../CamposCadastraAssociado/CamposCadastraAssociado'
import { useDispatch, useSelector } from 'react-redux'
import { resetAssociado } from '../../../../store/reducers/associadoSlice'
import { resetUsuario } from '../../../../store/reducers/usuarioSlice'
import { resetLocalizacao } from '../../../../store/reducers/localizacaoSlice'

const FormCadastraAssociado = ({ aoSubmeterForm, onClick }) => {

    const dadosAssociado = useSelector(state => state.associadoState)
    const dadosUsuario = useSelector(state => state.usuarioState)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(resetAssociado())
        dispatch(resetUsuario())
        dispatch(resetLocalizacao())
    }, [dispatch])
    
    const handleClick = (name) => {
        onClick(name)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        const envioValores = {
            ...dadosAssociado,
            ...dadosUsuario,
            cep: retornaSomenteNumerosDeString(dadosAssociado.cep),
            phone1: retornaSomenteNumerosDeString(dadosAssociado.phone1)
        }
        aoSubmeterForm(envioValores)
    }

    return (
        <form method="post" onSubmit={onSubmit}>
            <CamposCadastraAssociado editButton={false} onClick={handleClick} />
            <ContainerTitleInputField titulo="" botao>
                <Botao titulo="Cadastrar associado" type="submit" />
            </ContainerTitleInputField>
        </form>
    )
}

export default FormCadastraAssociado