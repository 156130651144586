import { useDispatch, useSelector } from 'react-redux';
import EditItem from '../../../../shared/components/IconButtons/EditItem';
import { handleAssociadoChange } from '../../../../../store/reducers/associadoSlice';
import CampoInputTexto from '../../../../shared/components/CampoInputComButton/CampoInputTexto/CampoInputTexto';

const CampoInputProprietario = ({ onClick, editButton, disabled }) => {

    const dispatch = useDispatch()
    const { owner } = useSelector(state => state.associadoState)
    
    const handleChange = (name, value) => {
        dispatch(handleAssociadoChange({[name]: value}))
    }

    const handleClick = (e) => {
        e.preventDefault()
        onClick("owner")
    }

    return (
        <CampoInputTexto
            id="inputProprietario"
            titulo="Nome proprietário*"
            onChange={handleChange}
            name="owner"
            value={owner}
            label="obrigatório"
            required={true}
            disabled={disabled}
        >
            {
                editButton ? <EditItem onClick={handleClick} /> : <></>
            }
        </CampoInputTexto>
    )
}

export default CampoInputProprietario
