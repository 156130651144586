import { useCallback, useState } from "react"
import http from "../../../../http"

export const useSolicitaRecuperacaoSenha = () => {
    const [resposta, setResposta] = useState({})

    const solicitaRecuperacaoSenha = useCallback((email) => {
        http.post(`/recupera-senha`, { email })
            .then(res => {
                if(res.statusCode === 200) {
                    setResposta({ data: "Email enviado com sucesso", error: null })
                }
                setResposta({ data: "Algo de inesperado aconteceu", error: null })
            })
            .catch(error => {
                const { response } = error
                setResposta({data: null, error: response})
            })
    }, [])
    return [resposta, solicitaRecuperacaoSenha]
}