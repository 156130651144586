import CadastroInput from "./CadastroInput"

export default class CadastroInputADM extends CadastroInput {
    constructor(dadosCadastro) {
        const {usuario_id, ...args} = dadosCadastro
        super(args)
        this.usuario_id = usuario_id
    }

    static create (cadastro) {
        if(!CadastroInputADM.validate) {
            throw new Error('Dados de cadastro inconsistentes')
        }
        return new CadastroInputADM(cadastro)
    }

    static validate (cadastro) {
        if(!cadastro.usuario_id) {
            return false
        }
        return true
    }

    getUsuario_id() {
        return this.usuario_id
    }

}

