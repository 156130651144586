import { createSlice } from "@reduxjs/toolkit"

const estadoInicial = {
    logradouro: "", 
    bairro: "",
    cidade: "",
    estado: ""
}

const localizacaoSlice = createSlice({
    name: 'dadosLocalizacao',
    initialState: estadoInicial,
    reducers: {
        handleLocalizacaoChange: (state, action) => {
            return {...state, ...action.payload }
        },
        resetLocalizacao: (state) => {
            return estadoInicial;
        }
        
    }
})

export const { handleLocalizacaoChange, resetLocalizacao } = localizacaoSlice.actions

export default localizacaoSlice.reducer