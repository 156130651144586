import { useEffect, useState } from "react";
import http from "../../../../http";

export const useBuscaAdministradoresDesativados = () => {
    const [listaAdministradoresDesativados, setListaAdministradoresDesativados] = useState([])
    
    useEffect(() => {
        http.get(`/administradores-desativados`)
            .then(res => {
                const resposta = res.data
                setListaAdministradoresDesativados(resposta)
            })
    }, [])

    return {
        listaAdministradoresDesativados,
    }
}