import styled from 'styled-components'

export const ContainerCards = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 150px) ;
    padding: 20px;
    gap: 20px;
    border-radius: 5px;
    width: 100%;
    align-self: center;
    @media (min-width: 1300px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(2, 150px);
        max-width: 1450px;
    }
`