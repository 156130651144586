import React, { useEffect } from "react"
import { Paper } from "@mui/material"
import MainTitle from "../../modules/shared/components/MainTitle"
import FormLaudos from "../../modules/ocorrencia/components/FormLaudos/FormLaudos"
import { useBuscaOcorrenciaPeloId } from "../../modules/ocorrencia/hooks/useBuscaOcorrenciaPeloId"
import { useBuscaListaDeIdsDeOcorrenciasSemLaudo } from "../../modules/ocorrencia/hooks/useBuscaListaDeIdsDeOcorrenciasSemLaudo"
import { useCadastraLaudo } from "../../modules/ocorrencia/hooks/useCadastraLaudo"
import { useDispatch } from "react-redux"
import { resetOcorrencia } from "../../store/reducers/ocorrenciaSlice"
import { resetOcorrenciaSelecionada } from "../../store/reducers/ocorrenciaSelecionadaSlice"
import { resetAssociadoSelecionado } from "../../store/reducers/associadoSelecionadoSlice"

export default function CadastrarLaudo() {
    
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(resetOcorrenciaSelecionada())
        dispatch(resetAssociadoSelecionado())
        dispatch(resetOcorrencia())
    }, [dispatch])

    const { listaIdsDeOcorrenciaSemLaudo } = useBuscaListaDeIdsDeOcorrenciasSemLaudo()

    return (
        <Paper>
            <MainTitle titulo={"Enviar um novo laudo"} tituloPrincipal={true}/>
            <FormLaudos 
                listaParaAutocomplete={listaIdsDeOcorrenciaSemLaudo}
                useBuscaOcorrenciaParaCamposPreenchidos={useBuscaOcorrenciaPeloId} 
                useOnSubmitForm={useCadastraLaudo}
            />
        </Paper>
    )
}