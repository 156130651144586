import { createSlice } from "@reduxjs/toolkit"

const estadoInicial = {
    usuario_id: "",
    items: [],
    brands: [],
    wash_instruct: "",
    wash_used: "",
    damage: "",
    ocurrence_date: "",
    fiber_type: "",
    iron_clothes: "",
    dry_dryer: "", 
    insurance: "",
    tests: "", 
    cep: "",
    responsible: "",
    technician: "",
    client: "",
    ticket: "",
    observation: "", 
    routing: "",
    complemento: "",
    solution: "" 
}

const ocorrenciaSlice = createSlice({
    name: 'dadosOcorrencia',
    initialState: estadoInicial,
    reducers: {
        handleOcorrenciaChange: (state, action) => {
            return {...state, ...action.payload }
        },
        resetOcorrencia: (state) => {
            return estadoInicial;
        }
        
    }
})

export const { handleOcorrenciaChange, resetOcorrencia } = ocorrenciaSlice.actions

export default ocorrenciaSlice.reducer