import PecaOutput from "./PecaOutput"

export default class PecasOutput {
    constructor (pecas) {
        this.items = pecas.map(peca => PecaOutput.create(peca)) 
    }

    static create(ListaPecas) {
        return new PecasOutput(ListaPecas)
    }

    get listaPecas() {
        return this.items.map(item => {
            const { id, name } = item 
            return {
                id,
                name
            }
        })
    }

    get listaIdPecas() {
        return this.items.map(item => item.id)
    }

    get listaNomePecas() {
        return this.items.map(item => item.name)
    }

    
}