import { Paper } from "@mui/material";
import MainTitle from "../../modules/shared/components/MainTitle"
import { useDisplayAlertAfterServerResponse } from "../../modules/shared/hooks/useDisplayAlertAfterServerResponse/useDisplayAlertAfterServerResponse";
import FormCadastroAdministrador from "../../modules/usuario/components/FormCadastroAdministrador/FormCadastroAdministrador";
import { useCadastraAdministrador } from '../../modules/usuario/hooks/useCadastraAdministrador'

export default function CadastrarAdministrador() {

    const [resposta, cadastraAdministrador] = useCadastraAdministrador()
    useDisplayAlertAfterServerResponse(resposta)

    return (
        <Paper>
            <MainTitle titulo={"Cadastrar administrador"} tituloPrincipal={true} />
            <FormCadastroAdministrador aoSubmeterForm={cadastraAdministrador} editButton={false} />
        </Paper>
    )
}