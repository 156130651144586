import React, { useEffect, useState } from 'react'
import TabelaSimples from '../../../../components/TabelaSimples/index'

function concatenaLista(lista) {
    let listaItens = ""
    lista.forEach((value, index) => {
        if (index === 0) {
            listaItens += value
        } else {
            listaItens += `, ${value}`
        }
    })
    return listaItens
}

function createData(id, social_name, cnpj, client, damage, items, brands, laudoCadastrado) {
    return {
        id,
        social_name,
        cnpj,
        client,
        damage,
        items: concatenaLista(items),
        brands: concatenaLista(brands),
        laudoCadastrado
    };
}

const headCells = [
    {
        id: 'id',
        numeric: true,
        disablePadding: false,
        label: 'Código AT',
    },
    {
        id: 'social_name',
        numeric: false,
        disablePadding: false,
        label: 'Razão Social',
    },
    {
        id: 'cnpj',
        numeric: false,
        disablePadding: false,
        label: 'CNPJ',
    },
    {
        id: 'client',
        numeric: false,
        disablePadding: false,
        label: 'Cliente',
    },
    {
        id: 'damage',
        numeric: false,
        disablePadding: false,
        label: 'Dano Reclamado',
    },
    {
        id: 'items',
        numeric: false,
        disablePadding: false,
        label: 'Peças',
    },
    {
        id: 'brands',
        numeric: false,
        disablePadding: false,
        label: 'Marcas',
    },
    {
        id: 'visualizar',
        numeric: false,
        disablePadding: false,
        label: 'Vizualizar',
    },
]

const valueCells = [
    {
        value: 'id',
        alignment: 'center'
    },
    {
        value: 'social_name',
        alignment: 'left'
    },
    {
        value: 'cnpj',
        alignment: 'left'
    },
    {
        value: 'client',
        alignment: 'left'
    },
    {
        value: 'damage',
        alignment: 'left'
    },
    {
        value: 'items',
        alignment: 'left'
    },
    {
        value: 'brands',
        alignment: 'left'
    },
    
]

export default function TabelaMeusLaudos({ocorrencias, onClick, children}) {
    const [linhas, setLinhas] = useState([])
    useEffect(() => {
        if(ocorrencias) {
            const listaOcorrencias = ocorrencias.map(value => createData(value.id, value.social_name, value.cnpj, value.client, value.damage, value.items, value.brands, value.laudoCadastrado))
            setLinhas(listaOcorrencias)
        }
    }, [ocorrencias])

    return (
        <TabelaSimples
            headCells={headCells}
            linhas={linhas}
            valueCells={valueCells}
            titulo="Meus Laudos"
            onClick={onClick}>
            {children}
        </TabelaSimples>
    )
}