import React, { useEffect } from 'react'

import Botao from '../../../../components/Botao'
import ContainerTitleInputField from '../../../../components/Containers/ContainerTitleInputField'
import { retornaSomenteNumerosDeString } from '../../../../Utilitarios'
import { useDispatch, useSelector } from 'react-redux'
import { resetAssociado } from '../../../../store/reducers/associadoSlice'
import { resetUsuario } from '../../../../store/reducers/usuarioSlice'
import CamposCadastraEstabelecimento from '../CamposCadastraEstabelecimento/CamposCadastraEstabelecimento'
import { resetLocalizacao } from '../../../../store/reducers/localizacaoSlice'
import CampoInputSelecionaEmail from '../Inputs/CampoInputSelecionaEmail/CampoInputSelecionaEmail'
import { resetEmailAssociadoSelecionado } from '../../../../store/reducers/emailAssociadoSelecionadoSlice'

const FormCadastraEstabelecimentoADM = ({ listaAssociados, aoSubmeterForm, onClick }) => {

    const dadosAssociado = useSelector(state => state.associadoState)
    const emailUsuarioSelecionado = useSelector(state => state.emailAssociadoSelecionadoState)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(resetAssociado())
        dispatch(resetUsuario())
        dispatch(resetLocalizacao())
        dispatch(resetEmailAssociadoSelecionado())
    }, [dispatch])
    
    const handleClick = (name) => {
        onClick(name)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        const envioValores = {
            ...dadosAssociado,
            usuario_id: emailUsuarioSelecionado.id,
            cep: retornaSomenteNumerosDeString(dadosAssociado.cep),
            phone1: retornaSomenteNumerosDeString(dadosAssociado.phone1)
        }
        aoSubmeterForm(envioValores)
    }


    return (
        <form method="post" onSubmit={onSubmit}>
            <CampoInputSelecionaEmail listaAssociados={listaAssociados}/> 
            <CamposCadastraEstabelecimento editButton={false} onClick={handleClick} />
            <ContainerTitleInputField titulo="" botao>
                <Botao titulo="Cadastrar estabelecimento" type="submit" />
            </ContainerTitleInputField>
        </form>
    )
}

export default FormCadastraEstabelecimentoADM