import CadastroInput from "./CadastroInput";

export default class AssociadoInput extends CadastroInput {
    constructor(dadosCadastro) {
        const { email, senha, ...demais } = dadosCadastro
        super(demais)
        this.email = dadosCadastro.email 
        this.senha = dadosCadastro.senha 
        this.confirmaSenha = dadosCadastro.confirmaSenha 

    }

    static create(dadosCadastro) {
        return new AssociadoInput(dadosCadastro)
    }
}