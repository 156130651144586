import MarcaInput from "./MarcaInput"

export default class MarcaOutput extends MarcaInput {
    constructor (marca) {
        super(marca.name)
        this.id = marca.id 
    }

    static create(marca) {
        return new MarcaOutput(marca)
    }
}