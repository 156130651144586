
import React from "react"
import ModalEditItem from "../../../../components/ModalEditItem"
import CampoInputProprietario from "../Inputs/CampoInputProprietario/CampoInputProprietario"
import CampoInputCnpj from "../Inputs/CampoInputCnpj/CampoInputCnpj"
import CampoInputDataFundacao from "../Inputs/CampoInputDataFundacao/CampoInputDataFundacao"
import CampoInputInscricaoEstadual from "../Inputs/CampoInputInscricaoEstadual/CampoInputInscricaoEstadual"
import CampoInputNomeFantasia from "../Inputs/CampoInputNomeFantasia/CampoInputNomeFantasia"
import CampoInputRazaoSocial from "../Inputs/CampoInputRazaoSocial/CampoInputRazaoSocial"
import CampoInputCep from "../Inputs/CampoInputCep/CampoInputCep"
import CampoInputTelefone from "../Inputs/CampoInputTelefone/CampoInputTelefone"
import CampoInputNumeroEndereco from "../Inputs/CampoInputNumeroEndereco/CampoInputNumeroEndereco"
import CampoInputComplemento from "../Inputs/CampoInputComplemento/CampoInputComplemento"
import CampoInputNumeroFuncionarios from "../Inputs/CampoInputNumeroFuncionarios/CampoInputNumeroFuncionarios"
import CampoInputSite from "../Inputs/CampoInputSite/CampoInputSite"
import CampoInputContato from "../Inputs/CampoInputContato/CampoInputContato"
import CampoInputCargoContato from "../Inputs/CampoInputCargoContato/CampoInputCargoContato"
import CampoInputSegmentoLavanderia from "../Inputs/CampoInputSegmentoLavanderia/CampoInputSegmentoLavanderia"
import CampoInputRedeFranquia from "../Inputs/CampoInputRedeFranquia/CampoInputRedeFranquia"
import CampoInputSelecionaEmail from "../Inputs/CampoInputSelecionaEmail/CampoInputSelecionaEmail"

const ModalAssociados = ({ open, handleClose, handleConfirma,  selected, listaUsuarios }) => {
    return (
        <ModalEditItem open={open} onClose={handleClose} confirma={handleConfirma} titulo="Editar usuário" >
            {selected === 'email' && <CampoInputSelecionaEmail 
            listaAssociados={listaUsuarios}                    
            disabled={false} />}
            {selected === 'owner' && <CampoInputProprietario />}
            {selected === 'cnpj' && <CampoInputCnpj />}
            {selected === 'foundation_date' && <CampoInputDataFundacao />}
            {selected === 'state_insc' && <CampoInputInscricaoEstadual />}
            {selected === 'fantasy_name' && <CampoInputNomeFantasia />}
            {selected === 'social_name' && <CampoInputRazaoSocial />}
            {selected === 'cep' && <CampoInputCep />}
            {selected === 'phone1' && <CampoInputTelefone />}
            {selected === 'numeroEndereco' && <CampoInputNumeroEndereco />}
            {selected === 'complemento' && <CampoInputComplemento />}
            {selected === 'employees_number' && <CampoInputNumeroFuncionarios />}
            {selected === 'site' && <CampoInputSite />}
            {selected === 'contato' && <CampoInputContato />}
            {/* {selected === 'store_number' && <CampoInputNumeroLojas />} */}
            {selected === 'cargo' && <CampoInputCargoContato />}
            {selected === 'washer_segment' && <CampoInputSegmentoLavanderia />}
            {selected === 'franchise' && <CampoInputRedeFranquia />}
        </ModalEditItem>
    )
}

export default ModalAssociados