import React from 'react'
import TabelaComSelection from '../../../../components/TabelaComSelection/index'

export default function GerenciarAssociados(props) {
    function createData(email, social_name, owner, cnpj, id) {
        return {
            email,
            social_name,
            owner,
            cnpj,
            id
        };
    }
    
    const headCells = [
        {
            id: 'email',
            numeric: false,
            disablePadding: false,
            label: 'E-mail',
        },
        {
            id: 'social_name',
            numeric: false,
            disablePadding: false,
            label: 'Razão Social',
        },
        {
            id: 'owner',
            numeric: false,
            disablePadding: false,
            label: 'Proprietário',
        },
        {
            id: 'cnpj',
            numeric: false,
            disablePadding: false,
            label: 'CNPJ',
        },
    ];
    
    const valueCells = [
        {
            value: 'email',
            alignment: 'left'
        },
        {
            value: 'social_name',
            alignment: 'left'
        },
        {
            value: 'owner',
            alignment: 'left'
        },
        {
            value: 'cnpj',
            alignment: 'left'
        },
    ]

    const linhas = props.cadastros.map(value => createData(value.usuario.email, value.cadastro.social_name, value.cadastro.owner, value.cadastro.cnpj, value.cadastro.id))

    return (
        <TabelaComSelection 
            headCells={headCells} 
            linhas={linhas} 
            valueCells={valueCells} 
            onClick={props.onClick} 
            tituloToolbar={props.tituloToolbar}>
            {props.children}
        </TabelaComSelection>
    )
}