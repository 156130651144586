import React, { useEffect } from 'react'

import Botao from '../../../../components/Botao'
import ContainerTitleInputField from '../../../../components/Containers/ContainerTitleInputField'
import { retornaSomenteNumerosDeString } from '../../../../Utilitarios'
import { useDispatch, useSelector } from 'react-redux'
import CamposEditaAssociado from '../CamposEditaAssociado/CamposEditaAssociado'
import CampoInputAssociado from '../Inputs/CampoInputAssociado/CampoInputAssociado'
import ShowOrHide from '../../../shared/components/ShowOrHide/ShowOrHide'
import { useBuscaCadastroPeloId } from '../../hooks/useBuscaCadastroPeloId'
import { resetAssociadoSelecionado } from '../../../../store/reducers/associadoSelecionadoSlice'
import { resetAssociado } from '../../../../store/reducers/associadoSlice'
import { resetLocalizacao } from '../../../../store/reducers/localizacaoSlice'

const FormEditaEstabelecimento = ({ aoSubmeterForm, onClick, listaAssociados }) => {

    const dadosAssociado = useSelector(state => state.associadoState)
    const { id } = useSelector(state => state.associadoSelecionadoState)
    
    const { cadastro } = useBuscaCadastroPeloId(id)
    
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(resetAssociadoSelecionado())
        dispatch(resetLocalizacao())
        dispatch(resetAssociado())
    }, [dispatch]) 

    const handleClick = (name) => {
        onClick(name)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        const envioValores = {
            ...dadosAssociado,
            cep: retornaSomenteNumerosDeString(dadosAssociado.cep),
            phone1: retornaSomenteNumerosDeString(dadosAssociado.phone1)
        }
        aoSubmeterForm(id, envioValores)
    }

    return (
        <form method="post" onSubmit={onSubmit}>
            <CampoInputAssociado editButton="void" listaAssociados={listaAssociados}/>
            <ShowOrHide campo={id} >
                <CamposEditaAssociado disabled={true} onClick={handleClick} cadastro={cadastro} />
                <ContainerTitleInputField titulo="" botao>
                    <Botao titulo="Atualizar associado" type="submit" />
                </ContainerTitleInputField>
            </ShowOrHide>
        </form>
    )
}

export default FormEditaEstabelecimento