import { useCallback, useState } from "react"
import http from "../../../../http"
import CadastroInputADM from "../../models/CadastroInputADM"

export const useCadastraEstabelecimentoADM = () => {
    const [resposta, setResposta] = useState({})

    const cadastrarEstabelecimentoADM = useCallback((inputsAssociado) => {
        const dadosCadastro = CadastroInputADM.create(inputsAssociado)
        http.post(`/estabelecimento-adm`, dadosCadastro)
            .then(res => {
                setResposta({ data: res.data, error: null })
            }) 
            .catch(error => {
                const { response } = error
                setResposta({ data: null, error: response })
            })
    }, [])
    return [resposta, cadastrarEstabelecimentoADM]
}