import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const useDisplayAlertAfterServerResponseRedirectLogin = (resposta) => {
    const [mensagem, setMensagem] = useState("")
    const [mensagemEnviada, setMensagemEnviada] = useState("")
    const [success, setSuccess] = useState("")
    const navigate = useNavigate()

    useEffect(() => {
        if(Object.keys(resposta).length !== 0) {
            if (resposta.data) {
                setMensagem(resposta.data)
                setSuccess(true)
            }
            if (resposta.error) {
                setMensagem(resposta.error.data)
            }
        }
    }, [resposta])

    useEffect(() => {
        if(mensagem) {
            alert(mensagem)
            setMensagem(null)
            setMensagemEnviada(true)
        }
    }, [mensagem])

    useEffect(() => {
        if(success && mensagemEnviada) {
            setSuccess(false)
            setMensagemEnviada(false)
            navigate('/login')
        }
    }, [success, mensagemEnviada, navigate])

    return 

  };