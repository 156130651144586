import React from 'react'
import { useBuscaAdministradoresAtivos } from '../../modules/usuario/hooks/useBuscaAdministradoresAtivos'
import { useDesativaAdministradores } from '../../modules/usuario/hooks/useDesativaAdministradores'
import BlockItem from '../../modules/shared/components/IconButtons/BlockItem'
import GerenciarAdministradores from '../../modules/usuario/components/TabelaGerenciarAdministradores/TabelaGerenciarAdministradores'
import MainTitle from '../../modules/shared/components/MainTitle'
import { useDisplayAlertAfterServerResponse } from '../../modules/shared/hooks/useDisplayAlertAfterServerResponse/useDisplayAlertAfterServerResponse'
import { Paper } from '@mui/material'

export default function DesativarAdministradores() {

    const { listaAdministradoresAtivos } = useBuscaAdministradoresAtivos()
    const [resposta, desativarUsuarios] = useDesativaAdministradores()
    useDisplayAlertAfterServerResponse(resposta)
    
    const handleClick = (listaSelecionados) => (e) => {
        e.preventDefault()
        desativarUsuarios(listaSelecionados)
    }

    return (
        <Paper>
            <MainTitle titulo="Desativar administradores" tituloPrincipal={true} />
            <GerenciarAdministradores
                usuarios={listaAdministradoresAtivos}
                onClick={handleClick}
                tituloToolbar={"Administradores ativos"}>
                <BlockItem />
            </GerenciarAdministradores> 
        </Paper>
    )
}