import { Paper } from '@mui/material'
import React from 'react'

import AllowItem from '../../modules/shared/components/IconButtons/AllowItem'
import { useBuscaListaCadastrosInativos } from '../../modules/associado/hooks/useBuscaListaCadastrosInativos';
import MainTitle from '../../modules/shared/components/MainTitle';
import FilterAssociadoTable from '../../modules/associado/components/FilterAssociadoTable/FilterAssociadoTable';
import { useAtivaCadastros } from '../../modules/associado/hooks/useAtivaCadastros';
import { useDisplayAlertAfterServerResponse } from '../../modules/shared/hooks/useDisplayAlertAfterServerResponse/useDisplayAlertAfterServerResponse';

export default function LiberarAcessos() {
    
    const { listaCadastrosInativos } = useBuscaListaCadastrosInativos()
    const [resposta, ativaCadastros] = useAtivaCadastros()
    useDisplayAlertAfterServerResponse(resposta)

    const handleClick = (listaSelecionados) => (e) => {
        e.preventDefault()
        ativaCadastros(listaSelecionados)
    }

    return (
        <Paper>
            <MainTitle titulo="Liberar acessos" tituloPrincipal={true} />
            <FilterAssociadoTable listaAssociados={listaCadastrosInativos} onClick={handleClick} tituloToolbar="Cadastros inativos">
                <AllowItem />
            </FilterAssociadoTable>
        </Paper>
    )
}