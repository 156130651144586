import { useEffect, useState } from "react";
import http from "../../../../http";

export const useBuscaListaDeIdsDeOcorrenciasComLaudo = () => {
    const [listaIdsDeOcorrenciaComLaudo, setListaIdsDeOcorrenciasComLaudo] = useState([])
    
    useEffect(() => {
        http.get('/ocorrencias-com-laudo')
        .then(res => {
            const listaOcorrencias = res.data
            setListaIdsDeOcorrenciasComLaudo(listaOcorrencias)
        })
    }, [])

    return {
        listaIdsDeOcorrenciaComLaudo
    }
}