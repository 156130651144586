import React, { useEffect, useState } from 'react'
import TabelaSimples from '../../../../components/TabelaSimples/index'

function concatenaLista(lista) {
    let listaItens = ""
    lista.forEach((value, index) => {
        if (index === 0) {
            listaItens += value
        } else {
            listaItens += `, ${value}`
        }
    })
    return listaItens
}


function createData(id, damage, items, brands, ocurrence_date, wash_used, wash_instruct, responsible
    // iron_clothes, dry_dryer
    ) {
    return {
        id,
        damage,
        items: concatenaLista(items),
        brands: concatenaLista(brands),
        ocurrence_date,
        wash_used,
        wash_instruct,
        responsible
        // iron_clothes,
        // dry_dryer,
    }
}

const headCells = [
    {
        id: 'damage',
        numeric: true,
        disablePadding: false,
        label: 'Dano Reclamado',
    },
    {
        id: 'items',
        numeric: false,
        disablePadding: false,
        label: 'Peças',
    },
    {
        id: 'brands',
        numeric: false,
        disablePadding: false,
        label: 'Marcas',
    },    
    {
        id: 'ocurrence_date',
        numeric: false,
        disablePadding: false,
        label: 'Data Ocorrência',
    },
    {
        id: 'wash_instruct',
        numeric: false,
        disablePadding: false,
        label: 'Instrução Limpeza',
    },
    {
        id: 'wash_used',
        numeric: false,
        disablePadding: false,
        label: 'Limpeza Utilizada',
    },
    {
        id: 'responsible',
        numeric: false,
        disablePadding: false,
        label: 'Responsável',
    },
    // {
    //     id: 'iron_clothes',
    //     numeric: false,
    //     disablePadding: false,
    //     label: 'Foi Passado',
    // },
    // {
    //     id: 'dry_dryer',
    //     numeric: false,
    //     disablePadding: false,
    //     label: 'Foi Secado',
    // },
]

const valueCells = [
    {
        value: 'damage',
        alignment: 'left'
    },
    {
        value: 'items',
        alignment: 'left'
    },
    {
        value: 'brands',
        alignment: 'left'
    },
    {
        value: 'ocurrence_date',
        alignment: 'left'
    },
    {
        value: 'wash_instruct',
        alignment: 'left'
    },
    {
        value: 'wash_used',
        alignment: 'left'
    },
    {
        value: 'responsible',
        alignment: 'left'
    },
    // {
    //     value: 'iron_clothes',
    //     alignment: 'left'
    // },
    // {
    //     value: 'dry_dryer',
    //     alignment: 'left'
    // },
    
]

export default function TabelaOcorrenciasAnonimas({ocorrencias, onClick, children}) {
    const [linhas, setLinhas] = useState([])
    useEffect(() => {
        if(ocorrencias.length < 1 || !ocorrencias) {
            setLinhas([])
        } else if(ocorrencias) {
            const listaOcorrencias = ocorrencias.map(value => createData(value.id, value.damage, value.items, value.brands, value.ocurrence_date, value.wash_used, value.wash_instruct, value.responsible 
                // value.iron_clothes, value.dry_dryer
                ))
            setLinhas(listaOcorrencias)
        }
    }, [ocorrencias])

    return (
        <TabelaSimples
            headCells={headCells}
            linhas={linhas}
            valueCells={valueCells}
            titulo="Ocorrências de associados"
            onClick={onClick}>
            {children}
        </TabelaSimples>
    )
}