import React from 'react'
import TabelaSimples from '../../../../components/TabelaSimples'

export default function TabelaListaValorPorCampoDeOcorrencia(props) {
    function createData(name, id) {
        return {
            name,
            id
        };
    }
    
    const geraCabecalho = (campoName) => {
    
        const campo = [
            {
                id: 'name',
                numeric: false,
                disablePadding: false,
                label: campoName,
            }
        ];
        return campo
    }
    
    const valueCells = [
        {
            value: 'name',
            alignment: 'left'
        }
    ]
    
    const headCells = geraCabecalho(props.campo)
    const linhas = props.usuarios.map(value => createData(value.name, value.id))
    
    return (
        <TabelaSimples 
            headCells={headCells} 
            linhas={linhas} 
            valueCells={valueCells}
            titulo={`${props.campo}s cadastradas`} 
            onClick={props.onClick}>
            {props.children ? props.children : false}
        </TabelaSimples>
    )
}