import { useDispatch, useSelector } from 'react-redux'
import EditItem from '../../../../shared/components/IconButtons/EditItem'
import CampoInputRadio from '../../../../shared/components/CampoInputComButton/CampoInputRadio/CampoInputRadio'
import { useEffect, useState } from 'react'
import { handleOcorrenciaChange } from '../../../../../store/reducers/ocorrenciaSlice'

const CampoInputFoiPassado = ({ onClick, editButton, disabled }) => {
    const [value, setValue] = useState("")
    const dispatch = useDispatch()
    const { iron_clothes } = useSelector(state => state.ocorrenciaState)
    
    useEffect(() => {
        if(iron_clothes === "1") setValue("Sim") 
        if(iron_clothes === "0") setValue("Não") 
        if(iron_clothes === "") setValue()
    }, [iron_clothes])

    const handleChange = (name, value) => {
        if(value === "Sim") dispatch(handleOcorrenciaChange({[name]: "1"}))
        if(value === "Não") dispatch(handleOcorrenciaChange({[name]: "0"}))
        setValue(value)
    }
    
    const handleClick = (e) => {
        e.preventDefault()
        onClick("iron_clothes")
    }

    return (
        <CampoInputRadio
            id="inputFoiPassado"
            titulo="Artigo foi passado?*"
            onChange={handleChange}
            name="iron_clothes"
            value={value}
            radioValores={["Sim", "Não"]}
            required={true}
            disabled={disabled} >
            {
                editButton ? <EditItem onClick={handleClick} /> : <></>
            }
        </CampoInputRadio>
    )
}

export default CampoInputFoiPassado
