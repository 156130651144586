import CadastroInput from "./CadastroInput"

export default class CadastroOutput extends CadastroInput {
    constructor(dadosCadastro) {
        const { id, usuario_id, ativo, ...args } = dadosCadastro 
        super(args)
        this.id = id
        this.usuario_id = dadosCadastro.usuario_id
        this.ativo = dadosCadastro.ativo
    }

    static create (cadastro) {
        if(!CadastroOutput.validate) {
            throw new Error('Dados de cadastro inconsistentes')
        }
        return new CadastroOutput(cadastro)
    }

    static validate (cadastro) {
        if(!cadastro.id) {
            return false
        }
        return true
    }

    getCadastroId() {
        return this.id
    }

    getAtivo() {
        return this.ativo
    }
    
}

