import { useEffect, useState } from "react";
import CadastroOutput from "../../models/CadastroOutput";
import UsuarioOutput from "../../../usuario/models/UsuarioOutput";
import { useStatusFetch } from "../../../shared/hooks/useStatusFetch";
import http from "../../../../http";

export const useBuscaListaCadastrosAtivos = () => {

  const [listaCadastrosAtivos, setListaCadastrosAtivos] = useState([])
  const { needFetching } = useStatusFetch(listaCadastrosAtivos)

  useEffect(() => {
    if (!needFetching) return
    http.get(`/cadastros-ativos`)
      .then(res => {
        const resposta = res.data
        const listaCadastros = resposta.map(cadastro => {
          const { Usuario, ...demaisCamposCadastro } = cadastro
          const cadastrosAtivos = CadastroOutput.create(demaisCamposCadastro)
          const usuario = UsuarioOutput.create(Usuario)
          return {
            usuario,
            cadastro: cadastrosAtivos
          }
        })
        setListaCadastrosAtivos(listaCadastros)
      })
      .catch(err => console.log(err))
    
  }, [needFetching])

    return {
      listaCadastrosAtivos
    };
  };