import React from 'react'
import * as S from './style'
import { MenuItem } from '@mui/material';

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 48 * 4.5 + 8,
            width: 250,
        },
    },
    disableScrollLock: true
};

export default function FormSelectInput({itensDoSelect, ...props}) {
    return (
        <>
            <S.ContainerComButton>
                <S.InputSelect
                    {...props}
                    data-testid="campoSelect"
                    inputProps={{ 'aria-label': 'Without label' }}
                    MenuProps={MenuProps}
                >
                    {
                        (itensDoSelect) && (
                            itensDoSelect.map((value, index) => {
                                return <MenuItem key={index} value={value}>{value}</MenuItem>
                            })
                        )
                    }

                </S.InputSelect>
                <S.ContainerIcon>
                    { props.children && (<>{props.children}</>) }
                </S.ContainerIcon>
            </S.ContainerComButton>

        </>

    )
}