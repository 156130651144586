import React from 'react';

import * as S from './style'
import TodasAsRotas from './Rotas/index'

function App() {
  return (
    <>
      <S.GlobalStyle />
      <TodasAsRotas />
    </>
  );
}

export default App;
