import { useCallback, useState } from "react"
import ListaActivateIds from "../../../shared/models/ListaActivateIds/ListaActivateIds"
import http from "../../../../http"

export const useAtivaAdministradores = () => {
    const [resposta, setResposta] = useState({})

    const ativaUsuarios = useCallback((listaIdsAdms) => {
        const listaIdsUsuariosASeremAtivados = ListaActivateIds.create(listaIdsAdms)
        http.put(`/ativa-multiplos-usuarios`, listaIdsUsuariosASeremAtivados)
            .then(res => {
                setResposta({ data: res.data, error: null })
            })
            .catch(error => {
                const { response } = error
                setResposta({ data: null, error: response })
            })
    }, [])
    return [resposta, ativaUsuarios]
}