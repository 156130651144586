import { createSlice } from "@reduxjs/toolkit"

const estadoInicial = {
    owner: "",
    cnpj: "",
    social_name: "",
    fantasy_name: "",
    state_insc: "",
    foundation_date: "",
    cep: "",
    numeroEndereco: "",
    complemento: "",
    employees_number: "",
    phone1: "",
    site: "",
    contato: "",
    cargo: "",
    store_number: "",
    washer_segment: "",
    franchise: ""
}

const associadoSlice = createSlice({
    name: 'dadosAssociado',
    initialState: estadoInicial,
    reducers: {
        handleAssociadoChange: (state, action) => {
            return {...state, ...action.payload }
        },
        resetAssociado: (state) => {
            return estadoInicial;
        }
        
    }
})

export const { handleAssociadoChange, resetAssociado } = associadoSlice.actions

export default associadoSlice.reducer