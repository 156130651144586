import styled from 'styled-components'

export const JanelaDragDrop = styled.section`
    width: 100%;
    max-width: 420px;
    background-color: #f4f4f4;
    text-align: center;
    padding: 10px;
    border: solid 3px rgb(50, 50, 50, 0.5);
    border-radius: 10px ;
    border-style: dashed;

`