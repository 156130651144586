import { useCallback, useState } from "react"
import Laudo from "../../models/laudo/Laudo"
import http from "../../../../http"

export const useAtualizaLaudo = () => {
    const [resposta, setResposta] = useState({})
    const atualizarLaudo = useCallback((inputCadastro) => {
        const atualizaLaudo = Laudo.create(inputCadastro) 
        http.put('/atualiza-laudo', atualizaLaudo, {
                headers: {
                    "Content-Type": "multipart/form-data"
                },
            })
            .then(res => {
                if (res.status === 200) {
                    setResposta({ data: res.data, error: null })
                }
            })
            .catch(error => {
                setResposta({data: null, error})
            })
    }, [])
    return [resposta, atualizarLaudo]
}