import React from 'react'

import CampoInputCidade from '../../../associado/components/Inputs/CampoInputCidade/CampoInputCidade'
import CampoInputEstado from '../../../associado/components/Inputs/CampoInputEstado/CampoInputEstado'
import CampoInputAutorizaTestes from '../Inputs/CampoInputAutorizaTestes/CampoInputAutorizaTestes'
import CampoInputDanoReclamado from '../Inputs/CampoInputDanoReclamado/CampoInputDanoReclamado'
import CampoInputDataOcorrencia from '../Inputs/CampoInputDataOcorrencia/CampoInputDataOcorrencia'
import CampoInputEncaminhaSeguro from '../Inputs/CampoInputEncaminhaSeguro/CampoInputEncaminhaSeguro'
import CampoInputFoiPassado from '../Inputs/CampoInputFoiPassado/CampoInputFoiPassado'
import CampoInputFoiSeco from '../Inputs/CampoInputFoiSeco/CampoInputFoiSeco'
import CampoInputInstrucaoLimpeza from '../Inputs/CampoInputInstrucaoLimpeza/CampoInputInstrucaoLimpeza'
import CampoInputLimpezaUtilizada from '../Inputs/CampoInputLimpezaUtilizada/CampoInputLimpezaUtilizada'
import CampoInputMarcas from '../Inputs/CampoInputMarcas/CampoInputMarcas'
import CampoInputNomeCliente from '../Inputs/CampoInputNomeCliente/CampoInputNomeCliente'
import CampoInputObservacao from '../Inputs/CampoInputObservacao/CampoInputObservacao'
import CampoInputPecas from '../Inputs/CampoInputPecas/CampoInputPecas'
import CampoInputResponsabilidade from '../Inputs/CampoInputResponsabilidade/CampoInputResponsabilidade'
import CampoInputSolucao from '../Inputs/CampoInputSolucao/CampoInputSolucao'
import CampoInputTecnico from '../Inputs/CampoInputTecnico/CampoInputTecnico'
import CampoInputTicket from '../Inputs/CampoInputTicket/CampoInputTicket'
import CampoInputTipoFibra from '../Inputs/CampoInputTipoFibra/CampoInputTipoFibra'

const CamposCadastraOcorrencia = ({editButton, disabled, onClick}) => {

    const handleClick = (field) => {
        onClick(field)
    }

    return (
        <>
            <CampoInputPecas editButton={editButton} disabled={disabled} onClick={handleClick}/>
            <CampoInputMarcas editButton={editButton} disabled={disabled} onClick={handleClick} />
            <CampoInputInstrucaoLimpeza editButton={editButton} disabled={disabled} onClick={handleClick} />
            <CampoInputLimpezaUtilizada editButton={editButton} disabled={disabled} onClick={handleClick} />
            <CampoInputDanoReclamado editButton={editButton} disabled={disabled} onClick={handleClick} />
            <CampoInputDataOcorrencia editButton={editButton} disabled={disabled} onClick={handleClick} />
            <CampoInputTipoFibra editButton={editButton} disabled={disabled} onClick={handleClick} />
            <CampoInputFoiPassado editButton={editButton} disabled={disabled} onClick={handleClick}/>
            <CampoInputFoiSeco editButton={editButton} disabled={disabled} onClick={handleClick} />
            <CampoInputEncaminhaSeguro editButton={editButton} disabled={disabled} onClick={handleClick} />
            <CampoInputAutorizaTestes editButton={editButton} disabled={disabled} onClick={handleClick} />
            <CampoInputCidade editButton={editButton} onClick={handleClick} />
            <CampoInputEstado editButton={editButton} onClick={handleClick} />
            <CampoInputResponsabilidade editButton={editButton} disabled={disabled} onClick={handleClick} />
            <CampoInputTecnico editButton={editButton} disabled={disabled} onClick={handleClick} />
            <CampoInputNomeCliente editButton={editButton} disabled={disabled} onClick={handleClick} />
            <CampoInputTicket editButton={editButton} disabled={disabled} onClick={handleClick} />
            <CampoInputSolucao editButton={editButton} disabled={disabled} onClick={handleClick} />
            <CampoInputObservacao editButton={editButton} disabled={disabled} onClick={handleClick} />
            {/* <CampoInputEncaminha editButton={editButton} disabled={disabled} onClick={handleClick} /> */}
        </>
    )
}

export default CamposCadastraOcorrencia