import CampoInputCnpj from "../Inputs/CampoInputCnpj/CampoInputCnpj"

import CampoInputDataFundacao from "../Inputs/CampoInputDataFundacao/CampoInputDataFundacao"

import CampoInputInscricaoEstadual from "../Inputs/CampoInputInscricaoEstadual/CampoInputInscricaoEstadual"
import CampoInputNomeFantasia from "../Inputs/CampoInputNomeFantasia/CampoInputNomeFantasia"
import CampoInputProprietario from "../Inputs/CampoInputProprietario/CampoInputProprietario"
import CampoInputRazaoSocial from "../Inputs/CampoInputRazaoSocial/CampoInputRazaoSocial"

import CampoInputCep from "../Inputs/CampoInputCep/CampoInputCep"
import CampoInputTelefone from "../Inputs/CampoInputTelefone/CampoInputTelefone"
import CampoInputEndereco from "../Inputs/CampoInputEndereco/CampoInputEndereco"
import CampoInputNumeroEndereco from "../Inputs/CampoInputNumeroEndereco/CampoInputNumeroEndereco"
import CampoInputComplemento from "../Inputs/CampoInputComplemento/CampoInputComplemento"
import CampoInputBairro from "../Inputs/CampoInputBairro/CampoInputBairro"
import CampoInputCidade from "../Inputs/CampoInputCidade/CampoInputCidade"
import CampoInputEstado from "../Inputs/CampoInputEstado/CampoInputEstado"
import CampoInputNumeroFuncionarios from "../Inputs/CampoInputNumeroFuncionarios/CampoInputNumeroFuncionarios"
import CampoInputSite from "../Inputs/CampoInputSite/CampoInputSite"
import CampoInputContato from "../Inputs/CampoInputContato/CampoInputContato"
import CampoInputCargoContato from "../Inputs/CampoInputCargoContato/CampoInputCargoContato"
import CampoInputSegmentoLavanderia from "../Inputs/CampoInputSegmentoLavanderia/CampoInputSegmentoLavanderia"
import CampoInputRedeFranquia from "../Inputs/CampoInputRedeFranquia/CampoInputRedeFranquia"

const CamposCadastraEstabelecimento = ({ disabled, editButton, onClick }) => {

    const handleClick = (name) => {
        onClick(name)
    }

    return (
        <>
            <CampoInputProprietario
                disabled={disabled}
                onClick={handleClick}
                editButton={editButton}
            />
            <CampoInputCnpj
                disabled={disabled}
                onClick={handleClick}
                editButton={editButton}
            />
            <CampoInputRazaoSocial
                disabled={disabled}
                onClick={handleClick}
                editButton={editButton}
            />
            <CampoInputNomeFantasia
                disabled={disabled}
                onClick={handleClick}
                editButton={editButton}
            />
            <CampoInputInscricaoEstadual
                disabled={disabled}
                onClick={handleClick}
                editButton={editButton}
            />
            <CampoInputDataFundacao
                disabled={disabled}
                onClick={handleClick}
                editButton={editButton}
            />
            <CampoInputCep
                disabled={disabled}
                onClick={handleClick}
                editButton={editButton}
            />
            <CampoInputEndereco
                disabled={disabled}
                onClick={handleClick}
                editButton={editButton}
            />
            <CampoInputNumeroEndereco
                disabled={disabled}
                onClick={handleClick}
                editButton={editButton}
            />
            <CampoInputComplemento
                disabled={disabled}
                onClick={handleClick}
                editButton={editButton}
            />
            <CampoInputBairro
                disabled={disabled}
                onClick={handleClick}
                editButton={editButton}
            />
            <CampoInputCidade
                disabled={disabled}
                onClick={handleClick}
                editButton={editButton}
            />
            <CampoInputEstado
                disabled={disabled}
                onClick={handleClick}
                editButton={editButton}
            /> 
            <CampoInputNumeroFuncionarios
                disabled={disabled}
                onClick={handleClick}
                editButton={editButton}
            />
            <CampoInputTelefone
                disabled={disabled}
                onClick={handleClick}
                editButton={editButton}
            />
            <CampoInputSite
                disabled={disabled}
                onClick={handleClick}
                editButton={editButton}
            />
            <CampoInputContato
                disabled={disabled}
                onClick={handleClick}
                editButton={editButton}
            />
            <CampoInputCargoContato
                disabled={disabled}
                onClick={handleClick}
                editButton={editButton}
            />
            {/* <CampoInputNumeroLojas
                disabled={disabled}
                onClick={handleClick}
                editButton={editButton}
            /> */}
            <CampoInputSegmentoLavanderia
                disabled={disabled}
                onClick={handleClick}
                editButton={editButton}
            />
            <CampoInputRedeFranquia
                disabled={disabled}
                onClick={handleClick}
                editButton={editButton}
            />
        </>
    )
}

export default CamposCadastraEstabelecimento