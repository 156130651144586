export default class OcorrenciaOutput {
    constructor(dadosOcorrencia) {
        this.id = dadosOcorrencia.id
        this.cadastro_id = dadosOcorrencia.cadastro_id
        this.usuario_id = dadosOcorrencia.usuario_id
        this.pecas = dadosOcorrencia.Items
        this.marcas = dadosOcorrencia.Brands
        this.instrucaoLimpeza = dadosOcorrencia.wash_instruct
        this.limpezaUtilizada = dadosOcorrencia.wash_used
        this.cliente = dadosOcorrencia.client
        this.complemento = dadosOcorrencia.complemento
        this.danoReclamado = dadosOcorrencia.damage
        this.foiSecado = dadosOcorrencia.dry_dryer
        this.tipoFibra = dadosOcorrencia.fiber_type
        this.encaminhaSeguro = dadosOcorrencia.insurance
        this.foiPassado = dadosOcorrencia.iron_clothes
        this.laudoCadastrado = dadosOcorrencia.laudoCadastrado
        this.observacao = dadosOcorrencia.observation
        this.dataOcorrencia = dadosOcorrencia.ocurrence_date
        this.dataSaida = dadosOcorrencia.out_date
        this.responsavel = dadosOcorrencia.responsible
        this.encaminha = dadosOcorrencia.routing
        this.solucao = dadosOcorrencia.solution
        this.nomeTecnico = dadosOcorrencia.technician
        this.autorizaTestes = dadosOcorrencia.tests
        this.ticket = dadosOcorrencia.ticket
    }

    static create (ocorrencia) {
        return new OcorrenciaOutput(ocorrencia)
    }

    static validate (cadastro) {
        if(!cadastro.id) {
            return false
        }
        return true
    }

    getOcorrencia() {
        let props = {}
        for (let prop in this) {
            if (this.hasOwnProperty(prop)) {
                props[prop] = this[prop]
            }
        }
        return props
    }

    getCliente() {
        return this.cliente ? this.cliente : ""
    }

    getSolucao() {
        return this.solucao ? this.solucao : "" 
    }

    getId() {
        return this.id
    }

    getPecas() {
        return this.pecas
    }

    getResponsavel() {
        return this.responsavel
    }

    getDanoReclamado() {
        return this.danoReclamado
    }

    getLaudoCadastrado() {
        return this.laudoCadastrado
    }

    getDataOcorrencia() {
        return this.dataOcorrencia
    }

    getInstrucaoLimpeza() {
        return this.instrucaoLimpeza
    }

    getLimpezaUtilizada() {
        return this.limpezaUtilizada
    }

    getFoiSecado() {
        return this.foiSecado
    }
    
    getFoiPassado() {
        return this.foiPassado
    }

}

