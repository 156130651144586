import { useCallback, useState } from "react"
import OcorrenciaUpdate from "../../models/ocorrencia/OcorrenciaUpdate"
import http from "../../../../http"

export const useAtualizaOcorrencia = () => {
    const [resposta, setResposta] = useState({})

    const atualizarOcorrencia = useCallback((inputsOcorrencia) => {
        const { codigoAT, usuario_id, ...demais} = inputsOcorrencia
        const ocorrencia = OcorrenciaUpdate.create(demais)
        http.put(`/usuarios/${usuario_id}/ocorrencias/${codigoAT}`, ocorrencia)
            .then(res => {
                setResposta({ data: res.data, error: null })
            })
            .catch(error => {
                const { response } = error
                setResposta({ data: null, error: response })
            })
    }, [])
    return [resposta, atualizarOcorrencia]
}