import { useEffect } from 'react'
import FormInputTexto from '../../../../../components/FormInputTexto'
import { useDispatch, useSelector } from 'react-redux'
import { handleCampoValorChange } from '../../../../../store/reducers/campoValorSlice'

const Valor = () => {

    const dispatch = useDispatch()
    const { valor } = useSelector(state => state.campoValorState)

    useEffect(() => {
        dispatch(handleCampoValorChange({ valor: "" }))
    }, [dispatch])

    const handleChange = (e) => {
        e.preventDefault()
        const input = e.target.value
        dispatch(handleCampoValorChange({ valor: input }))
    }
    return (
        <>
            <h5>Descrição</h5>
            <FormInputTexto
                value={valor}
                onChange={handleChange} />
        </>       
    )
}

export default Valor