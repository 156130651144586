import React, { useEffect } from "react"

import MainTitle from "../../modules/shared/components/MainTitle"
import { useAtualizaLaudo } from "../../modules/ocorrencia/hooks/useAtualizaLaudo"
import { useBuscaListaDeIdsDeOcorrenciasComLaudo } from "../../modules/ocorrencia/hooks/useBuscaListaDeIdsDeOcorrenciasComLaudo"
import DisplayPDFOnScreen from "../../modules/ocorrencia/components/DisplayPDFOnScreen/DisplayPDFOnScreen"
import FormLaudos from "../../modules/ocorrencia/components/FormLaudos/FormLaudos"
import { useBuscaOcorrenciaPeloId } from "../../modules/ocorrencia/hooks/useBuscaOcorrenciaPeloId"
import { useDispatch, useSelector } from "react-redux"
import { resetOcorrenciaSelecionada } from "../../store/reducers/ocorrenciaSelecionadaSlice"
import { resetOcorrencia } from "../../store/reducers/ocorrenciaSlice"
import { resetAssociadoSelecionado } from "../../store/reducers/associadoSelecionadoSlice"
import ShowOrHide from "../../modules/shared/components/ShowOrHide/ShowOrHide"

export default function EditarLaudo() {

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(resetOcorrenciaSelecionada())
        dispatch(resetAssociadoSelecionado())
        dispatch(resetOcorrencia())
    }, [dispatch])

    const { codigoAT } = useSelector(state => state.ocorrenciaSelecionadaState)
    
    const { listaIdsDeOcorrenciaComLaudo } = useBuscaListaDeIdsDeOcorrenciasComLaudo()
    
    return (
        <>
            <MainTitle titulo={"Editar laudo"} tituloPrincipal={true} />
            <FormLaudos 
                listaParaAutocomplete={listaIdsDeOcorrenciaComLaudo}
                useBuscaOcorrenciaParaCamposPreenchidos={useBuscaOcorrenciaPeloId} 
                useOnSubmitForm={useAtualizaLaudo}
            />
            <ShowOrHide campo={codigoAT}>
                <DisplayPDFOnScreen />
            </ShowOrHide>    
        </>
    )
}