import * as React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import { Tooltip } from '@mui/material';

export default function EditItem(props) {

    return (
        <Tooltip title="Editar">
            <IconButton onClick={props.onClick}>
                <EditIcon />
            </IconButton>
        </Tooltip>
    )
}