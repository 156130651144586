import CampoInputCnpj from "../Inputs/CampoInputCnpj/CampoInputCnpj"
import CampoInputDataFundacao from "../Inputs/CampoInputDataFundacao/CampoInputDataFundacao"
import CampoInputInscricaoEstadual from "../Inputs/CampoInputInscricaoEstadual/CampoInputInscricaoEstadual"
import CampoInputNomeFantasia from "../Inputs/CampoInputNomeFantasia/CampoInputNomeFantasia"
import CampoInputProprietario from "../Inputs/CampoInputProprietario/CampoInputProprietario"
import CampoInputRazaoSocial from "../Inputs/CampoInputRazaoSocial/CampoInputRazaoSocial"
import CampoInputCep from "../Inputs/CampoInputCep/CampoInputCep"
import CampoInputTelefone from "../Inputs/CampoInputTelefone/CampoInputTelefone"
import CampoInputEndereco from "../Inputs/CampoInputEndereco/CampoInputEndereco"
import CampoInputNumeroEndereco from "../Inputs/CampoInputNumeroEndereco/CampoInputNumeroEndereco"
import CampoInputComplemento from "../Inputs/CampoInputComplemento/CampoInputComplemento"
import CampoInputBairro from "../Inputs/CampoInputBairro/CampoInputBairro"
import CampoInputCidade from "../Inputs/CampoInputCidade/CampoInputCidade"
import CampoInputEstado from "../Inputs/CampoInputEstado/CampoInputEstado"
import CampoInputNumeroFuncionarios from "../Inputs/CampoInputNumeroFuncionarios/CampoInputNumeroFuncionarios"
import CampoInputSite from "../Inputs/CampoInputSite/CampoInputSite"
import CampoInputContato from "../Inputs/CampoInputContato/CampoInputContato"
import CampoInputCargoContato from "../Inputs/CampoInputCargoContato/CampoInputCargoContato"
import CampoInputSegmentoLavanderia from "../Inputs/CampoInputSegmentoLavanderia/CampoInputSegmentoLavanderia"
import CampoInputRedeFranquia from "../Inputs/CampoInputRedeFranquia/CampoInputRedeFranquia"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { handleAssociadoChange, resetAssociado } from "../../../../store/reducers/associadoSlice"
import { cepMask, telefoneMask } from "../../../../Utilitarios"

const CamposEditaAssociado = ({ disabled, editButton, onClick, cadastro }) => {

    const dispatch = useDispatch()

    const handleClick = (name) => {
        onClick(name)
    }

    useEffect(() => {
        if(Object.entries(cadastro).length > 0) {         
            dispatch(handleAssociadoChange({
                owner: cadastro.getProprietario(),
                cnpj: cadastro.getCnpj(),
                social_name: cadastro.getRazaoSocial(),
                fantasy_name: cadastro.getNomeFantasia(),
                state_insc: cadastro.getInscricaoEstadual(),
                foundation_date: cadastro.getDataFundacao(),
                cep: cepMask(cadastro.getCep()),
                numeroEndereco: cadastro.getNumeroEndereco(),
                complemento: cadastro.getComplemento(),
                employees_number: cadastro.getNumeroFuncionarios(),
                phone1: telefoneMask(cadastro.getTelefone()),
                site: cadastro.getSite(),
                contato: cadastro.getContato(),
                cargo: cadastro.getCargo(),
                store_number: cadastro.getNumeroEstabelecimento(),
                washer_segment: cadastro.getSegmentoLavanderia(),
                franchise: cadastro.getRedeFranquia()
            }))
        } else {
            dispatch(resetAssociado())
        }
    }, [cadastro, dispatch])

    return (
        <>
            <CampoInputProprietario
                disabled={disabled}
                onClick={handleClick}
                editButton={true}
            />
            <CampoInputCnpj
                disabled={disabled}
                onClick={handleClick}
                editButton={true}
            />
            <CampoInputRazaoSocial
                disabled={disabled}
                onClick={handleClick}
                editButton={true}
            />
            <CampoInputNomeFantasia
                disabled={disabled}
                onClick={handleClick}
                editButton={true}
            />
            <CampoInputInscricaoEstadual
                disabled={disabled}
                onClick={handleClick}
                editButton={true}
            />
            <CampoInputDataFundacao
                disabled={disabled}
                onClick={handleClick}
                editButton={true}
            />
            <CampoInputCep
                disabled={disabled}
                onClick={handleClick}
                editButton={true}
            />
            <CampoInputEndereco
                disabled={disabled}
                onClick={handleClick}
                editButton={true}
            />
            <CampoInputNumeroEndereco
                disabled={disabled}
                onClick={handleClick}
                editButton={true}
            />
            <CampoInputComplemento
                disabled={disabled}
                onClick={handleClick}
                editButton={true}
            />
            <CampoInputBairro
                disabled={disabled}
                onClick={handleClick}
                editButton={true}
            />
            <CampoInputCidade
                disabled={disabled}
                onClick={handleClick}
                editButton={true}
            />
            <CampoInputEstado
                disabled={disabled}
                onClick={handleClick}
                editButton={true}
            /> 
            <CampoInputNumeroFuncionarios
                disabled={disabled}
                onClick={handleClick}
                editButton={true}
            />
            <CampoInputTelefone
                disabled={disabled}
                onClick={handleClick}
                editButton={true}
            />
            <CampoInputSite
                disabled={disabled}
                onClick={handleClick}
                editButton={true}
            />
            <CampoInputContato
                disabled={disabled}
                onClick={handleClick}
                editButton={true}
            />
            <CampoInputCargoContato
                disabled={disabled}
                onClick={handleClick}
                editButton={true}
            />
            {/* <CampoInputNumeroLojas
                disabled={disabled}
                onClick={handleClick}
                editButton={true}
            /> */}
            <CampoInputSegmentoLavanderia
                disabled={disabled}
                onClick={handleClick}
                editButton={true}
            />
            <CampoInputRedeFranquia
                disabled={disabled}
                onClick={handleClick}
                editButton={true}
            />
        </>
    )
}

export default CamposEditaAssociado