import { useDispatch, useSelector } from 'react-redux';

import EditItem from '../../../../shared/components/IconButtons/EditItem';
import { handleOcorrenciaChange } from '../../../../../store/reducers/ocorrenciaSlice';
import CampoInputSelect from '../../../../shared/components/CampoInputComButton/CampoInputSelect/CampoInputSelect';


const CampoInputInstrucaoLimpeza = ({ onClick, editButton, disabled }) => {

    const dispatch = useDispatch()
    const { wash_instruct } = useSelector(state => state.ocorrenciaState)
    
    const handleChange = (name, value) => {
        dispatch(handleOcorrenciaChange({[name]: value}))
    }
    
    const handleClick = (e) => {
        e.preventDefault()
        onClick("wash_instruct")
    }

    return (
        <CampoInputSelect
            id="inputInstrucaoLimpeza"
            titulo="Instrução limpeza*"
            onChange={handleChange}
            required
            name="wash_instruct"
            value={wash_instruct}
            disabled={disabled}
            itensDoSelect={['ÁGUA', 'SECO', 'ÚMIDO E SECO', 'MANUAL', 'WET CLEANING', 'NÃO CONSTA']}
        >
            {
                editButton ? <EditItem onClick={handleClick} /> : <></>
            }
        </CampoInputSelect>
    )
}

export default CampoInputInstrucaoLimpeza
